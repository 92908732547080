import type { Dispatch, FC, SetStateAction } from 'react';
import { useCallback, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import { ArrowBack, ArrowForward, Close, ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { debounce } from 'lodash-es';
import { Link } from 'react-router-dom';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import type { CreativeTableItem } from '../api/types';
import {
  AssetChip,
  AssetChipLabel,
  AssetDownloadFileButton,
  AssetPreviewImage,
  AssetPreviewVideo,
} from '../../assets/components/AssetsListComponents';

interface CreativePreviewProps {
  creatives: CreativeTableItem[];
  isOpen: boolean;
  handleClose: () => void;
  currentPreviewIndex: number;
  setCurrentPreviewIndex: Dispatch<SetStateAction<number>>;
}

const CreativePreviewPopup: FC<CreativePreviewProps> = ({
  creatives,
  isOpen,
  handleClose,
  currentPreviewIndex,
  setCurrentPreviewIndex,
}) => {
  const { onShowAlert } = useEnqueueSnackbar();

  const currentCreative = useMemo(() => {
    if (currentPreviewIndex > -1) {
      return creatives[currentPreviewIndex];
    }

    return creatives[0];
  }, [currentPreviewIndex, creatives]);

  const isTrack = currentCreative.media_type === 'track';
  const isVideo = currentCreative.media_type === 'video';
  const isImage = currentCreative.media_type === 'image';

  const handlePrevClick = useCallback(() => {
    if (currentPreviewIndex > 0) {
      setCurrentPreviewIndex((prevIndex) => prevIndex - 1);
    }
  }, [currentPreviewIndex, setCurrentPreviewIndex]);

  const handleNextClick = useCallback(() => {
    if (currentPreviewIndex < creatives.length - 1) {
      setCurrentPreviewIndex((prevIndex) => prevIndex + 1);
    }
  }, [currentPreviewIndex, creatives, setCurrentPreviewIndex]);

  const handleCopyValue = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      onShowAlert('Copied');
    } catch (err) {
      onShowAlert('Error occurs');
    }
  };

  const debouncePrevClick = useMemo(() => debounce(handlePrevClick, 300), [handlePrevClick]);
  const debounceNextClick = useMemo(() => debounce(handleNextClick, 300), [handleNextClick]);

  const isFirstCreative = currentPreviewIndex === 0;
  const isLastCreative = currentPreviewIndex === creatives.length - 1;

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose}>
      <DialogTitle component={Box} sx={{ display: 'flex', justifyContent: 'space-between', p: 4 }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', ['&:hover .toggle-visibility']: { visibility: 'visible' } }}
        >
          <Typography
            component={Link}
            variant="h2"
            to={`/creatives/${currentCreative.id}`}
            target="_blank"
            sx={(t) => ({ color: t.palette.primary.main, mb: 2, ['&:hover']: { textDecoration: 'none' } })}
          >
            {currentCreative.file_name}
          </Typography>
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', visibility: 'hidden' }}
            className={'toggle-visibility'}
          >
            <Button
              startIcon={<ContentCopyIcon fontSize="small" />}
              sx={{ textTransform: 'capitalize', mr: 2, borderRadius: '4px' }}
              onClick={() => handleCopyValue(currentCreative.file_name)}
            >
              Name
            </Button>
            <Button
              startIcon={<ContentCopyIcon fontSize="small" />}
              sx={{ textTransform: 'capitalize', borderRadius: '4px' }}
              onClick={() => handleCopyValue(`${location.origin}/creatives/${currentCreative.id}`)}
            >
              Link
            </Button>
          </Box>
        </Box>
        <IconButton onClick={handleClose} sx={{ maxWidth: 42, maxHeight: 42 }}>
          <Close sx={(t) => ({ fontSize: 32, color: t.palette.grey[600] })} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 4, width: '100%' }}>
        <Box
          sx={(t) => ({
            display: 'flex',
            flexGrow: 1,
            width: '100%',
            height: '100%',
            [t.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          })}
        >
          <Box
            sx={(t) => ({
              display: 'flex',
              flexGrow: 1,
              [t.breakpoints.up('md')]: {
                minWidth: '25%',
                maxWidth: 200,
              },
              [t.breakpoints.down('md')]: {
                width: '100%',
              },
            })}
          >
            <Box>
              {currentCreative.tags.map(({ id, name }) => (
                <AssetChip
                  key={id}
                  label={<AssetChipLabel label={name} />}
                  variant="filled"
                  sx={(t) => ({ backgroundColor: t.palette.grey[200], p: 0.25, mb: 0.25, maxWidth: 160 })}
                />
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'center',
            }}
          >
            {isImage && <AssetPreviewImage sx={{ maxHeight: '100%' }} src={currentCreative.thumbnail} />}
            {isVideo && (
              <AssetPreviewVideo key={currentCreative.media_link} controls muted autoFocus autoPlay>
                <source src={currentCreative.media_link} type="video/mp4" />
              </AssetPreviewVideo>
            )}
            {isTrack && (
              <AssetPreviewVideo
                key={currentCreative.media_link}
                poster={currentCreative.thumbnail}
                controls
                autoFocus
                autoPlay
              >
                <source src={currentCreative.media_link} type="video/mp4" />
              </AssetPreviewVideo>
            )}
          </Box>
          <Box
            sx={(t) => ({
              display: 'flex',
              flexGrow: 1,
              [t.breakpoints.up('md')]: {
                minWidth: '25%',
                maxWidth: 200,
                justifyContent: 'end',
              },
              [t.breakpoints.down('md')]: {
                width: '100%',
                alignItems: 'start',
                justifyContent: 'center',
              },
            })}
          >
            <AssetDownloadFileButton
              isPreview
              file={currentCreative.download_files_link}
              fileName={currentCreative.file_name}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 4, justifyContent: 'space-between' }}>
        <Tooltip title={isFirstCreative ? 'This is very first creative' : null} placement="top" arrow>
          <Box>
            <Button
              onClick={debouncePrevClick}
              sx={{ ml: -1, fontSize: 26, fontWeight: 400, textTransform: 'capitalize' }}
              startIcon={<ArrowBack sx={{ fontSize: '32px !important' }} />}
              disabled={isFirstCreative}
            >
              Prev
            </Button>
          </Box>
        </Tooltip>
        <Tooltip title={isLastCreative ? 'This is very last creative' : null} placement="top" arrow>
          <Box>
            <Button
              onClick={debounceNextClick}
              sx={{ mr: -1, fontSize: 26, fontWeight: 400, textTransform: 'capitalize' }}
              endIcon={<ArrowForward sx={{ fontSize: '32px !important' }} />}
              disabled={isLastCreative}
            >
              Next
            </Button>
          </Box>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CreativePreviewPopup;
