import { Download as DownloadIcon, ExpandMoreOutlined as ExpandMoreOutlinedIcon } from '@mui/icons-material';
import { Button, Menu, Tooltip } from '@mui/material';
import { useAnchorMenu, useAnalytics } from 'hooks';
import { useCallback } from 'react';
import type { FC } from 'react';
import type { AssetEntry} from '../api';
import { useAssets } from '..';
import { AssetUtilButton } from './AssetsListComponents';
import AssetFilesList from './AssetFilesList';
interface AssetGetFileButtonProps {
  parentRef?: React.MutableRefObject<HTMLDivElement | null>;
  asset?: AssetEntry;
  variant: 'icon' | 'icon-text';
  isPreview?: boolean;
  assetId: number;
  tooltip?: boolean;
  title?: string;
}

const AssetDownloadFileButton: FC<AssetGetFileButtonProps> = ({ isPreview, variant, parentRef, assetId, tooltip, title }) => {
  const { isList, isGrid } = useAssets();
  const { isOpen, anchor, handleOpenMenu, handleCloseMenu } = useAnchorMenu();
  const { sendAssetDownloadToAnalytics } = useAnalytics();

  const onOpenMenu = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      parentRef?.current?.classList.add('action-hover');
      handleOpenMenu(e);
      sendAssetDownloadToAnalytics(isPreview);
    },
    [parentRef, handleOpenMenu, isPreview, sendAssetDownloadToAnalytics]
  );

  const onCloseMenu = useCallback(() => {
    parentRef?.current?.classList.remove('action-hover');
    handleCloseMenu();
  }, [parentRef, handleCloseMenu]);

  return (
    <>
      <Tooltip title={!tooltip ? null : isGrid ? `Get file` : null} placement={'left'} arrow={isList}>
        {variant === 'icon' ? (
          <AssetUtilButton
            preview={isPreview}
            aria-controls={isOpen ? `asset-${assetId}-context-menu` : undefined}
            aria-haspopup="true"
            aria-expanded={isOpen ? 'true' : undefined}
            onClick={onOpenMenu}
            variant="contained"
            disableElevation
          >
            <DownloadIcon
              sx={{ fontSize: 22, color: isPreview ?? isGrid ? 'white' : 'black', mr: !isPreview && isList ? 1 : 0 }}
            />
          </AssetUtilButton>
        ) : (
          <Button
            aria-controls={isOpen ? `asset-${assetId}-context-menu` : undefined}
            aria-haspopup="true"
            aria-expanded={isOpen ? 'true' : undefined}
            onClick={onOpenMenu}
            variant="contained"
            color="info"
            disableElevation
            startIcon={<DownloadIcon sx={{ fontSize: 22, color: 'black' }} />}
            endIcon={
              <ExpandMoreOutlinedIcon
                sx={{
                  fontSize: 14,
                  color: 'black',
                  transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
                  transition: 'transform .1s',
                }}
              />
            }
            sx={(t) => ({
              flexGrow: 1,
              mr: '4px',
              borderRadius: t.borderRadius.sm,
              fontSize: t.typography.body2.fontSize,
              lineHeight: t.typography.body2.lineHeight,
              opacity: 0.9,
              minHeight: 30,
            })}
          >
            {title ?? `Get file`}
          </Button>
        )}
      </Tooltip>

      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={`asset-${assetId}-context-menu`}
        anchorEl={anchor}
        open={isOpen}
        onClose={onCloseMenu}
        slotProps={{
          paper: {
            sx: {
              minWidth: 180,
              maxWidth: 280,
              maxHeight: 280,
            },
          },
        }}
      >
        {isOpen && <AssetFilesList id={assetId} />}
      </Menu>
    </>
  );
};

export default AssetDownloadFileButton;
