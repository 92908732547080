import { type FC, useCallback, useEffect } from 'react';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { Titled } from 'react-titled';
import { Box, Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Users, useMyAccess, UsersDrawer } from 'features/users';
import BreadcrumbLink from 'components/layout/BreadcrumbLink';
import { useModal } from 'hooks';

const UsersPage: FC = () => {
  const [userId, setUserId] = useQueryParam('userId', withDefault(NumberParam, null));
  const access = useMyAccess();

  const { isOpen, close, open } = useModal();

  useEffect(() => {
    if (userId) {
      open();
    }
  }, [open, userId]);

  const handleCloseDrawer = useCallback(() => {
    close();
    setUserId(null);
  }, [close, setUserId]);

  return (
    <>
      <Titled title={(title) => `ADAM | ${title}`} />
      <Box
        sx={(t) => ({
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          [t.breakpoints.down('sm')]: {
            pt: 6,
          },
          [t.breakpoints.up('sm')]: {
            pt: 1,
          },
          mb: 1,
          height: 48,
        })}
      >
        <BreadcrumbLink to="/assets" title="Assets" />
      </Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h1" sx={{ lineHeight: 1 }}>
          Users
        </Typography>
        {access?.users.includes('create') && (
          <Button
            color="primary"
            variant="contained"
            onClick={open}
            sx={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              minWidth: 0,
            }}
          >
            <AddIcon fontSize="medium" sx={{ color: 'white' }} />
          </Button>
        )}
      </Grid>
      <UsersDrawer open={isOpen} onClose={handleCloseDrawer} />
      <Users />
    </>
  );
};

export default UsersPage;
