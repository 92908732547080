import * as yup from 'yup';

const creativeEditSchema = yup.object().shape({
  access: yup.boolean().required(),

  model_name: yup.string(),
  comment: yup.string(),
  thumbnail: yup.string().required(),
  download_files_link: yup.string().required(),
  model_video_id: yup.string(),
  original_text: yup.string(),
  original_creative_asset: yup.string(),
  base_id: yup.string().required('Field can’t be empty'),
  file_name: yup.string().required('Field can’t be empty'),
  media_link: yup.string().required('Field can’t be empty'),
  customer: yup.string(),
  performer: yup.string(),
  base_customer: yup.string(),

  branch: yup.number().optional(),
  gender: yup.number().optional(),
  creative_type: yup.number().required(),
  idea_source: yup.number().optional(),
  idea_type: yup.number().required(),
  policy_check: yup.number().optional(),

  technology: yup.array().of(yup.number()),
  social_network: yup.array().of(yup.number()),
  projects: yup.array().of(yup.number().required()).min(1).label('Projects').required('Field can’t be empty'),
  resolution: yup.array().of(yup.number().required()).min(1).label('Resolution').required('Field can’t be empty'),
  languages: yup.array().of(yup.number().required()).min(1).label('Languages').required('Field can’t be empty'),
});

export default creativeEditSchema;
