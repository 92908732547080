import { Delete as DeleteIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { isAxiosError } from 'axios';
import { useModal } from 'hooks';
import type { FC } from 'react';
import { useState } from 'react';
import type { UserEntry } from '../api';
import { useDeleteUser } from '../api/deleteUser';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';

interface UsersDeleteEntry {
  user: UserEntry;
}

const UsersDelete: FC<UsersDeleteEntry> = ({ user: { id: user_id, email } }) => {
  const { onShowAlert } = useEnqueueSnackbar();
  const { close, isOpen, open } = useModal();
  const [formError, setFormError] = useState<string | null>(null);
  const deleteUser = useDeleteUser({
    config: {
      onSuccess: () => {
        close();
        onShowAlert(`${email} was deleted`);
      },
      onError: (e) => {
        if (isAxiosError(e) && e.response) {
          setFormError((e.response.data as { message: string }).message);
        }
      },
    },
  });

  const handleAgree = () => {
    deleteUser.mutate({ user_id });
  };

  return (
    <>
      <IconButton onClick={open}>
        <DeleteIcon color="action" sx={{ fontSize: 20 }} />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isOpen}
        onClose={() => {
          !deleteUser.isPending ? close() : null;
        }}
      >
        <DialogTitle id="alert-dialog-title" align="center">
          Delete user?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align="center">
            {email}
            <br />
            will no longer have access to ADAM
            {formError && (
              <Typography variant="caption" color="red">
                {formError}
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button disableElevation onClick={close} disabled={deleteUser.isPending}>
            Go back
          </Button>
          <LoadingButton
            disableElevation
            loading={deleteUser.isPending}
            onClick={handleAgree}
            autoFocus
            variant="contained"
            color="primary"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UsersDelete;
