import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { CreateAssetPayload } from './types';
import type { CreateAssetResponse } from './types';

export const createAsset = async (data: CreateAssetPayload): Promise<CreateAssetResponse> => {
  const res = await apiClient.post<CreateAssetResponse>(`/assets`, data);
  return res.data;
};

interface UseCreateAssetOptions {
  config?: MutationConfig<typeof createAsset>;
}

export const useCreateAsset = ({ config }: UseCreateAssetOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({
        queryKey: ['assets'],
      });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: createAsset,
  });
};
