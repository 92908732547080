import { Menu, MenuItem, IconButton } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { type FC } from 'react';
import type { SortOptionsProps } from '../../types';
import { useAnchorMenu } from 'hooks';

interface TagSortingProps {
  setSortOrder: (value: Pick<SortOptionsProps, 'order' | 'type'>) => void,
  currentSorting: Pick<SortOptionsProps, 'order' | 'type'>,
};

const SORT_OPTIONS: SortOptionsProps[] = [
  { name: "Sort A to Z", order: "asc", type: "alphabet" },
  { name: "Sort Z to A", order: "desc", type: "alphabet" },
  { name: "Increasing content", order: "asc", type: "number" },
  { name: "Decreasing content", order: "desc", type: "number" },
  { name: "The newest", order: "desc", type: "time" },
  { name: "The oldest", order: "asc", type: "time" },
];

const TagSorting: FC<TagSortingProps> = ({ setSortOrder, currentSorting }) => {
  const {
    isOpen,
    anchor,
    handleOpenMenu,
    handleCloseMenu,
  } = useAnchorMenu();

  const onClickCapture = (value: Pick<SortOptionsProps, 'order' | 'type'> ) => {
    setSortOrder(value);
    handleCloseMenu();
  }
  return (
    <>
      <IconButton
        sx={{ p: 0, ml: 1, }}
        aria-controls={isOpen ? 'tag-sorting-menu' : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="tag-sorting"
        anchorEl={anchor}
        open={isOpen}
        onClose={handleCloseMenu}>
        {SORT_OPTIONS.map(({order, type, name}) => (
          <MenuItem
            key={order}
            selected={currentSorting.order === order && currentSorting.type === type}
            sx={{ fontSize: 14 }}
            onClickCapture={() => { onClickCapture({order, type}); }}>{name}</MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default TagSorting;
