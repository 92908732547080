import { Box } from '@mui/system';
import { Button, CircularProgress, Drawer, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider, useForm } from 'react-hook-form';
import UserForm from './UserForm';
import type { FC } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';
import { userSchema } from '../validation/user.schema';
import type { UserFormData } from '../types';
import { Suspense, useCallback, useEffect } from 'react';
import type { UserAccessLevel } from '../api';
import { useCreateUser, useUpdateUser } from '../api';
import { yupResolver } from '@hookform/resolvers/yup';
import UserFormSkeleton from './UserFormSkeleton';
import { enqueueSnackbar } from 'notistack';

interface UserDrawerProps {
  open: boolean;
  onClose: () => void;
}

const UsersDrawer: FC<UserDrawerProps> = ({ open, onClose }) => {
  const t = useTheme();
  const [userId] = useQueryParam('userId', NumberParam);

  const isEdit = !!userId;
  const formMethods = useForm<UserFormData>({
    resolver: yupResolver(userSchema),
  });
  const { handleSubmit, reset } = formMethods;

  const handleError = useCallback((msg: string) => {
    enqueueSnackbar(msg, {
      key: '409-error',
      variant: 'error',
      autoHideDuration: 5000,
    });
  }, []);

  const { mutateAsync: createUser, isPending: isCreating } = useCreateUser({
    config: {
      onSuccess: () => {
        onClose();
      },
      onError: (err) => {
        handleError(err.message);
      },
    },
  });
  const { mutateAsync: updateUser, isPending: isUpdating } = useUpdateUser({
    config: {
      onSuccess: () => {
        onClose();
      },
      onError: (err) => {
        handleError(err.message);
      },
    },
  });

  const onSubmit = async ({ email, access, projects, tag }: UserFormData) => {
    const body = {
      projects_ids: projects,
      email,
      access_level: access as UserAccessLevel,
      customer_tag: tag,
    };

    if (isEdit) {
      await updateUser({ userId, body });
    } else {
      await createUser(body);
    }
  };

  useEffect(() => {
    reset({ email: '', projects: [], access: '', tag: '' });
  }, [open, reset]);

  const isLoading = isCreating || isUpdating;

  return (
    <Drawer
      PaperProps={{ sx: { boxShadow: 'none', borderLeft: `1px solid ${t.palette.grey[50]}` } }}
      sx={{ boxShadow: 'none' }}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          width: 500,
          px: 4,
          py: 5,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 5 }}>
            <Typography variant="h2">{isEdit ? 'Edit' : 'New'} user</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Suspense fallback={<UserFormSkeleton />}>
            <FormProvider {...formMethods}>
              <UserForm />
            </FormProvider>
          </Suspense>
        </Box>
        <Button
          onClick={handleSubmit(onSubmit, (err) => {
            console.log(err);
          })}
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          sx={{ minHeight: '56px' }}
        >
          {isLoading ? <CircularProgress size={40} sx={{ color: 'white' }} /> : isEdit ? 'Save' : 'Create'}
        </Button>
      </Box>
    </Drawer>
  );
};

export default UsersDrawer;
