import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { TagEntry } from '../types';

interface UpdateTagPayload {
  group_id: number;
  tag_id: number;
  tag_name: string;
}

export const updateTag = async ({group_id, tag_id, ...data}: UpdateTagPayload): Promise<TagEntry> => {
  const res = await apiClient.patch<TagEntry>(`/tag_groups/${group_id}/tags/${tag_id}`, data);
  return res.data;
};

interface UseUpdateTagOptions {
  params: Pick<UpdateTagPayload, 'group_id' | 'tag_id'>
  config?: MutationConfig<typeof updateTag>;
}

export const useUpdateTag = ({params: {group_id, tag_id}, config }: UseUpdateTagOptions) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    ...config,
    onSuccess: async (...args) => {
      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }

      await queryClient.invalidateQueries({
        queryKey: ['tag_groups']
      });
    },
    mutationFn: updateTag,
  });

  return {
    ...mutation,
    mutate: ({ tag_name }: Pick<UpdateTagPayload, 'tag_name'>, options?: MutationConfig<typeof updateTag>) => {
      mutation.mutate({ group_id, tag_id, tag_name }, options);
    },
  };
};
