import type { Breakpoint} from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';

export interface UseBreakpointsResult {
  isBreakpoint: boolean
}

export const useBreakpoints = (defaultBreakpoint: Breakpoint = 'sm'): UseBreakpointsResult => {
  const defaultTheme = useTheme();
  const isBreakpoint = useMediaQuery(defaultTheme.breakpoints.down(defaultBreakpoint));

  return { isBreakpoint }
}
