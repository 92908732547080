import { useTagGroups } from '../features/assets';
import useListParams from './useListParams';
import { trackEvent } from '../lib/analytics/analyticsInitial';
import type { SearchTypesValue } from '../features/search/types';

export const useAnalytics = () => {
  const {
    data: { data: tagGroups },
  } = useTagGroups();

  const { favourite, tags, q} = useListParams();

  const calculateSearchItems = () => {
    const uniqueTagGroupsInSearch = tagGroups.filter((t) =>
      t.tags.some((tag) => tags && tags.includes(tag.tag_id))
    )
    const numberOfSearchItems: number = (
      uniqueTagGroupsInSearch.length
      + (q ? 1 : 0)
      + (favourite ? 1 : 0)
    )
    return { uniqueTagGroupsInSearch, numberOfSearchItems };
  };

  const sendAssetDownloadToAnalytics = (isPreview?: boolean) => {
    const { uniqueTagGroupsInSearch, numberOfSearchItems } = calculateSearchItems();
    const eventProperties = {
      'number_of_search_items': numberOfSearchItems,
      'text_filter': q ? 1 : 0,
      'tag_groups': uniqueTagGroupsInSearch.map(t => t.group_name).join(', '),
      'number_of_tag_groups': uniqueTagGroupsInSearch.length,
      'page': isPreview ? 'listing' : 'asset',
    };
    trackEvent('asset download', eventProperties);
  };

  const sendContentPlayToAnalytics = (searchType: SearchTypesValue, isPreviewPopup?: boolean) => {
    if (searchType === 'assets') {
      const eventProperties = {
        'place': isPreviewPopup ? 'asset preview' : 'asset page',
      };
      trackEvent('asset play', eventProperties);
    }
  };

  const sendAssetScrollToAnalytics = () => {
    const { numberOfSearchItems } = calculateSearchItems();
    const eventProperties = {
      'number_of_search_items': numberOfSearchItems,
    };
    trackEvent('asset listing scroll', eventProperties);
  };

  const sendSavePresetOpened = (searchType: SearchTypesValue) => {
    if (searchType === 'assets') {
      trackEvent('asset listing save preset opened', {});
    }
  }

  return {
    sendAssetDownloadToAnalytics,
    sendContentPlayToAnalytics,
    sendAssetScrollToAnalytics,
    sendSavePresetOpened,
  };
};
