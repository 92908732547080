import type { ColumnKey, ColumnOrderObj } from '../types';
const getOrderedColumnsToDisplay = (columns: Record<ColumnKey, boolean>, order: ColumnOrderObj[]) => {
  const visibleCols = Object.keys(columns).filter((k) => columns[k as ColumnKey]);
  const result: ColumnKey[] = [];
  order.forEach(({ name }) => {
    const colId = name.split('.')[1] as ColumnKey;
    if (visibleCols.includes(colId)) {
      result.push(colId);
    }
  });

  return result;
};

export default getOrderedColumnsToDisplay;
