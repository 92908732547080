import {
  InsertDriveFileOutlined as EmptyDocIcon,
  ImageOutlined as ImageIcon,
  VideoFileOutlined as VideoFileIcon,
  AudioFileOutlined as AudioFileIcon,
  PaletteOutlined as PaletteIcon,
  FolderOpen as FolderOpenIcon,
  AnimationOutlined as AnimationIcon,
  InsertDriveFileOutlined as InsertDriveFileIcon,
} from '@mui/icons-material';
import type { SxProps, Theme } from '@mui/system';
import type { FC } from 'react';
import type { CreativeMediaTypeEntry, FileTypeValue } from 'types/shared';

interface FileIconProps {
  type: FileTypeValue | CreativeMediaTypeEntry;
  format?: string;
  sx?: SxProps<Theme>;
}

const iconMappings = (sx?: SxProps<Theme>): Record<string, Record<string, React.ReactNode>> => ({
  image: { default: <ImageIcon fontSize="medium" sx={sx} /> },
  static: { default: <ImageIcon fontSize="medium" sx={sx} /> },
  animation: { default: <ImageIcon fontSize="medium" sx={sx} /> },
  json: { default: <ImageIcon fontSize="medium" sx={sx} /> },
  video: { default: <VideoFileIcon fontSize="medium" sx={sx} /> },
  audio: { default: <AudioFileIcon fontSize="medium" sx={sx} /> },
  track: { default: <AudioFileIcon fontSize="medium" sx={sx} /> },
  fonts: { default: <InsertDriveFileIcon fontSize="medium" sx={sx} /> },
  source_file: {
    psd: <PaletteIcon fontSize="medium" sx={sx} />,
    ae: <AnimationIcon fontSize="medium" sx={sx} />,
    zip: <FolderOpenIcon fontSize="medium" sx={sx} />,
    default: <InsertDriveFileIcon fontSize="medium" sx={sx} />,
  },
});

const FileIcon: FC<FileIconProps> = ({ type, format = '', sx }) => {
  if (!type) return <EmptyDocIcon fontSize="medium" sx={sx} />;

  const icon = iconMappings(sx)[type.toLowerCase()][format.toLowerCase()] ?? iconMappings(sx)[type.toLowerCase()].default ?? (
    <EmptyDocIcon fontSize="medium" sx={sx} />
  );
  return icon;
};

export default FileIcon;
