import { Box, Button } from "@mui/material";
import { ViewListOutlined as ListOutlinedIcon, ViewModuleOutlined as ModuleOutlinedIcon } from '@mui/icons-material';
import type { FC } from "react"
import { useBreakpoints } from "hooks";
import { useAssets } from "../context";

const AssetsTypesList: FC = () => {
  const { isBreakpoint } = useBreakpoints('sm');
  const { handleListType, isGrid, isList } = useAssets();

  return !isBreakpoint ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
      <Button variant="text" sx={{ minWidth: 42 }} onClick={() => { handleListType('row'); }}>
        <ModuleOutlinedIcon sx={t => ({ fontSize: 26, color: isList ? t.palette.action.active : t.palette.primary.main })} /></Button>
      <Button variant="text" sx={{ minWidth: 42 }} onClick={() => { handleListType('column'); }}>
        <ListOutlinedIcon sx={t => ({ fontSize: 26, color: isGrid ? t.palette.action.active : t.palette.primary.main })} /></Button>
    </Box>
  ) : null
};

export default AssetsTypesList;
