import { Box, LinearProgress, Typography, styled } from '@mui/material';
import LogoAdam from 'assets/logo-adam.svg?react';
import BgLogin from 'assets/bg-login.webp';
import LoginForm from './LoginForm';
import useCheckSession from 'hooks/useCheckSession';

const GradientBox = styled(Box)`
  padding: 16px 24px;
  background-color: t.palette.grey[100];
  background: url(${BgLogin}) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  ${({ theme: t }) => t.breakpoints.up('md')} {
    justify-content: space-between;
    max-width: 450px;
  }

  ${({ theme: t }) => t.breakpoints.down('md')} {
    justify-content: flex-start;
  }
`;

const TitleStyled = styled(Typography)`
  font-size: 48px;
  line-height: 1.25;
  color: white;

  ${({ theme: t }) => t.breakpoints.up('md')} {
    width: min-content;
  }
`;

const Login = () => {
  const { isChecked } = useCheckSession();

  return isChecked ? (
    <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', height: '100vh', minWidth: 320 }}>
      <GradientBox>
        <LogoAdam />
        <TitleStyled>Asset aggregation management</TitleStyled>
      </GradientBox>
      <LoginForm />
    </Box>
  ) : (
    <LinearProgress />
  );
};

export default Login;
