/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import {
  Box,
  IconButton,
  LinearProgress,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { type FC } from 'react';

const TagGroupDetailsSkeleton: FC = () => {
  return (
    <Box sx={{ position: 'relative' }}>
      <LinearProgress sx={{ position: 'absolute', height: 4, mb: 2, zIndex: 10, width: '100%' }} />
      <Box
        sx={(t) => ({
          borderBottom: 1,
          borderColor: t.palette.grey[300],
          display: 'flex',
          minHeight: 56,
          maxHeight: 56,
          height: 56,
          px: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
        })}
      >
        <Typography fontWeight="500" variant="body2">
          Details
        </Typography>
        <IconButton>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}>
        <Skeleton animation="wave" height={90} width={'100%'} sx={{ mt: -2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Skeleton animation="wave" height={26} width={120} />
          <Skeleton animation="wave" height={26} width={80} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'start',
            position: 'relative',
            width: '100%',
            maxHeight: '100%',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              width: '100%',
              top: 0,
              left: 0,
              maxHeight: '100%',
            }}
          >
            <TableContainer component={Box}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: 280 }}>
                      <Skeleton width={80} animation="wave" variant="text" sx={{ fontSize: '14px' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton width={160} animation="wave" variant="text" sx={{ fontSize: '14px' }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Skeleton animation="wave" height={40} width={100} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Skeleton animation="wave" height={40} width={200} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" height={40} width={20} />
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Skeleton animation="wave" height={40} width={200} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" height={40} width={20} />
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Skeleton animation="wave" height={40} width={200} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" height={40} width={20} />
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TagGroupDetailsSkeleton;
