import { apiClient } from '../../../lib/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationConfig } from '../../../lib/react-query';
import type { UpdateUserDTO } from '../types';
import type { UserEntry } from './types';
import axios from 'axios';
import type { UserError } from './types';
import { UserErrors } from './errors';

interface UpdateUserPayload {
  userId: number;
  body: UpdateUserDTO;
}

export const updateUser = async ({ userId, body }: UpdateUserPayload): Promise<UserEntry> => {
  const res = await apiClient.patch<UserEntry>(`/users/${userId}`, body);

  if (axios.isAxiosError(res)) {
    const error = res.response?.data as UserError;
    throw new Error(UserErrors[error.detail.column]);
  }

  return res.data;
};

interface UseUpdateUserOptions {
  config?: MutationConfig<typeof updateUser>;
}

export const useUpdateUser = ({ config }: UseUpdateUserOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['users'],
      });

      queryClient.removeQueries({
        queryKey: ['user'],
      });

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    mutationFn: updateUser,
  });
};
