import { Box, FormControlLabel, Switch, styled } from "@mui/material";
import useListParams from "hooks/useListParams";
import { useCallback, type FC } from "react";

export const AssetsFavouritesFilterContainer = styled(Box)(() => `
  display: flex;
  align-items: center;
  justify-content: start;
  min-height: 56px;
  width: 100%;
  padding: 0px 8px;
`);

const AssetsFavouritesFilter: FC = () => {
  const { favourite, setFavourite } = useListParams();

  const handleChange = useCallback(() => {
    setFavourite(!favourite);
  }, [setFavourite, favourite]);

  return (
    <AssetsFavouritesFilterContainer>
      <FormControlLabel
        sx={{ ml: -1, }}
        control={
          <Switch
            checked={favourite}
            onChange={handleChange}
            name="favourite"
            sx={{ mr: 1, }}
          />
        }
        label="Only favourites"
      />
    </AssetsFavouritesFilterContainer>
  )
}

export default AssetsFavouritesFilter;
