/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { Box } from '@mui/material';
import Input from 'components/common/Input';
import Select from 'components/common/Select';
import { useFormContext } from 'react-hook-form';
import type { UserFormData } from '../types';
import { useAccessLevels, useUser } from '../api';
import type { Option } from 'types/shared';
import { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { NumberParam, useQueryParam } from 'use-query-params';
import type { Project } from 'features/projects';
import UsersFormSkeleton from './UserFormSkeleton';
import { useProjects } from 'features/projects';
import { PROJECT_DEPENDANT_ROLES } from '../hooks/useMyAccess';

const UserForm = () => {
  const [userId] = useQueryParam('userId', NumberParam);
  const { data: user, isFetching } = useUser({ id: userId!, config: { enabled: !!userId } });

  const { data: accessLevels } = useAccessLevels<Option[]>({
    config: {
      select: (data) => data.map((item) => ({ value: item, label: item })),
    },
  });

  const { data: projects } = useProjects<Option[]>({
    config: {
      select: (data) => data.map(({ name, id }) => ({ value: id, label: name })),
    },
  });
  const { watch, setValue } = useFormContext<UserFormData>();

  const access = watch('access');

  const showProject = PROJECT_DEPENDANT_ROLES.some((r) => r === access);

  useEffect(() => {
    if (!isEmpty(user)) {
      const { email, access_level, projects, customer_tag } = user;
      setValue('email', email);
      setValue('tag', customer_tag);
      if (access_level) {
        setValue('access', access_level);
      }
      if (!isEmpty(projects) && PROJECT_DEPENDANT_ROLES.some((r) => r === access_level)) {
        setValue(
          'projects',
          (projects as Project[]).map((p) => p.id)
        );
      }
    }
  }, [setValue, user, accessLevels]);

  if (isFetching) {
    return <UsersFormSkeleton />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px 0' }}>
      <Input name="email" label="User email" size="small" required />
      <Select name="access" size="small" label="Access" required options={accessLevels} />
      {showProject && <Select name="projects" size="small" label="Project" multiple required options={projects} />}
      {/*<Select name="team" size="small" label="Team" options={[]} />*/}
      <Input inputProps={{ maxLength: 3 }} name="tag" label="Tag" size="small" required />
    </Box>
  );
};

export default UserForm;
