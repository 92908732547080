import type { UserAccessLevel } from '../api';
import { useMe } from '../api';

export const PROJECT_DEPENDANT_ROLES: UserAccessLevel[] = ['Viewer'];

type Entities = 'tags' | 'assets' | 'creatives' | 'users';
type Actions = 'read' | 'update' | 'delete' | 'create' | 'users-all' | 'read-all';

type Access = Record<Entities, Actions[]>;

const fullAccess: Actions[] = ['read-all', 'update', 'delete', 'create'];

const useMyAccess = (): Access | undefined => {
  const { data: me, isPending } = useMe();

  if (isPending) return undefined;
  if (!me?.access_level) return { tags: [], assets: [], users: [], creatives: [] };

  if (me.access_level === 'Admin') {
    return { tags: fullAccess, assets: fullAccess, creatives: fullAccess, users: [...fullAccess, 'users-all'] };
  }
  if (me.access_level === 'Supervisor') {
    return { tags: fullAccess, assets: fullAccess, creatives: fullAccess, users: fullAccess };
  }
  if (me.access_level === 'Assistant') {
    return { tags: fullAccess, assets: fullAccess, creatives: fullAccess, users: [] };
  }
  return { tags: ['read'], assets: ['read'], creatives: ['read'], users: ['read'] };
};

export default useMyAccess;
