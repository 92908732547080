import { Box } from '@mui/system';
import TagAccordion from 'components/common/Accordion';
import { useSearchContext, type TagGroupEntry } from 'features/search';
import { useCallback, type FC } from 'react';
import Checkbox from 'components/common/Checkbox';
import { useFormContext } from 'react-hook-form';

const SearchTagItem: FC<{ tagGroup: TagGroupEntry }> = ({ tagGroup }) => {
  const { searchTextFieldRef, setQueryFieldValue } = useSearchContext();
  const { setValue } = useFormContext();

  const handleClearField = useCallback(() => {
    setQueryFieldValue('');
  }, [setQueryFieldValue]);

  const handleChecked = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      handleClearField();
      setValue(e.target.name, checked);
    },
    [handleClearField, setValue, searchTextFieldRef]
  );

  return (
    <Box>
      <TagAccordion
        summary={tagGroup.group_name}
        details={tagGroup.tags.map((t) => (
          <Checkbox
            onChange={handleChecked}
            key={`${t.tag_id}:${t.tag_name}`}
            name={'searchTags.' + t.tag_id}
            label={t.tag_name}
            inputProps={{ name: 'searchTags.' + t.tag_id }}
          />
        ))}
      />
    </Box>
  );
};

export default SearchTagItem;
