import { Box, Button, Typography, styled, Link } from '@mui/material';
import { Titled } from 'react-titled';
import LogoAdam from 'assets/logo-adam-white.svg?react';
import BgMaintenance from 'assets/bg-maintenance.png';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { Auth } from 'aws-amplify';

const GradientBackground = styled(Box)`
  position: relative;
  padding: 40px;
  background: url(${BgMaintenance}) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const MaintenancePage = () => {
  const handleSignOut = async () => {
    await Auth.signOut();
  };

  return (
    <>
      <Titled title={(title) => `Maintenance | ${title}`} />
      <Box sx={{ display: 'flex', flexGrow: 1, width: '100%', flexDirection: 'column', height: '100vh' }}>
        <GradientBackground>
          <Box sx={{ pb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 11.5 }}>
            <Link href="/">
              <LogoAdam />
            </Link>
            <Button
              variant="text"
              size="large"
              component="button"
              onClick={handleSignOut}
              startIcon={<LogoutIcon />}
              sx={{ mr: 1, color: 'white', fontWeight: 500, textTransform: 'uppercase' }}
            >
              Log out
            </Button>
          </Box>
          <Box
            sx={{
              width: '100%',
              mx: 'auto',
              backgroundColor: 'rgba(255, 255, 255, 0.75)',
              p: 5,
              borderRadius: 1.5,
            }}
          >
            <Typography variant="h1" fontWeight={400}>
              503: Maintenance mode
            </Typography>
            <Typography
              variant="body1"
              sx={(t) => ({
                fontSize: t.typography.h3.fontSize,
                fontWeight: 400,
              })}
            >
              We are currently undergoing maintenance and our service is temporarily unavailable.{' '}
              <Link
                href={'/assets'}
                sx={(t) => ({
                  fontSize: t.typography.h3.fontSize,
                  fontWeight: 400,
                })}
              >
                Check back
              </Link>{' '}
              in a few hours. We apologize for the inconvenience and appreciate your patience!
            </Typography>
          </Box>
        </GradientBackground>
      </Box>
    </>
  );
};

export default MaintenancePage;
