import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { UserEntry } from './types';

interface DeleteUserPayload {
  user_id: number;
}

export const deleteUser = async ({ user_id }: DeleteUserPayload): Promise<UserEntry> => {
  const res = await apiClient.delete<UserEntry>(`/users/${user_id}`);
  return res.data;
};

type MutationFnType = (data: DeleteUserPayload) => ReturnType<typeof deleteUser>;

interface UseDeleteTagOptions {
  config?: MutationConfig<MutationFnType>;
}

export const useDeleteUser = ({ config }: UseDeleteTagOptions = {}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: ['users'] });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: ({ user_id }) => deleteUser({ user_id }),
  });

  return {
    ...mutation,
    mutate: ({ user_id }: DeleteUserPayload) => {
      mutation.mutate({ user_id });
    },
  };
};
