import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { SearchTypesValue } from '../types';

interface CreatePresetPayload {
  name: string;
  tags?: number[];
  q?: string;
  type: SearchTypesValue
}

export interface CreatePresetResponse {
  success: boolean;
}

export const createPreset = async ({ type, ...data }: CreatePresetPayload): Promise<CreatePresetResponse> => {
  const res = await apiClient.post<CreatePresetResponse>(`/${type}/presets`, data);
  return res.data;
};

interface UseCreatePresetOptions {
  config?: MutationConfig<typeof createPreset>;
}

export const useCreatePreset = ({ config }: UseCreatePresetOptions = {}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({
        queryKey: [args[1].type, 'presets']
      });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: createPreset,
  });

  return {
    ...mutation,
    mutate: (data: CreatePresetPayload, options?: MutationConfig<typeof createPreset>) => {
      mutation.mutate(data, options);
    },
  };
};
