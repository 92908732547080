import { type FC, Suspense } from 'react';
import { useParams } from 'react-router';
import { useCreativeFilesSync } from '../api/getCreativeFiles';
import { CreativeDetailsSkeleton } from './index';
import CreativeDetailsContent from './CreativeDetailsContent';
import useRestrictedRedirect from '../../../hooks/useRestrictedRedirect';
import { useCreativeSync } from '../api/getCreative';
import type { AccessError } from '../../../types/shared';

const CreativeDetails: FC = () => {
  const { creativeId } = useParams();
  const { error, isPending, data } = useCreativeSync({ id: `${creativeId}` });

  useRestrictedRedirect(error as AccessError, 'creative');
  useCreativeFilesSync({ params: { id: Number(creativeId) }, config: { enabled: !!creativeId } });

  if (isPending || !data) return <CreativeDetailsSkeleton />;

  return (
    <Suspense fallback={<CreativeDetailsSkeleton />}>
      <CreativeDetailsContent />
    </Suspense>
  );
};

export default CreativeDetails;
