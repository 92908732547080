import { Box, Skeleton } from '@mui/material';
import { AssetItemStyled, AssetListContainer, AssetsPreviewContainer } from './AssetsListComponents';
import { useAssets } from '..';

const AssetsListSkeleton = () => {
  const { isGrid, isList } = useAssets();

  return (
    <>
      <Box sx={{ display: 'flex', flexGrow: 1, py: 2, flexDirection: 'column', maxWidth: '100%' }}>
        <AssetListContainer sx={{ maxWidth: '100%' }}>
          {[...Array(22).keys()].map((i) => (
            <AssetItemStyled key={i}>
              <AssetsPreviewContainer
                sx={{ position: 'relative', flexDirection: isGrid ? 'column' : 'row' }}
              >
                <Box sx={{ position: 'relative', width: '100%', height: '100%', mb: 1, }}>
                  <Skeleton
                    sx={(t) => ({
                      position: 'absolute',
                      zIndex: 2,
                      minWidth: '100%',
                      transform: 'scale(1)',
                      backgroundColor: t.palette.grey[50],
                      mb: 2,
                    })}
                    animation={'wave'}
                    height={'100%'}
                  />
                  <Box component={'img'} src='#' width={'100%'} height={'100%'} sx={{ visibility: 'hidden', objectFit: 'contain', }}></Box>
                </Box>
                {isGrid ? (
                  <Skeleton
                    sx={(t) => ({ transform: 'scale(1)', backgroundColor: t.palette.grey[50], mb: 1 })}
                    animation={'wave'}
                    width={'80%'}
                    height={30}
                  />
                ) : null}
              </AssetsPreviewContainer>
              {isList && (
                <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', pl: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1 }}>
                      <Skeleton
                        sx={(t) => ({
                          transform: 'scale(1)',
                          backgroundColor: t.palette.grey[50],
                          mb: 1,
                          borderRadius: '4px',
                        })}
                        animation={'wave'}
                        width={isGrid ? '80%' : '50%'}
                        height={28}
                      />
                      <Skeleton
                        sx={(t) => ({
                          transform: 'scale(1)',
                          backgroundColor: t.palette.grey[50],
                          mb: 1,
                          borderRadius: '4px',
                        })}
                        animation={'wave'}
                        width={140}
                        height={28}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </AssetItemStyled>
          ))}
        </AssetListContainer>
      </Box>
    </>
  );
};

export default AssetsListSkeleton;
