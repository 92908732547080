import { useSuspenseQuery } from '@tanstack/react-query';
import type { QueryKey } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import type { Project } from './types';

export const getProjects = async (signal?: AbortSignal): Promise<Project[]> => {
  const res = await apiClient.get<{ data: Project[] }>(`/projects`, {
    signal,
  });
  return res.data.data;
};

interface UseProjectListOptions<TData> {
  config?: QueryConfig<typeof getProjects, TData>;
}

export const useProjects = <TData = Project[]>({ config }: UseProjectListOptions<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getProjects(signal),
    queryKey: ['projects'] as QueryKey,
  });
};
