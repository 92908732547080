import type { TestContext } from 'yup';

export function isUrl(this: TestContext, value: string) {
  let url;

  try {
    url = new URL(value);
  } catch (_) {
    console.log(this);
    return this.createError({
      message: ({ label }) => `${label} must be valid URL url and have http or https protocol`,
      path: this.path,
    });
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}
