import { useCallback, useMemo, useState } from 'react';
import useUsersListParams from '../hooks/useUsersListParams';
import { debounce, isEmpty } from 'lodash-es';
import type { Option, OptionValue } from 'types/shared';
import CheckboxAutocomplete from 'components/common/CheckboxAutocomplete';
import { useAccessLevels } from '../api';

const UsersAccessFilter = () => {
  const { accessLevel, setAccessLevel } = useUsersListParams();
  const [accessLevelValue, setAccessLevelValue] = useState<string[] | []>(accessLevel as string[]);
  const updateQueryValue = useMemo(() => debounce(setAccessLevel, 700), [setAccessLevel]);

  const { data: options, isLoading } = useAccessLevels<Option[]>({
    config: {
      select: (data) => data.map((item) => ({ value: item.toLowerCase(), label: item })),
    },
  });

  const handleAccessLevel = useCallback(
    (value: string[]) => {
      setAccessLevelValue(value);
      updateQueryValue(value);
    },
    [setAccessLevelValue, updateQueryValue]
  );

  return (
    <CheckboxAutocomplete
      allSelectable={false}
      isLoading={isLoading}
      value={accessLevelValue}
      InputProps={{
        label: 'Access',
        placeholder: isEmpty(accessLevelValue) ? 'Access level' : '',
      }}
      onChange={handleAccessLevel as (value: OptionValue[]) => void}
      id="access-level-autocomplete"
      options={options}
    />
  );
};

export default UsersAccessFilter;
