import { useState, type FC, useEffect } from 'react';
import {
  Box,
  Chip,
  Table,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  Tooltip,
} from '@mui/material';
import SwitchVisibility from './SwitchVisibility';
import { useBreakpoints, usePagination } from 'hooks';
import { useTagGroups } from '../api/getTagGroups';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import type { TagEntry } from '../types';
import { useMyAccess } from 'features/users';

interface TagGroupProps {
  tagGroupIdDetails: number | null;
  setTagGroupIdDetails: (id: number | null) => void;
}

const TableHeadEntry: FC<{ tagGroupIdDetails: number | null }> = ({ tagGroupIdDetails }) => {
  const access = useMyAccess();
  const { isBreakpoint } = useBreakpoints('sm');

  return (
    <TableHead sx={{ ['th']: { padding: 0 } }}>
      <TableRow>
        <TableCell sx={{ minWidth: 160 }}>Name</TableCell>
        {!tagGroupIdDetails && (
          <>
            {access?.tags.includes('update') ? <TableCell sx={{ minWidth: 80 }}>Visibility</TableCell> : null}
            <TableCell sx={{ minWidth: 80 }}>Tags count</TableCell>
            {!isBreakpoint ? <TableCell>Tags preview</TableCell> : null}
          </>
        )}
      </TableRow>
    </TableHead>
  );
};

const TagsPreview: FC<{ tags: TagEntry[] }> = ({ tags }: { tags: TagEntry[] }) => {
  const preview = tags.slice(0, 3);

  return preview.map((tag) => (
    <Chip
      key={tag.tag_id}
      label={tag.tag_name}
      sx={(t) => ({
        '&': {
          maxWidth: `calc(380px / ${preview.length})`,
          height: '24px',
          mr: 1,
          borderRadius: '4px',
          '& .MuiChip-label': { px: '6px', py: '3px', color: t.palette.primary.main },
        },
      })}
    />
  ));
};

const TagGroupsList: FC<TagGroupProps> = ({ tagGroupIdDetails, setTagGroupIdDetails }) => {
  const access = useMyAccess();
  const { isBreakpoint } = useBreakpoints('sm');
  const [total, setTotal] = useState(0);
  const { page, perPage, handlePageChange, handleRowsPerPageChange } = usePagination([], 50);

  const {
    data: { data: tagGroupsList, count },
  } = useTagGroups({
    params: { limit: perPage, offset: page * perPage },
  });

  useEffect(() => {
    setTotal(count);
  }, [setTotal, count]);

  return tagGroupsList.length ? (
    <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', width: '100%' }}>
      <Box sx={{ display: 'flex', flexGrow: 1, width: '100%', position: 'relative' }}>
        <TableContainer component={Box} sx={{ position: 'absolute', top: 0, left: 0, height: '100%' }}>
          <Table size="medium" stickyHeader sx={{ '& thead th': { backgroundColor: 'white' } }}>
            <TableHeadEntry tagGroupIdDetails={tagGroupIdDetails} />
            <TableBody>
              {tagGroupsList.map((group) => (
                <TableRow
                  onClick={() => {
                    setTagGroupIdDetails(group.group_id);
                  }}
                  hover
                  key={group.group_id}
                  sx={(t) => {
                    const showMoreLabel = {
                      '&:hover::after': {
                        content: '"Show more"',
                        position: 'absolute',
                        right: 0,
                        px: 2,
                        fontSize: t.typography.body2.fontSize,
                        fontWeight: 500,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        textTransform: 'uppercase',
                        color: t.palette.primary.light,
                      },
                    };
                    const hiddenLabel = {
                      '&::after': {
                        content: '"Hidden"',
                        position: 'absolute',
                        right: 0,
                        px: 2,
                        fontSize: 14,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        color: t.palette.grey[500],
                      },
                    };
                    const activeTagGroup = {
                      backgroundColor: t.palette.grey[100],
                      boxShadow: `inset -4px 0 0 ${t.palette.primary.light}`,
                    };
                    return {
                      position: 'relative',
                      cursor: 'pointer',
                      '& td': {
                        padding: 0,
                        height: 71,
                        minHeight: 71,
                        ...(tagGroupIdDetails === group.group_id ? activeTagGroup : null),
                      },
                      '&:hover td': {
                        backgroundColor: t.palette.grey[100],
                      },
                      [t.breakpoints.up('sm')]: {
                        ...(!tagGroupIdDetails ? showMoreLabel : null),
                        ...(tagGroupIdDetails && !group.is_visible ? hiddenLabel : null),
                      },
                    };
                  }}
                >
                  <TableCell sx={{ minWidth: 160 }}>
                    <Tooltip
                      title={
                        tagGroupIdDetails &&
                        tagGroupIdDetails !== group.group_id &&
                        group.tags.map((t) => t.tag_name).join(', ')
                      }
                      placement="bottom"
                      followCursor
                    >
                      <Typography fontSize={14}>{group.group_name}</Typography>
                    </Tooltip>
                  </TableCell>
                  {!tagGroupIdDetails && (
                    <>
                      {access?.tags.includes('update') ? (
                        <TableCell sx={{ minWidth: 80 }}>
                          <Box component={'div'} sx={{ display: 'flex', alignContent: 'center' }}>
                            <SwitchVisibility groupId={group.group_id} checked={group.is_visible} />
                          </Box>
                        </TableCell>
                      ) : null}
                      <TableCell sx={{ minWidth: 80 }}>{group.tag_count}</TableCell>
                      {!isBreakpoint ? (
                        <TableCell>
                          <Box sx={{ display: 'flex', flexWrap: 'nowrap', flexGrow: 1 }}>
                            <TagsPreview tags={group.tags} />
                          </Box>
                        </TableCell>
                      ) : null}
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        sx={{ backgroundColor: 'white' }}
        labelRowsPerPage="Tag groups per page:"
        component="div"
        count={total}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={perPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[50, 100, 150]}
      />
    </Box>
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
      <InfoOutlinedIcon fontSize="medium" />
      <Typography>No tag groups</Typography>
    </Box>
  );
};

export default TagGroupsList;
