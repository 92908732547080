import { useMediaQuery, useTheme } from "@mui/material";
import AlertMessage from "components/common/AlertMessage";
import { enqueueSnackbar } from "notistack";
import { useCallback } from "react";

const useEnqueueSnackbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onShowAlert = useCallback((message: string) => {
    enqueueSnackbar(<AlertMessage message={message} />, {
      autoHideDuration: 2000,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      style: {
        paddingBottom: 0,
        paddingTop: 0,
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        boxShadow: 'none',
        marginLeft: isMobile ? 0 : 80,
      },
    });
  }, [enqueueSnackbar]);

  return { onShowAlert };
}

export default useEnqueueSnackbar;
