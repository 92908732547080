import type { FC } from "react";
import type { AssetProjectEntry } from "../api";
import { AssetChip, AssetChipLabel } from "./AssetsListComponents";
import { Tooltip, Typography } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { useAssets } from "../context";
import { Box } from "@mui/system";

interface AssetProjectsProps {
  className?: string;
  projects: AssetProjectEntry[];
  restricted: boolean;
}
export const AssetProjects: FC<AssetProjectsProps> = ({ projects, className, restricted }) => {
  const visibleProjects = projects.slice(0, 2);
  const additionalItems = projects.slice(2, projects.length).map(({ name }) => name).join(', ');
  const { isList } = useAssets();

  return (
    <Box className={className}>
      {visibleProjects.map(({ id, name }) => (
        <Tooltip
          key={`${id}.${name}`}
          arrow
          followCursor
          title={<Typography sx={{ textTransform: 'capitalize', fontSize: 12, fontWeight: 500 }}>{name}</Typography>}
          sx={{ cursor: 'default' }}>
          <AssetChip
            label={
              <AssetChipLabel
                icon={restricted ? <LockOutlined sx={{ fontSize: 14, mr: '2px', }} /> : null}
                label={name}
                sx={{ fontWeight: 500, maxWidth: isList ? 160 : 100, }}
              />}
            variant="filled" />
        </Tooltip>
      ))}
      {(projects.length > visibleProjects.length) &&
        <Tooltip
          arrow
          followCursor
          title={<Typography sx={{ textTransform: 'capitalize', fontSize: 12, fontWeight: 500 }}>{additionalItems}</Typography>}
          sx={{ cursor: 'default', }}>
          <AssetChip
            label={`+${projects.length - visibleProjects.length}`}
            variant="filled"
            sx={{
              cursor: 'default',
              ['&:hover']: {
                backgroundColor: 'rgba(255,255,255, 0.6)',
              }
            }}
          />
        </Tooltip>}
    </Box>
  )
};
