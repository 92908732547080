import { TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Typography } from '@mui/material';
import { CreativesSettingsDropdown } from './index';
import { COLUMNS } from '../constants';
import type { ColumnKey, CreativesSettingsState } from '../types';
import { useFormContext } from 'react-hook-form';
import getOrderedColumnsToDisplay from '../utils/getOrderedColumnsToDisplay';
import type { FC } from 'react';

interface CreativeTableHeadProps {
  handleToggleSort: (key: ColumnKey) => void;
  orderBy: ColumnKey | null | undefined;
  desc: boolean;
}

const CreativeTableHead: FC<CreativeTableHeadProps> = ({ handleToggleSort, desc, orderBy }) => {
  const { watch } = useFormContext<CreativesSettingsState>();
  const columns = watch('columns');
  const order = watch('columnsOrder');

  const orderedColumnsToDisplay = getOrderedColumnsToDisplay(columns, order);
  const previewSize = watch('previewSize') === 'large' ? 96 : 64;

  return (
    <TableHead sx={{ position: 'relative', zIndex: 3 }}>
      <TableRow
        sx={(t) => ({
          th: {
            backgroundColor: 'white',
            border: '1px solid',
            borderColor: t.palette.grey[200],
            height: 40,
            p: 1,
            borderRight: 'none',
            maxWidth: 108,
            minWidth: 108,
          },
          'th#file_name': {
            minWidth: 320,
          },
          'th:first-of-type': {
            zIndex: 10,
            borderRight: '4px solid',
            borderColor: t.palette.grey[200],
          },
          'th:nth-child(2)': {
            borderLeft: 'none !important',
          },
          'th:last-of-type': {
            borderLeft: '4px solid',
            borderColor: t.palette.grey[200],
          },
        })}
      >
        <TableCell sx={{ minWidth: `${previewSize}px !important` }}>Preview</TableCell>
        {orderedColumnsToDisplay.map((columnKey) => {
          const { title, apiKey } = { ...COLUMNS[columnKey] };
          return (
            <TableCell key={apiKey} id={columnKey}>
              <TableSortLabel
                sx={{ width: '100%' }}
                active={orderBy === columnKey}
                direction={desc ? 'desc' : 'asc'}
                onClick={() => {
                  handleToggleSort(columnKey);
                }}
              >
                <Tooltip title={title.length > 9 ? title : null} arrow placement="top">
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '80%',
                    }}
                  >
                    {title}
                  </Typography>
                </Tooltip>
              </TableSortLabel>
            </TableCell>
          );
        })}
        <TableCell sx={{ '&': { minWidth: '112px' } }} align="right">
          <CreativesSettingsDropdown />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CreativeTableHead;
