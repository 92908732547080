import { Typography } from "@mui/material";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";

const CreativesCounter: FC = () => {
  const { watch } = useFormContext();
  const creativesCount = watch('creativesCount') as number;

  return creativesCount ? (
    <Typography
      sx={(t) => ({
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        fontSize: t.typography.body2.fontSize,
        fontWeight: 400,
        [t.breakpoints.down('md')]: {
          display: 'none',
        },
      })}
    >{creativesCount} results</Typography>
  ) : null;
}

export default CreativesCounter;
