import {
  Link as LinkIcon,
  ContentCopy as ContentCopyIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useAnchorMenu } from 'hooks';
import { useCallback, useMemo } from 'react';
import type { FC, ReactNode } from 'react';
import type { AssetEntry } from '../api';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useAssets } from '..';
import { useNavigate } from 'react-router';
import { useMyAccess } from '../../users';

interface AssetItemContextMenuProps {
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
  asset: AssetEntry;
}

interface PopupListItemProps {
  icon: ReactNode;
  text: string;
  action: (() => void) | (() => Promise<void>);
}

const PopupListItem: FC<PopupListItemProps> = ({ icon, text, action }) => (
  <MenuItem onClick={action}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText sx={{ pl: 1 }}>{text}</ListItemText>
  </MenuItem>
);

const AssetItemContextMenu: FC<AssetItemContextMenuProps> = ({ parentRef, asset: { id, name, media_url } }) => {
  const { onShowAlert } = useEnqueueSnackbar();
  const { isList } = useAssets();
  const { isOpen, anchor, handleOpenMenu, handleCloseMenu } = useAnchorMenu();
  const navigate = useNavigate();
  const access = useMyAccess();

  const onOpenMenu = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      parentRef.current?.classList.add('action-hover');
      handleOpenMenu(e);
    },
    [handleOpenMenu, parentRef]
  );

  const onCloseMenu = useCallback(() => {
    parentRef.current?.classList.remove('action-hover');
    handleCloseMenu();
  }, [handleCloseMenu, parentRef]);

  const handleCopyValue = useCallback(
    async (value: string) => {
      try {
        await navigator.clipboard.writeText(value);
        onShowAlert('Copied');
        onCloseMenu();
      } catch (err) {
        onShowAlert('Error occurs');
      }
    },
    [onCloseMenu, onShowAlert]
  );

  const editAssetRedirect = useCallback(() => {
    navigate(`update/${id}`);
  }, [id, navigate]);

  const duplicateAssetRedirect = useCallback(() => {
    navigate(`create?baseAsset=${id}`);
  }, [id, navigate]);

  const listData: PopupListItemProps[] = useMemo(() => {
    const actions = [
      {
        icon: <LinkIcon fontSize="small" />,
        text: 'Copy Media URL',
        action: () => handleCopyValue(media_url),
      },
      {
        icon: <ContentCopyIcon fontSize="small" />,
        text: 'Copy name',
        action: () => handleCopyValue(name),
      },
    ];

    const editAction = {
      icon: <EditOutlinedIcon fontSize="small" />,
      text: 'Edit',
      action: () => {
        editAssetRedirect();
      },
    };

    const duplicateAction = {
      icon: <FileCopyOutlinedIcon fontSize="small" />,
      text: 'Duplicate',
      action: () => {
        duplicateAssetRedirect();
      },
    };

    if (access?.assets.includes('update')) {
      // @ts-expect-error expects Promise<void>
      actions.push(editAction);
    }

    if (access?.assets.includes('create')) {
      // @ts-expect-error expects Promise<void>
      actions.push(duplicateAction);
    }

    return actions;
  }, [access?.assets, duplicateAssetRedirect, editAssetRedirect, handleCopyValue, media_url, name]);

  return (
    <>
      <Button
        aria-controls={isOpen ? `asset-${id}-context-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={onOpenMenu}
        variant="contained"
        color={isList ? "info" : "primary"}
        disableElevation
        sx={(t) => ({
          minWidth: isList ? 30 : 36,
          maxWidth: isList ? 30 : 36,
          minHeight: isList ? 30 : 36,
          p: 0,
          opacity: 0.9,
          borderRadius: t.borderRadius.sm,
          [`&:hover`]: {
            opacity: 0.75,
          },
        })}
      >
        <MoreVertIcon sx={{ color: isList ? 'black' : 'white', fontSize: 22 }} />
      </Button>

      <Menu id={`asset-${id}-context-menu`} anchorEl={anchor} open={isOpen} onClose={onCloseMenu}>
        {listData.map(({ text, action, icon }) => (
          <PopupListItem key={text} action={action} icon={icon} text={text}></PopupListItem>
        ))}
      </Menu>
    </>
  );
};

export default AssetItemContextMenu;
