/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useEffect, useState } from 'react';
import type { SortOptionsProps, TagEntry } from '../types';
import { orderBy } from 'lodash-es';

const useTagsSort = (initialArray: TagEntry[], initialSorting:Pick<SortOptionsProps, 'order' | 'type'>) => {
  const [sortedTags, setSortedArray] = useState<TagEntry[]>(initialArray);
  const [currentSorting, setCurrentSorting] = useState<Pick<SortOptionsProps, 'order' | 'type'>>(initialSorting);

  const setSortOrder = ({order, type}: Pick<SortOptionsProps, 'order' | 'type'> ) => {
    const sortCb = (
      type === 'time' ? (tag: TagEntry) => new Date(tag.created_at!).getTime()
        : type === 'number' ? (tag: TagEntry) => tag.creatives_count
          : (tag: TagEntry) => tag.tag_name.toLowerCase()
    );

    const sortedArray = orderBy( initialArray, [sortCb], [order] );
    setSortedArray(sortedArray);
    setCurrentSorting({order, type});
  };

  useEffect(() => {
    setSortOrder(currentSorting);
  }, [initialArray])

  return { currentSorting, sortedTags, setSortOrder };
};

export default useTagsSort;
