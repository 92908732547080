import { useEffect } from 'react';
import type { AccessError } from '../types/shared';
import { useNavigate } from 'react-router';

const useRestrictedRedirect = (error: AccessError | undefined, from: 'asset' | 'creative') => {
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      const { cause } = error;
      if (cause.error === 'No access') {
        navigate({
          pathname: '/forbidden',
          search: new URLSearchParams({
            projects: cause.projects.map((p) => p.id).join(','),
            from,
          }).toString(),
        });
      }
    }
  }, [error, from, navigate]);
};

export default useRestrictedRedirect;
