import { Cancel } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";
import { useTagGroups, sortTagGroups } from "features/search";
import type { FC } from "react";
import { useCallback } from "react";
import { useSearchContext } from "../context";

const SelectedTags: FC<{ selectedTags: number[], onSelectTag: (id: number, value: boolean) => void }> = ({ selectedTags, onSelectTag }) => {
  const { searchType } = useSearchContext();
  const { data } = useTagGroups(searchType, {
    config: {
      select: ({ data }) => sortTagGroups(data, selectedTags),
    }
  });

  const handleOnSelectTag = useCallback((tagId: number) => {
    onSelectTag(tagId, false);
  }, [onSelectTag])

  return selectedTags.length ? (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', pb: 1, px: 0.5, }}>{data.map(tag => (
      <Chip
        color="primary"
        key={`selectedTag.${tag.tag_id}:${tag.tag_name}`}
        label={tag.tag_name}
        deleteIcon={<Cancel />}
        onDelete={() => { handleOnSelectTag(tag.tag_id); }}
        sx={{ mb: 1, }} />
    ))}</Box>
  ) : null
}

export default SelectedTags;
