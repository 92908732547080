import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import useHotjarConsent from "../hooks/useHotjarConsent";

const HotjarConsent = () => {
  const { isShow, isHotjarConsent, onToggleHotjarConsent } = useHotjarConsent();

  return (
    isShow ? <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={isHotjarConsent} onChange={onToggleHotjarConsent} />}
        label="I agree that my actions will be recorded along with my email to improve ADAM as a product."
      />
    </FormGroup> : null
  )
}

export default HotjarConsent;
