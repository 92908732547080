import { Box } from '@mui/material';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import SwitchInput from 'components/common/SwitchInput';
import type { DroppableProps, DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragIndicator as DragIndicatorIcon } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import type { ColumnKey, CreativesSettingsState } from '../types';

const DroppableImprovementContainer = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => {
      setEnabled(true);
    });

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

const CreativesSettingsList: FC = () => {
  const { watch, setValue } = useFormContext<CreativesSettingsState>();
  const order = watch('columnsOrder');
  const columns = watch('columns');
  const visibleCols = Object.keys(columns).filter((k) => columns[k as ColumnKey]);
  const reorderColumns = useCallback(
    (startIndex: number, endIndex: number) => {
      const result = [...order];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      setValue('columnsOrder', result);
    },
    [order, setValue]
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      reorderColumns(result.source.index, result.destination.index);
    },
    [reorderColumns]
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <DroppableImprovementContainer droppableId={'droppableId'}>
        {(provided) => (
          <>
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}
            >
              {order.map(({ name, title }, index) => {
                const isLastActiveSwitch = visibleCols.length === 1 && name.split('.')[1] === visibleCols[0];
                return (
                  <Draggable key={name} draggableId={name} index={index}>
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{ display: 'flex', justifyContent: 'space-between' }}
                      >
                        <SwitchInput
                          disabledTitle={isLastActiveSwitch ? 'At least 1 column has to be visible' : undefined}
                          disabled={isLastActiveSwitch}
                          name={name}
                          label={title}
                          size="small"
                        />
                        <DragIndicatorIcon sx={(t) => ({ color: t.palette.grey[500] })} />
                      </Box>
                    )}
                  </Draggable>
                );
              })}
            </Box>
            {provided.placeholder}
          </>
        )}
      </DroppableImprovementContainer>
    </DragDropContext>
  );
};

export default CreativesSettingsList;
