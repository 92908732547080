import { createContext, useContext } from 'react';
import type { SearchTypesValue } from '../types';

export type ListType = 'row' | 'column';

export interface CallbackFunctionParam {
  param: 'q' | 'tags'
}

export interface SearchStateValue {
  searchTextFieldRef: { current: HTMLInputElement | null };
  searchType: SearchTypesValue;
  onResetValues: (value?: CallbackFunctionParam) => void;

  isShowPresets: boolean,
  setShowPresets: React.Dispatch<React.SetStateAction<boolean>>,

  isShowAllTags: boolean,
  setShowAllTags: React.Dispatch<React.SetStateAction<boolean>>,

  isShowResultTags: boolean,
  setShowResultTags: (value: boolean) => void,

  queryString: string,
  setQueryString: React.Dispatch<React.SetStateAction<string>>,

  queryFieldValue: string,
  setQueryFieldValue: React.Dispatch<React.SetStateAction<string>>,

  tags: (number | null)[] | undefined,
  setTags: (newValue: (number | null)[] | null | undefined) => void;

  q: string | undefined,
  setQ: (newValue: string | null | undefined) => void,
}

export type SearchContextType = SearchStateValue;

export const SearchContext = createContext<SearchContextType>({} as SearchContextType);

export function useSearchContext() {
  return useContext(SearchContext);
}
