/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import type { FC } from 'react';
import { useAssetFilesSync } from '../api/getAssetFiles';
import FilesList from 'components/common/FilesList';
import { Box, Button, Typography } from '@mui/material';
import FilesListSkeleton from '../../../components/common/FilesListSkeleton';

const AssetFilesList: FC<{ id: number }> = ({ id }) => {
  const {
    data: assetFiles,
    isError,
    isLoading,
    refetch,
  } = useAssetFilesSync({
    params: { id },
  });

  if (isLoading) {
    return <FilesListSkeleton />;
  }

  if (isError) {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography sx={{ mb: 3 }}>Unable to load files</Typography>
        <Button variant="contained" onClick={() => refetch()}>
          Try again
        </Button>
      </Box>
    );
  }

  return <FilesList files={assetFiles!} />;
};

export default AssetFilesList;
