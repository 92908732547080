import { MenuItem, Skeleton } from "@mui/material";
import type { FC } from "react";

const FilesListSkeleton: FC = () => (
  <>
    {[...Array(6).keys()].map(i => (
      <MenuItem disabled key={i} onClick={(e: React.MouseEvent<HTMLElement>) => { e.stopPropagation(); }}>
        <Skeleton
          sx={{ transform: 'translateY(0)', borderRadius: '4px' }}
          width={'100%'}
          height={'24px'}
          animation="wave" />
      </MenuItem>
    ))}
  </>
)

export default FilesListSkeleton;
