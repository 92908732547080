import type { QueryKey } from '@tanstack/react-query';
import type { TagGroupsFilteringFields } from './types';
import type { SearchTypesValue } from '../types';

interface TagGroupsFields extends Partial<TagGroupsFilteringFields> {
  type: SearchTypesValue
};

export default {
  tagGroups: ({ type, q }: TagGroupsFields) => [type, 'tags', q] as QueryKey,
  presets: (type: SearchTypesValue) => [type, 'presets'] as QueryKey,
};
