import { Box, LinearProgress, Skeleton } from '@mui/material';
import type { FC } from 'react';

const UsersFormSkeleton: FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px 0' }}>
      <LinearProgress sx={{ height: 4, my: 2, zIndex: 10, width: '100%' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Skeleton width="100px" height={22} sx={{ borderRadius: '6px' }} />
        <Skeleton width="100%" height={48} sx={{ borderRadius: '6px' }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Skeleton width="100px" height={22} sx={{ borderRadius: '6px' }} />
        <Skeleton width="100%" height={48} sx={{ borderRadius: '6px' }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Skeleton width="100px" height={22} sx={{ borderRadius: '6px' }} />
        <Skeleton width="100%" height={48} sx={{ borderRadius: '6px' }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Skeleton width="100px" height={22} sx={{ borderRadius: '6px' }} />
        <Skeleton width="100%" height={48} sx={{ borderRadius: '6px' }} />
      </Box>
    </Box>
  );
};

export default UsersFormSkeleton;
