import { isEmpty } from "lodash-es";
import type { CreativeDetailEntry } from "../api/types";

const getDetailIdValue = (obj: CreativeDetailEntry | CreativeDetailEntry[]): number | undefined => {
  if (Array.isArray(obj)) {
    return !isEmpty(obj[0]) ? obj[0].id : undefined
  } else {
    return obj.id || undefined
  }
};

export default getDetailIdValue;
