import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { SynchronousQueryConfig } from 'lib/react-query';
import type { SearchPreset, SearchPresetsResponse } from './types';
import queryKeys from './queryKeys';
import queryString from 'query-string';
import type { SearchTypesValue } from '../types';

export const getPresets = async (type: SearchTypesValue, signal?: AbortSignal): Promise<SearchPreset[]> => {
  const res = await apiClient.get<SearchPresetsResponse>(`/${type}/presets`, {
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data.data;
};

interface UseAssetOptions {
  config?: SynchronousQueryConfig<typeof getPresets>;
}

export const usePresets = (type: SearchTypesValue, { config }: UseAssetOptions = {}) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getPresets(type, signal),
    queryKey: queryKeys.presets(type),
  });
};
