import { useSuspenseQuery, useQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig, SynchronousQueryConfig } from 'lib/react-query';
import type { CreativeDetailsModel } from './types';
import queryKeys from './queryKeys';
import axios from 'axios';

export const getCreative = async (id: string, signal?: AbortSignal): Promise<CreativeDetailsModel> => {
  const res = await apiClient.get<{ data: CreativeDetailsModel }>(`/creatives/${id}`, {
    signal,
  });

  if (axios.isAxiosError(res)) {
    const error = new Error('axios error');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    error.cause = res.response?.data;
    return Promise.reject(error);
  }

  return res.data.data;
};

interface UseCreativeOptions<TData> {
  config?: QueryConfig<typeof getCreative, TData>;
  id: string;
}

interface UseCreativeSyncOptions<TData> {
  config?: SynchronousQueryConfig<typeof getCreative, TData>;
  id: string;
}

export const useCreative = <TData = CreativeDetailsModel>({ id, config }: UseCreativeOptions<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getCreative(id, signal),
    queryKey: queryKeys.creativeDetails(id),
  });
};

export const useCreativeSync = <TData = CreativeDetailsModel>({ id, config }: UseCreativeSyncOptions<TData>) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getCreative(id, signal),
    queryKey: queryKeys.creativeDetails(id),
  });
};
