import { Button } from '@mui/material';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import copy from 'copy-to-clipboard';
import type { FC } from 'react';
import { useCallback } from 'react';
import { ContentCopy } from '@mui/icons-material';

const CopyButtons: FC<{ value: string }> = ({ value }) => {
  const { onShowAlert } = useEnqueueSnackbar();

  const handleCopyAssetName = useCallback(() => {
    copy(value);
    onShowAlert('Copied successfully');
  }, [onShowAlert, value]);

  const handleCopyAssetLink = useCallback(() => {
    copy(window.location.toString());
    onShowAlert('Copied successfully');
  }, [onShowAlert]);
  return (
    <>
      <Button
        sx={{ textTransform: 'capitalize' }}
        onClick={handleCopyAssetName}
        startIcon={<ContentCopy />}
        variant="text"
      >
        Name
      </Button>
      <Button
        sx={{ textTransform: 'capitalize' }}
        onClick={handleCopyAssetLink}
        startIcon={<ContentCopy />}
        variant="text"
      >
        Link
      </Button>
    </>
  );
};

export default CopyButtons;
