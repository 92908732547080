import { useBreakpoints, useModal } from 'hooks';
import { useState, type FC, type ReactNode, useMemo, useCallback } from 'react';
import type { ListType } from './AssetsContext';
import { AssetsContext } from './AssetsContext';
import { getPersistedData, persistData } from 'utils/persist';
import useListParams from 'hooks/useListParams';

interface AuthContextProviderProps {
  children: ReactNode;
};

const AssetsContextProvider: FC<AuthContextProviderProps> = ({ children }) => {
  const { setPreviewId } = useListParams();
  const { isBreakpoint } = useBreakpoints('sm');
  const { isOpen: isVisibleFilter, toggle: handleVisibleFilter } = useModal(!isBreakpoint);
  const [countAssets, setCountAssets] = useState<number | null>(null);
  const [listType, setListType] = useState<ListType>(() => {
    if (isBreakpoint) return 'row';

    const persistedValue = getPersistedData<ListType>('listType');
    return persistedValue ?? 'row';
  });

  const handleListType = useCallback((value: ListType) => {
    window.scroll(0, 0);
    setListType(value);
    persistData('listType', value);
  }, [setListType]);

  const handleToggleAssetPreview = useCallback((id?: number) => {
    setPreviewId(id);
  }, [setPreviewId]);

  const handleSetCountAssets = useCallback((value: number) => {
    setCountAssets(value);
  }, [setCountAssets]);

  const contextValue = useMemo(
    () => ({
      isVisibleFilter,
      handleVisibleFilter,

      listType,
      handleListType,

      isGrid: listType === 'row' || isBreakpoint,
      isList: listType === 'column' && !isBreakpoint,

      handleToggleAssetPreview,

      countAssets,
      handleSetCountAssets,
    }),
    [
      isVisibleFilter,
      handleVisibleFilter,
      listType,
      handleListType,
      handleToggleAssetPreview,
      isBreakpoint,
      countAssets,
      handleSetCountAssets,
    ]
  );

  return <AssetsContext.Provider
    value={contextValue}>
    {children}
  </AssetsContext.Provider>;
};

export default AssetsContextProvider;
