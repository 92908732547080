import type { FC } from 'react';
import { useMemo } from 'react';
import type { AutocompleteProps, TextFieldProps } from '@mui/material';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useModal } from 'hooks';
import { isEmpty } from 'lodash-es';
import { Box } from '@mui/system';
import type { TagAutocompleteOption } from 'features/search';
import { useTagGroups } from 'features/search';
import { SearchTagsCollapseGroup } from './SearchComponents';
import { useSearchContext } from '../context';

interface TagsAutocompleteProps
  extends Omit<
    AutocompleteProps<GroupedTagAutoCompleteOption, true, boolean, false>,
    'value' | 'onChange' | 'options' | 'renderInput'
  > {
  textFieldProps?: TextFieldProps,
  value: number[];
  onChange: (tag: number[]) => void;
}

interface GroupedTagAutoCompleteOption extends TagAutocompleteOption {
  groupName: string;
}

const TagsAutocomplete: FC<TagsAutocompleteProps> = ({ value, onChange, textFieldProps, ...restProps }: TagsAutocompleteProps) => {
  const { toggle: onToggle } = useModal(false);
  const { searchType } = useSearchContext();
  const { data: tagList } = useTagGroups<GroupedTagAutoCompleteOption[]>(
    searchType,
    {
      config: {
        select: (data): GroupedTagAutoCompleteOption[] =>
          data.data
            .map((tagGroup) =>
              tagGroup.tags.map((tag) => ({
                id: tag.tag_id,
                label: tag.tag_name,
                groupId: tagGroup.group_id,
                groupName: tagGroup.group_name,
              }))
            )
            .flat(),
      },
    });
  const { isOpen, open, close } = useModal();

  const derivedValue = useMemo(() => {
    if (isEmpty(tagList)) return [];
    return tagList.filter((tag) => value.includes(tag.id));
  }, [tagList, value]);

  const checkOption = (option: TagAutocompleteOption) => derivedValue.some(i => i.id === option.id);

  const checkGroup = (group: string, full = true) => {
    const groupLength = tagList.filter((t) => t.groupName === group).length;
    const selectedGroupLength = derivedValue.filter((t) => t.groupName === group).length;
    if (full) {
      return groupLength === selectedGroupLength;
    }

    return selectedGroupLength > 0 && selectedGroupLength < groupLength;
  };

  const selectGroup = (group: string) => {
    const groupedTags = tagList.filter((t) => t.groupName === group);
    const selectedGroupTags = derivedValue.filter((t) => t.groupName === group);

    if (selectedGroupTags.length > 0) {
      onChange(derivedValue.filter((c) => c.groupName !== group).map((tag) => tag.id));
    } else {
      onChange([...derivedValue, ...groupedTags].map((tag) => tag.id));
    }
  };

  return (
    <Autocomplete
      {...restProps}
      id="tags-autocomplete"
      options={tagList}
      onChange={(_, option) => {
        onChange(option.map((tag) => tag.id));
      }}
      value={derivedValue}
      multiple
      open={isOpen}
      onOpen={open}
      onClose={close}
      onFocus={onToggle}
      onBlur={onToggle}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      groupBy={(option) => String(option.groupName)}
      renderOption={(props, option) => (
        <li {...props}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pl: 3.5, }}>
            <Checkbox
              id={`tagGroup.${option.groupId}.tag.${option.id}`}
              key={option.id}
              icon={<CheckBoxOutlineBlankIcon />}
              checkedIcon={<CheckBoxIcon />}
              checked={checkOption(option)}
            />
            {option.label}
          </Box>
        </li>
      )}
      renderGroup={(params) => (
        <SearchTagsCollapseGroup
          onChange={() => {
            selectGroup(params.group);
          }}
          checked={checkGroup(params.group)}
          partiallyChecked={checkGroup(params.group, false)}
          key={params.key}
          params={params}
        />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
          }}
          {...textFieldProps}
        />
      )}
      componentsProps={{
        popper: {
          placement: "bottom-start",
        }
      }}
    />
  );
};

export default TagsAutocomplete;
