import type { FC } from 'react';
import { Container, Box } from '@mui/material';
import { Layout } from 'components/layout';
import { Suspense } from 'react';
import BreadcrumbLink from 'components/layout/BreadcrumbLink';
import { CreativeEdit, CreativeEditSkeleton } from 'features/creatives';
import { Search, SearchContextProvider } from 'features/search';
import { FormProvider, useForm } from 'react-hook-form';

const CreativeEditPage: FC = () => {
  const formMethods = useForm();
  return (
    <FormProvider {...formMethods}>
      <SearchContextProvider searchType="creatives">
        <Layout childrenContainerClasses={{ paddingTop: '24px', paddingBottom: '40px', }}>
          <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'top', pt: 1, mb: 6, height: 48 }}>
            <BreadcrumbLink to="/creatives" title="Creatives" />
            <Search />
          </Box>
          <Container maxWidth={false} sx={{ '&': { p: 0 }, }}>
            <Suspense fallback={<CreativeEditSkeleton />}>
              <CreativeEdit />
            </Suspense>
          </Container>
        </Layout>
      </SearchContextProvider>
    </FormProvider>
  );
};

export default CreativeEditPage;
