import type { QueryKey } from '@tanstack/react-query';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import queryString from 'query-string';
import type { QueryConfig, SynchronousQueryConfig } from 'lib/react-query';
import type { File } from 'types/shared';
import type { CreativeFilesResponse } from './types';

interface CreativeFilesPayload {
  id: number;
}

export const getCreativeFiles = async (
  { params: { id } }: { params: CreativeFilesPayload },
  signal?: AbortSignal
): Promise<File[]> => {
  const res = await apiClient.get<CreativeFilesResponse>(`/creatives/${id}/files`, {
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data.data;
};

interface UseCreativeFiles<TData> {
  config?: QueryConfig<typeof getCreativeFiles, TData>;
  params: CreativeFilesPayload;
}

interface UseSyncCreativeFiles<TData> {
  config?: SynchronousQueryConfig<typeof getCreativeFiles, TData>;
  params: CreativeFilesPayload;
}

export const useCreativeFiles = <TData = File[]>({ params, config }: UseCreativeFiles<TData>) => {
  const { id } = params;
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getCreativeFiles({ params }, signal),
    queryKey: ['creatives', id, 'files'] as QueryKey,
  });
};

export const useCreativeFilesSync = <TData = File[]>({ params, config }: UseSyncCreativeFiles<TData>) => {
  const { id } = params;
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getCreativeFiles({ params }, signal),
    queryKey: ['creatives', id, 'files'] as QueryKey,
  });
};
