import { ErrorOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { isAxiosError } from "axios";
import type { FC } from "react";
import { useCallback } from "react";
import { useCreateTagGroup } from "..";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import addTagGroupSchema from "../validation/AddTagGroup.schema";

interface FormValues {
  tagGroupName: string;
}

const AddTagGroupDialog: FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose, }) => {
  const formMethods = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(addTagGroupSchema),
    defaultValues: {
      tagGroupName: "",
    },
  });


  const {
    control,
    setValue,
    setError,
    handleSubmit,
    formState: { errors, },
  } = formMethods;

  const tagGroupNameValue = useWatch({
    control,
    name: 'tagGroupName',
  }).trim();

  const handleTagGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("tagGroupName", event.target.value);
  };

  const createTagGroup = useCreateTagGroup({
    config: {
      onSuccess: () => {
        onClose();
      },
      onError: (e) => {
        if (isAxiosError(e) && e.response) {
          setError("tagGroupName", { message: (e.response.data as { message: string }).message });
        }
      },
    },
  });

  const onSubmit = useCallback(() => {
    createTagGroup.mutate({ group_name: tagGroupNameValue });
  }, [tagGroupNameValue, createTagGroup]);

  const handleClose = useCallback(() => {
    setValue("tagGroupName", "");;
    onClose();
  }, [setValue, onClose]);

  const onKeyUpCapture = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setError("tagGroupName", {});
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)().catch(console.error);;
    }
  };

  return (<>
    <Dialog
      disableRestoreFocus
      open={isOpen}
      onClose={() => {
        !createTagGroup.isPending ? handleClose() : null;
      }}
      PaperProps={{
        sx: {
          width: '90%',
          maxWidth: 440
        }
      }}
    >
      <DialogTitle
        id="add-tag-group-dialog-title"
        align="center"
        sx={t => ({ fontWeight: 500, fontSize: t.typography.h6.fontSize, })}>
        New Tag group
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexGrow: 1, pt: 2, }}>
          <Controller control={control} name="tagGroupName" render={({ field }) => (
            <TextField
              {...field}
              id="add-tag-group-dialog-field"
              required
              fullWidth
              autoFocus
              label="Name"
              onKeyUpCapture={onKeyUpCapture}
              onChange={handleTagGroupNameChange}
              error={Boolean(errors.tagGroupName?.type)}
              helperText={
                errors.tagGroupName?.type === 'required' && (
                  <Typography fontSize={13} marginTop={-2}>
                    {errors.tagGroupName.message}
                  </Typography>
                )
              }
              disabled={createTagGroup.isPending}
              InputProps={{
                sx: { mb: 2, height: 40, },
                endAdornment: createTagGroup.isPending ? (
                  <CircularProgress size={20} color="success" />
                ) : createTagGroup.isError || errors.tagGroupName?.type ? (
                  <ErrorOutline color="error" sx={{ width: 22 }} />
                ) : null,
              }}
              InputLabelProps={{
                sx: { fontSize: 14, top: -5, }
              }}
              inputProps={{ style: { fontSize: 14, fontWeight: 400, }, maxLength: 120 }}
            />
          )} />


        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', mb: 2, }}>
        <Button disabled={createTagGroup.isPending} onClick={onClose}>Cancel</Button>
        <Button disabled={createTagGroup.isPending || !!errors.tagGroupName?.type} onClick={onSubmit} variant="contained">Add tag group</Button>
      </DialogActions>
    </Dialog>
  </>)
}

export default AddTagGroupDialog;
