import { Box, IconButton, Typography, useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { useModal } from '../../../hooks';
import CloseIcon from '@mui/icons-material/Close';
import useMyAccess from '../../users/hooks/useMyAccess';
import { useParams } from 'react-router';
const ViewAssetActionBlock = () => {
  const t = useTheme();
  const { isOpen, toggle } = useModal();
  const access = useMyAccess();
  const { assetId } = useParams();

  if (!access || !access.assets.includes('update') || !access.assets.includes('create')) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1.25,
        borderRadius: 5,
        position: 'fixed',
        right: 24,
        bottom: 40,
        zIndex: 10,
        backgroundColor: 'white',
        p: 1,
        boxShadow:
          '0px 3px 5px -1px rgba(0, 0, 0, 0.10), 0px 6px 10px 0px rgba(0, 0, 0, 0.07), 0px 1px 18px 0px rgba(0, 0, 0, 0.06)',
      }}
    >
      <IconButton onClick={toggle} sx={{ rounded: '50%' }}>
        {!isOpen ? <MoreVertIcon /> : <CloseIcon />}
      </IconButton>
      {isOpen && (
        <Link
          to={`/assets/create?baseAsset=${assetId}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            textDecoration: 'none',
            padding: '0 5px',
          }}
        >
          <Typography fontSize={13} color="text.primary" fontWeight={500}>
            duplicate
          </Typography>
        </Link>
      )}

      <Link
        to={`/assets/update/${assetId}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          backgroundColor: t.palette.primary.main,
          textTransform: 'uppercase',
          textDecoration: 'none',
          color: 'white',
          borderRadius: '20px',
          padding: '8px 16px',
        }}
      >
        <Typography fontWeight={500} variant="body2">
          edit
        </Typography>
        <EditIcon />
      </Link>
    </Box>
  );
};

export default ViewAssetActionBlock;
