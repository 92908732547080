import type { FC } from 'react';
import { useCallback } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useMyAccess } from 'features/users';
import AddTagGroupDialog from './AddTagGroupDialog';
import { useModal } from 'hooks';

const AddTagGroup: FC = () => {
  const { isOpen, open, close } = useModal();
  const access = useMyAccess();

  const handleOpen = useCallback(() => {
    open();
  }, [open]);

  return access?.tags.includes('create') ? (
    <>
      <Button
        onClick={handleOpen}
        disableElevation
        variant="contained"
        color="primary"
        sx={{ padding: 0, minWidth: 40, minHeight: 40, borderRadius: 20 }}
      >
        <AddIcon sx={{ color: 'white', fontSize: 22 }} />
      </Button>
      <AddTagGroupDialog isOpen={isOpen} onClose={close} />
    </>
  ) : null;
};

export default AddTagGroup;
