import type { FC } from "react";
import { useCallback } from "react";
import { useChangeFavourites } from "../api";
import { useAssets } from "../context";
import useEnqueueSnackbar from "hooks/useEnqueueSnackbar";
import { CircularProgress, Tooltip, Typography } from "@mui/material";
import { AssetUtilButton } from "./AssetsListComponents";
import { Star, StarBorder } from "@mui/icons-material";
import type { AssetEntry, SortingOptionValue } from '..';
import { useQueryClient } from '@tanstack/react-query';
import useListParams from '../../../hooks/useListParams';
import queryKeys from '../api/queryKeys';

interface AssetAddToFavouritesProps {
  isPreview?: boolean;
  assetId: number;
  favourite: boolean;
}

interface AssetListCachePage {
  data: AssetEntry[];
}

interface AssetListCache {
  pages: AssetListCachePage[];
  pageParams: number[];
}

const AssetAddToFavourites: FC<AssetAddToFavouritesProps> = ({ assetId, favourite, isPreview }) => {
  const queryClient = useQueryClient();
  const { desc, orderBy, favourite: favouriteParam, tags, q } = useListParams();
  const { isList, isGrid } = useAssets();
  const { onShowAlert } = useEnqueueSnackbar();
  const queryKey = queryKeys.assetsList({
    desc,
    favourite: favouriteParam,
    tags: tags as number[],
    q,
    order_by: orderBy as SortingOptionValue,
  });
  const changeFavourites = useChangeFavourites({
    config: {
      onMutate: async () => {
        await queryClient.cancelQueries({ queryKey });

        const previousAssets = queryClient.getQueryData(queryKey);

        await queryClient.setQueryData(queryKey, (old: AssetListCache) => {
          let updatedValue: AssetListCache;
          for (const [pageIndex, page] of old.pages.entries()) {
            const item = page.data.find((asset) => asset.id === assetId);
            if (item) {
              const index = page.data.indexOf(item);
              const pageDataClone = [...page.data];
              pageDataClone.splice(index, 1, {
                ...item,
                favourite: !favourite,
              });
              const pagesClone = [...old.pages];
              pagesClone.splice(pageIndex, 1, {
                ...old.pages[pageIndex],
                data: pageDataClone,
              });

              updatedValue = { ...old, pages: pagesClone };
              break;
            }
          }
          // @ts-expect-error return mutated cache
          return updatedValue;
        });

        return { previousAssets };
      },
      onSuccess: () => {
        onShowAlert('Success');
      },
      onError: async (err, payload, context) => {
        await queryClient.setQueryData(queryKey, (context as { previousAssets: AssetListCache }).previousAssets);
        onShowAlert('Error');
        console.error(err);
      },
    },
  });

  const handleChangeFavourites = useCallback(() => {
    changeFavourites.mutate({ asset_id: assetId, favourite: !favourite });
  }, [changeFavourites, assetId, favourite]);

  return (
    <Tooltip
      title={isGrid && favourite ? 'Remove from favourites' : isGrid ? 'Add to favourites' : null}
      placement={isPreview ?? isGrid ? 'left' : 'bottom'}
      arrow={isList}
    >
      <AssetUtilButton
        preview={isPreview}
        disabled={changeFavourites.isPending}
        disableElevation
        onClick={handleChangeFavourites}
        variant={isPreview ?? isGrid ? 'contained' : 'text'}
      >
        {changeFavourites.isPending ? (
          <CircularProgress
            size={22}
            sx={{ color: isPreview ?? isGrid ? 'white' : 'black', mr: !isPreview && isList ? 1 : 0 }}
          />
        ) : favourite ? (
          <Star
            sx={{ fontSize: 22, color: isPreview ?? isGrid ? 'white' : 'black', mr: !isPreview && isList ? 1 : 0 }}
          />
        ) : (
          <StarBorder
            sx={{ fontSize: 22, color: isPreview ?? isGrid ? 'white' : 'black', mr: !isPreview && isList ? 1 : 0 }}
          />
        )}
        {!isPreview && isList && (
          <Typography
            sx={(t) => ({
              fontSize: t.typography.body2.fontSize,
              fontWeight: 500,
              color: t.palette.primary.main,
              whiteSpace: 'nowrap',
            })}
          >
            Add to favourites
          </Typography>
        )}
      </AssetUtilButton>
    </Tooltip>
  );
};

export default AssetAddToFavourites;
