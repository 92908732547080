import { Button, IconButton, Paper, Popover } from '@mui/material';
import type { FC } from 'react';
import { useCallback } from 'react';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { useAnchorMenu } from 'hooks';
import RadioGroup from 'components/common/RadioGroup';
import { Box } from '@mui/system';
import { PREVIEW_OPTIONS } from '../constants';
import { useFormContext } from 'react-hook-form';
import { getPersistedData, persistData } from 'utils/persist';
import CreativesSettingsList from './CreativesSettingsList';
import type { CreativesSettingsState } from '../types';

const CreativesSettingsDropdown: FC = () => {
  const { getValues, reset } = useFormContext();
  const { isOpen, handleOpenMenu, handleCloseMenu, anchor } = useAnchorMenu();

  const onClose = useCallback(() => {
    const persistedValues = getPersistedData<CreativesSettingsState>('creativesSettingsState');
    reset(persistedValues);
    handleCloseMenu();
  }, [reset, handleCloseMenu]);

  const onSave = useCallback(() => {
    const formStateValues = getValues();
    persistData('creativesSettingsState', formStateValues);
    handleCloseMenu();
  }, [handleCloseMenu, getValues]);

  return (
    <>
      <IconButton sx={{ width: 36, height: 36 }} onClick={handleOpenMenu} aria-describedby={'settings-dropdown'}>
        <TuneOutlinedIcon />
      </IconButton>
      <Popover
        id={'settings-dropdown'}
        open={isOpen}
        onClick={onSave}
        onClose={onSave}
        anchorEl={anchor}
        role={undefined}
        sx={{
          height: 560,
        }}
        hideBackdrop
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Paper
          onClick={(e) => {
            e.stopPropagation();
          }}
          sx={{ width: 330, px: 2, pt: 2 }}
        >
          <>
            <RadioGroup
              id="preview-size-group-label"
              name="previewSize"
              label="Preview size"
              options={PREVIEW_OPTIONS}
              labelProps={{
                sx: (t) => ({ fontSize: t.typography.body2.fontSize, fontWeight: 500, color: t.palette.primary.main }),
              }}
              controlProps={{
                sx: {
                  display: 'flex',
                  flexGrow: 1,
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                },
              }}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                mb: 1,
                '& .MuiFormControlLabel-root:last-child': { mr: 0 },
              }}
            />

            <CreativesSettingsList />

            <Box
              sx={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                background: 'white',
                pt: 1,
                pb: 2,
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Button variant="text" onClick={onClose}>
                Cancel
              </Button>
            </Box>
          </>
        </Paper>
      </Popover>
    </>
  );
};

export default CreativesSettingsDropdown;
