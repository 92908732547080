import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import type { AssetEntry } from '../api';
import {
  AssetChip,
} from './AssetsListComponents';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { ArrowBack, ArrowForward, Close, ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { AssetItemListTags } from './AssetTagsComponents';
import { debounce, isEmpty } from 'lodash-es';
import type { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import AssetAddToFavourites from './AssetAddToFavourites';
import AssetDownloadFileButton from "./AssetDownloadFileButton";
import MediaContentView from 'components/common/MediaContentView';
import useListParams from 'hooks/useListParams';
import AssetCopyLinkButton from './AssetCopyLinkButton';

interface AssetPreviewProps {
  assetsList: AssetEntry[];
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<{ count: number; data: AssetEntry[] }, unknown>>;
}

const AssetPreviewPopup: FC<AssetPreviewProps> = ({ assetsList, isFetchingNextPage, hasNextPage, fetchNextPage }) => {
  const { setPreviewId, previewId } = useListParams();
  const [currentAsset, setCurrentAsset] = useState<AssetEntry | undefined>(() => assetsList.find((value) => value.id === previewId));

  const { onShowAlert } = useEnqueueSnackbar();

  const handlePrevClick = useCallback(() => {
    const currentIndex = assetsList.findIndex((value) => value.id === previewId);
    if (currentIndex > 0) {
      const prevAsset = assetsList[currentIndex - 1];
      setPreviewId(prevAsset.id);
    }
  }, [setPreviewId, assetsList, previewId]);

  const handleNextClick = useCallback(async () => {

    const currentIndex = assetsList.findIndex((value) => value.id === previewId);
    if (currentIndex === assetsList.length - 1) {
      await fetchNextPage().catch(console.error);
      const nextAsset = assetsList[currentIndex + 1];
      setPreviewId(nextAsset.id);
      return;
    }

    if (currentIndex < assetsList.length - 1) {
      setPreviewId(assetsList[currentIndex + 1].id);
      return;
    }
  }, [assetsList, previewId, setPreviewId, fetchNextPage]);

  const handleClosePreview = useCallback(() => {
    setPreviewId(undefined);
  }, [setPreviewId]);

  useEffect(() => {
    if (!previewId) return;

    const currentIndex = assetsList.findIndex((value) => value.id === previewId);
    if (currentIndex > -1) {
      setCurrentAsset(() => assetsList[currentIndex]);
    } else {
      setPreviewId(undefined);
    }
  }, [setCurrentAsset, setPreviewId, assetsList, previewId])

  const handleCopyValue = useCallback(async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      onShowAlert('Copied');
    } catch (err) {
      onShowAlert('Error occurs');
    }
  }, [onShowAlert]);

  const debouncePrevClick = useMemo(() => debounce(handlePrevClick, 300), [handlePrevClick]);
  const debounceNextClick = useMemo(() => debounce(handleNextClick, 300), [handleNextClick]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        debouncePrevClick();
        return;
      }

      if (event.key === 'ArrowRight') {
        debounceNextClick()?.catch(console.error);
        return;
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [debouncePrevClick, debounceNextClick]);

  return useMemo(() => {
    if (!currentAsset) {
      return null;
    };

    const currentIndex = assetsList.findIndex((value) => value.id === previewId);
    return (
      <Dialog fullScreen open={!!previewId} onClose={handleClosePreview}>
        <DialogTitle component={Box} sx={{ display: 'flex', justifyContent: 'space-between', p: 4 }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', ['&:hover .toggle-visibility']: { visibility: 'visible' } }}
          >
            <Typography
              component={Link}
              variant="h2"
              to={`/assets/${currentAsset.id}`}
              sx={(t) => ({
                color: t.palette.primary.main,
                mb: 2,
                textDecoration: 'none',
                wordBreak: 'break-all',
                ['&:hover']: { textDecoration: 'underline' },
              })}
            >
              {currentAsset.name}
            </Typography>
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', visibility: 'hidden' }}
              className={'toggle-visibility'}
            >
              <Button
                startIcon={<ContentCopyIcon fontSize="small" />}
                sx={{ textTransform: 'capitalize', mr: 2, borderRadius: '4px' }}
                onClick={() => handleCopyValue(currentAsset.name)}
              >
                Name
              </Button>
              <Button
                startIcon={<ContentCopyIcon fontSize="small" />}
                sx={{ textTransform: 'capitalize', borderRadius: '4px' }}
                onClick={() => handleCopyValue(`${location.href}/${currentAsset.id}`)}
              >
                Link
              </Button>
            </Box>
          </Box>
          <IconButton onClick={handleClosePreview} sx={{ maxWidth: 42, maxHeight: 42 }}>
            <Close sx={(t) => ({ fontSize: 32, color: t.palette.grey[600] })} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 4, width: '100%' }}>
          <Box
            sx={(t) => ({
              display: 'flex',
              flexGrow: 1,
              width: '100%',
              height: '100%',
              [t.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            })}
          >
            <Box
              sx={(t) => ({
                display: 'flex',
                flexGrow: 1,
                [t.breakpoints.up('md')]: {
                  minWidth: '25%',
                  maxWidth: 200,
                },
                [t.breakpoints.down('md')]: {
                  width: '100%',
                },
              })}
            >
              <Box>
                {!isEmpty(currentAsset.format) && <AssetChip label={currentAsset.format} variant="filled" sx={{ textTransform: 'uppercase' }} />}
                {!isEmpty(currentAsset.tags) && <AssetItemListTags tags={currentAsset.tags} />}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
              }}
            >
              <Box
                sx={(t) => ({
                  display: 'flex',
                  maxWidth: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',

                  [t.breakpoints.down('md')]: {
                    my: 2,
                    maxHeight: '40vh'
                  },
                })}
              >
                <MediaContentView previewPopup thumbnail={currentAsset.thumbnail} media_type={currentAsset.asset_type} url={currentAsset.media_url} searchType={'assets'} />
              </Box>
            </Box>
            <Box
              sx={(t) => ({
                display: 'flex',
                flexGrow: 1,
                [t.breakpoints.up('md')]: {
                  minWidth: '25%',
                  maxWidth: 200,
                  justifyContent: 'end',
                },
                [t.breakpoints.down('md')]: {
                  width: '100%',
                  alignItems: 'start',
                  justifyContent: 'center',
                },
              })}
            >
              <Box
                sx={(t) => ({
                  display: 'flex',
                  gap: 1,
                  [t.breakpoints.up('md')]: {
                    width: 36,
                    flexDirection: 'column',
                  },
                  [t.breakpoints.down('md')]: {
                    flexDirection: 'row',
                  },
                })}
              >
                <AssetAddToFavourites isPreview assetId={currentAsset.id} favourite={currentAsset.favourite} />
                <AssetDownloadFileButton isPreview assetId={currentAsset.id} variant="icon" tooltip />
                <AssetCopyLinkButton isPreview assetId={currentAsset.id} />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 4, justifyContent: 'space-between' }}>
          <Button
            onClick={debouncePrevClick}
            sx={{ ml: -1, fontSize: 26, fontWeight: 400, textTransform: 'capitalize' }}
            startIcon={<ArrowBack sx={{ fontSize: '32px !important' }} />}
            disabled={!currentIndex || isFetchingNextPage}
          >
            Prev
          </Button>
          <Button
            onClick={debounceNextClick}
            sx={{ mr: -1, fontSize: 26, fontWeight: 400, textTransform: 'capitalize' }}
            endIcon={
              isFetchingNextPage ? (
                <CircularProgress size={22} />
              ) : (
                <ArrowForward sx={{ fontSize: '32px !important' }} />
              )
            }
            disabled={(!hasNextPage && currentIndex === assetsList.length - 1) || isFetchingNextPage}
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [
    previewId,
    assetsList,
    currentAsset,
    hasNextPage,
    isFetchingNextPage,
    handleClosePreview,
    handleCopyValue,
    setPreviewId,
    debounceNextClick,
    debouncePrevClick,
  ]);

};

export default AssetPreviewPopup;
