import * as yup from 'yup';
import { PROJECT_DEPENDANT_ROLES } from '../hooks/useMyAccess';

const emailRegex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);

export const userSchema = yup.object().shape({
  email: yup.string().required().matches(emailRegex, 'Email is not valid').label('Email'),
  access: yup.string().required().label('Access level'),
  projects: yup
    .array()
    .of(yup.number().required())
    .required()
    .when('access', {
      is: (access: string) => {
        return !PROJECT_DEPENDANT_ROLES.some((r) => r === access);
      },
      then: (schema) => schema.notRequired().transform(() => []),
      otherwise: (schema) =>
        schema.test({
          message: 'Select at least 1 project',
          test: (arr) => arr.length >= 1,
        }),
    })
    .label('Project'),
  tag: yup
    .string()
    .required()
    .min(3)
    .max(3)
    .label('Tag')
    // only latin chars
    .matches(new RegExp('^[a-zA-Z]+$'), 'Only latin characters are allowed')
    .test({ test: (tag) => tag === tag.toUpperCase(), message: 'Tag should be in the uppercase' }),
  // team: yup.number().label('Team'),
});
