import type { BaseSyntheticEvent, FC } from 'react';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { Titled } from 'react-titled';
import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogTitle, Grid, Typography } from '@mui/material';
import CircularSpinner from '../../components/common/CircularSpinner';
import { Layout } from '../../components/layout';
import { AssetForm, AssetsContextProvider, CreateUpdateAssetActionBlock, useUpdateAsset } from 'features/assets';
import { FormProvider, useForm } from 'react-hook-form';
import type { AssetFormType } from 'features/assets';
import { yupResolver } from '@hookform/resolvers/yup';
import { assetSchema } from 'features/assets';
import { useAsset } from 'features/assets';
import { useNavigate, useParams } from 'react-router';
import { useBlocker } from 'react-router-dom';
import { has, isEmpty } from 'lodash-es';
import { getAssetTypeFromUrl } from 'features/assets';
import extractSelectedTagsIds from '../../features/assets/utils/extractSelectedTagsIds';
import MediaContentView from 'components/common/MediaContentView';
import BreadcrumbLink from 'components/layout/BreadcrumbLink';
import { useModal } from 'hooks';
import { useMyAccess } from 'features/users';

interface BackButtonProps {
  isPreventRoute: boolean;
  isPending: boolean;
  assetId: string | undefined;
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>;
};

const BackButton: FC<BackButtonProps> = ({ isPreventRoute, isPending, assetId, onSubmit }) => {
  const { isOpen, open, close } = useModal();
  const navigate = useNavigate();

  const routeBlocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (isPreventRoute) {
      if (currentLocation.pathname !== nextLocation.pathname) {
        open();
        return true; // Prevent navigation
      }
      handleBack(nextLocation.pathname);
      return false; // Allow navigation
    }

    return false;
  });

  const handleBack = useCallback((nextLocation?: string) => {
    history.length <= 2 || !has(history.state, 'key')
      ? navigate(nextLocation ?? `/assets/${assetId}`)
      : history.back();
  }, [assetId, navigate]);

  const handleSaveChanges = useCallback(async () => {
    await onSubmit();
    routeBlocker.state === 'blocked' ? routeBlocker.proceed() : null;
    close();
  }, [routeBlocker, close, onSubmit]);

  const handleLeave = useCallback(() => {
    routeBlocker.state === 'blocked' ? routeBlocker.proceed() : null;
    close();
  }, [routeBlocker, close]);

  return (
    <>
      <BreadcrumbLink onClick={() => { handleBack(); }} title="Back" />
      <Dialog
        open={isOpen}
        onClose={close}
        PaperProps={{ sx: { maxWidth: 440, width: '90%', py: 1, } }}
        transitionDuration={{
          appear: 0, enter: 0, exit: 0,
        }}>
        <DialogTitle variant="h4" align="center" sx={t => ({ color: t.palette.primary.main, fontWeight: 500, })}>
          Leave page without saving?
        </DialogTitle>
        <DialogActions sx={{ justifyContent: 'center', }}>
          <Button
            onClick={handleSaveChanges}
            autoFocus
            disabled={isPending}
            startIcon={isPending && <CircularProgress
              size={16}
              sx={{ color: 'black' }}
            />}
          >Save changes</Button>
          <Button onClick={handleLeave} variant={'contained'}>Leave without saving</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const UpdateAsset = () => {
  const [isPreventRoute, setIsPreventRoute] = useState(false);
  const { assetId } = useParams();
  const { data: asset } = useAsset({ id: Number(assetId) });
  const navigate = useNavigate();
  const access = useMyAccess();

  useEffect(() => {
    if (access && !access.assets.includes('update')) {
      navigate({
        pathname: '/forbidden',
        search: new URLSearchParams({
          from: 'asset',
          action: 'update',
        }).toString(),
      });
    }
  }, [access, navigate]);

  const { mutateAsync, isPending } = useUpdateAsset({
    config: {
      onSuccess: () => {
        // navigate(`/assets/${assetId}`);
      },
      onError: () => {
        setIsPreventRoute(true);
      }
    },
  });

  const formMethods = useForm<AssetFormType>({
    resolver: yupResolver(assetSchema),
    defaultValues: {
      name: asset.name,
      mediaUrl: asset.media_url,
      thumbnail: asset.thumbnail,
      tags: !isEmpty(asset.tags)
        ? asset.tags.reduce<Record<string, boolean>>((acc, currentTag) => {
          const key = `${currentTag.name}:${currentTag.id}`;
          acc[key] = true;
          return acc;
        }, {})
        : {},
      comment: asset.comment ?? '',
      aiPrompt: asset.ai_prompt ?? '',
      modelName: asset.model_name ?? '',
      modelVideoId: asset.model_video_id ?? undefined,
      projects: asset.projects.map((p) => p.id),
      restricted: asset.restricted,
      customer: asset.customer ?? '',
      performer: asset.performer ?? '',
    },
  });

  const { reset, handleSubmit, watch, formState: { dirtyFields } } = formMethods;

  const [thumbnail, url] = [watch('thumbnail'), watch('mediaUrl')];

  useEffect(() => {
    setIsPreventRoute(!isEmpty(dirtyFields));
  }, [dirtyFields]);

  const onSubmit = async ({
    name,
    mediaUrl,
    aiPrompt,
    customer,
    tags,
    comment,
    performer,
    thumbnail,
    restricted,
    projects,
    modelName,
    modelVideoId
  }: AssetFormType) => {
    setIsPreventRoute(false);
    const selectedTagIds = extractSelectedTagsIds(tags);

    await mutateAsync({
      id: Number(assetId),
      name,
      media_url: mediaUrl,
      ai_prompt: aiPrompt,
      customer,
      comment,
      performer,
      thumbnail,
      restricted,
      projects,
      tags: selectedTagIds,
      model_name: modelName,
      model_video_id: !isEmpty(modelVideoId) ? Number(modelVideoId) : undefined
    });

    navigate(`/assets/${assetId}`);
  };

  const discardChanges = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <FormProvider {...formMethods}>
      <AssetsContextProvider>
        <Layout childrenContainerClasses={{ pb: 12, }}>
          <Titled title={(title) => `Update asset | ${title}`} />
          <Box
            sx={(t) => ({
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'start',
              [t.breakpoints.down('sm')]: {
                pt: 6,
              },
              [t.breakpoints.up('sm')]: {
                pt: 1,
              },
              mb: 6,
              height: 48,
            })}
          >
            <BackButton isPreventRoute={isPreventRoute} assetId={assetId} isPending={isPending} onSubmit={handleSubmit(onSubmit)} />
          </Box>
          <Container
            sx={(t) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              p: 0,
              [t.breakpoints.up('md')]: {
                flexDirection: 'row',
              },
            })}
            maxWidth={false}
          >
            <Suspense fallback={<CircularSpinner containerSx={{ height: '100vh' }} />}>
              <Grid container item xs={12} md={6} display="flex" flexDirection="column" alignItems="center">
                <Box
                  sx={(t) => ({
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    maxHeight: 420,
                    [t.breakpoints.up('xl')]: {
                      maxHeight: 700,
                    },
                  })}
                >
                  <MediaContentView thumbnail={thumbnail} media_type={getAssetTypeFromUrl(url)} url={url} searchType={'assets'} />
                </Box>
              </Grid>
              <Grid container item xs={12} md={6} display="flex" flexDirection="column">
                <Typography variant="h3" sx={{ mb: 2.5 }}>
                  Edit asset
                </Typography>
                <AssetForm />
                <CreateUpdateAssetActionBlock
                  onSubmit={handleSubmit(onSubmit)}
                  onDiscard={discardChanges}
                  isSubmitting={isPending}
                />
              </Grid>
            </Suspense>
          </Container>
        </Layout>
      </AssetsContextProvider>
    </FormProvider>
  );
};

export default UpdateAsset;
