import * as amplitude from '@amplitude/analytics-browser';
import config from 'config';
import type { UserEntry } from 'features/users';

amplitude.init(config.AMPLITUDE_API_KEY, {
  logLevel: amplitude.Types.LogLevel.Error,
  defaultTracking: {
    attribution: false,
    pageViews: true,
    sessions: true,
    formInteractions: true,
    fileDownloads: false,
  },
});

export const trackUser = (me: UserEntry): void => {
  amplitude.setUserId(me.email);
  const identifyEvent = new amplitude.Identify();
  identifyEvent.setOnce('email', me.email);
  identifyEvent.setOnce('access_level', me.access_level ? me.access_level : '');
  identifyEvent.setOnce('projects', me.projects ? me.projects.map((p) => p.name) : []);
  identifyEvent.setOnce('customer_tag', me.customer_tag);
  amplitude.identify(identifyEvent);
}

export const trackEvent = (eventName: string, eventProperties?: Record<string, unknown>): void => {
  amplitude.track(eventName, eventProperties);
}
