import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { ChangeFavouritesPayload, ChangeFavouritesResponse } from './types';

export const changeFavourites = async ({
  asset_id,
  ...data
}: ChangeFavouritesPayload): Promise<ChangeFavouritesResponse> => {
  const res = await apiClient.patch<ChangeFavouritesResponse>(`/assets/favourite/${asset_id}`, data);
  return res.data;
};

interface UseChangeFavouritesOptions {
  config?: MutationConfig<typeof changeFavourites>;
}

export const useChangeFavourites = ({ config }: UseChangeFavouritesOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    onError: async (...args) => {
      if (config?.onError) {
        await config.onError(...args);
      }
    },
    onSettled: () => {
      void queryClient.invalidateQueries({
        queryKey: ['assets'],
      });
    },
    mutationFn: changeFavourites,
  });
};
