import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import type { FC } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { ErrorOutline } from '@mui/icons-material';
import { isAxiosError } from 'axios';
import { useUpdateTagGroup } from '../../api';
import { yupResolver } from '@hookform/resolvers/yup';
import { tagGroupNameSchema } from 'features/tags';
import { useMyAccess } from 'features/users';

interface TagGroupNameProps {
  groupName: string;
  groupId: number;
}

interface FormValues {
  groupName: string;
}

const TagGroupName: FC<TagGroupNameProps> = ({ groupName, groupId }) => {
  const access = useMyAccess();

  const formMethods = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(tagGroupNameSchema),
    defaultValues: {
      groupName,
    },
  });

  const {
    control,
    setValue,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods;

  const groupNameValue = useWatch({
    control,
    name: 'groupName',
  }).trim();

  const updateTagGroup = useUpdateTagGroup(groupId, {
    config: {
      onError: (e) => {
        if (isAxiosError(e) && e.response) {
          setValue('groupName', groupName);
          setError('groupName', { message: (e.response.data as { message: string }).message });
        }
      },
    },
  });

  const onBlur = () => {
    if (errors.groupName?.type === 'required') {
      return;
    }

    handleSubmit(onSubmit)().catch(console.error);
  };

  const onKeyUpCapture = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)().catch(console.error);
    }
  };

  const onSubmit = () => {
    if (groupName === groupNameValue) return;
    updateTagGroup.mutate({ group_name: groupNameValue });
  };

  return access?.tags.includes('update') ? (
    <Box sx={{ position: 'relative', '& fieldset': { borderColor: 'transparent' } }}>
      <Controller
        control={control}
        name="groupName"
        render={({ field }) => (
          <TextField
            fullWidth
            variant="outlined"
            error={Boolean(errors.groupName?.type)}
            onKeyUp={onKeyUpCapture}
            helperText={
              errors.groupName?.type === 'required' && (
                <Typography fontSize={14} marginTop={-2} marginBottom={2}>
                  {errors.groupName.message}
                </Typography>
              )
            }
            disabled={isSubmitting}
            InputProps={{
              sx: { mb: 2 },
              endAdornment: updateTagGroup.isPending ? (
                <CircularProgress size={20} color="success" />
              ) : updateTagGroup.isError ? (
                <ErrorOutline color="error" sx={{ width: 22 }} />
              ) : null,
            }}
            inputProps={{ style: { fontSize: 20, padding: 8 }, maxLength: 120 }}
            {...field}
            onBlur={onBlur}
          />
        )}
      />
    </Box>
  ) : (
    <Box>
      <Typography
        sx={{
          fontSize: 20,
          px: 1,
          py: 2,
          mb: 1,
        }}
      >
        {groupNameValue}
      </Typography>
    </Box>
  );
};

export default TagGroupName;
