import { ArrowUpward } from "@mui/icons-material";
import { Box, Button, Slide } from "@mui/material";
import { useModal } from "hooks";
import type { FC } from "react";
import { useCallback, useEffect } from "react";

const AssetScrollTop: FC = () => {
  const { isOpen: isShow, open: onShow, close: onClose } = useModal();

  const handleScrollTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleScroll = useCallback(() => {
    if (window.scrollY > window.innerHeight / 2 && !isShow) {
      onShow();
      return;
    }

    if (window.scrollY < window.innerHeight / 2 && isShow) {
      onClose();
      return;
    }
  }, [onShow, onClose, isShow]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <Slide direction="up" in={isShow} mountOnEnter unmountOnExit>
      <Button
        onClick={handleScrollTop}
        sx={(t) => ({
          minWidth: 72,
          minHeight: 56,
          height: 56,
          position: 'fixed',
          zIndex: 8,
          bottom: 42,
          right: 28,
          p: 1,
          borderRadius: '100px',
          boxShadow: t.shadows[10],
          [`&, &:hover`]: {
            background: 'white',
            [`&:hover svg`]: {
              transform: 'translateY(-4px)',
            },
          },
        })}
      >
        <Box
          sx={(t) => ({
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            borderRadius: '100px',
            backgroundColor: t.palette.grey[100],
          })}
        >
          <ArrowUpward sx={{ fontSize: 22, transform: 'translateY(0)', transition: 'transform 0.2s' }} />
        </Box>
      </Button>
    </Slide>
  );
};

export default AssetScrollTop;
