import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { SearchTypesValue } from '../types';

interface DeletePresetPayload {
  id: number;
  type: SearchTypesValue
}

interface DeletePresetResponse {
  success: boolean
}

export const deletePreset = async ({ type, id }: DeletePresetPayload): Promise<DeletePresetResponse> => {
  const res = await apiClient.delete<DeletePresetResponse>(`/${type}/presets/${id}`);
  return res.data;
};

type MutationFnType = (data: DeletePresetPayload) => ReturnType<typeof deletePreset>;

interface UseDeletePresetOptions {
  config?: MutationConfig<MutationFnType>;
}

export const useDeletePreset = ({ config }: UseDeletePresetOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({
        queryKey: [args[1].type, 'presets'],
      });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: deletePreset,
  });
};
