import { ArrayParam, BooleanParam, StringParam, useQueryParam, withDefault } from 'use-query-params';

const useUsersListParams = () => {
  const [queryString, setQueryString] = useQueryParam('q', withDefault(StringParam, undefined));
  const [accessLevel, setAccessLevel] = useQueryParam('access_level', withDefault(ArrayParam, []));
  const [desc, setDesc] = useQueryParam('desc', withDefault(BooleanParam, false));
  const [orderBy, setOrderBy] = useQueryParam('order_by', withDefault(StringParam, undefined));

  return {
    queryString,
    setQueryString,
    accessLevel,
    setAccessLevel,
    desc,
    setDesc,
    orderBy,
    setOrderBy,
  };
};

export default useUsersListParams;
