import type { QueryKey } from '@tanstack/react-query';
import type { AssetsListParams, TagGroupsFilteringFields } from './types';

export default {
  asset: (id: number) => ['assets', id] as QueryKey,
  assetsList: (params: AssetsListParams) => {
    const { q, tags, favourite, order_by, desc } = params;
    return ['assets', q, tags, favourite, order_by, desc] as QueryKey;
  },
  tagGroups: ({ q }: Partial<TagGroupsFilteringFields>) => ['assets', 'tags', q] as QueryKey,
  presets: () => ['assets', 'presets'] as QueryKey,
};
