import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';

export const createTagGroup = async (data: {group_name: string}): Promise<{success: boolean}> => {
  const res = await apiClient.post<{success: boolean}>(`/tag_groups`, data);
  return res.data;
};

interface UseCreateTagGroupOptions {
  config?: MutationConfig<typeof createTagGroup>;
}

export const useCreateTagGroup = ({ config }: UseCreateTagGroupOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({
        queryKey: ['tag_groups']
      });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: createTagGroup,
  });
};
