import type { FC } from 'react';
import FilesList from 'components/common/FilesList';
import { useCreativeFiles } from '../api/getCreativeFiles';

const CreativeFilesList: FC<{ id: number }> = ({ id }) => {
  const { data: creativeFiles } = useCreativeFiles({
    params: { id },
  });

  return <FilesList files={creativeFiles} />;
};

export default CreativeFilesList;
