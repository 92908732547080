import { Box, Divider, List, ListItem, ListItemText, Skeleton } from "@mui/material";
import { type FC } from "react";
import { CreativeContainerStyled, CreativeDetailsGalleryStyled, CreativeEditRowStyled, CreativeDetailsStyled } from "./CreativeDetailsComponents";

const CreativeDetailsSkeleton: FC = () => {
  return (
    <CreativeContainerStyled>
      <CreativeDetailsGalleryStyled>
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'start', justifyContent: 'center', flexGrow: 1, height: 420, }}>
          <Skeleton sx={{ transform: 'translateY(0)', borderRadius: '4px' }} width={'100%'} height={'50vh'} animation="wave" />
        </Box>
      </CreativeDetailsGalleryStyled>

      <CreativeDetailsStyled>
        <Box sx={{ position: 'relative', display: 'flex', flexGrow: 1, height: 30, }}>
          <Skeleton width={'30vw'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px' }} />
        </Box>

        <Divider sx={{ p: 2, }} />

        <CreativeEditRowStyled>
          <Box sx={{ pt: 4, }}>
            <Skeleton width={200} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px' }} />
          </Box>
        </CreativeEditRowStyled>

        <Divider sx={{ p: 2, }} />

        <CreativeEditRowStyled sx={{ pt: 4, }}>
          <Skeleton width={120} height={30} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
          <Skeleton width={'30vw'} height={20} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
        </CreativeEditRowStyled>

        <Divider sx={{ p: 2, }} />

        <CreativeEditRowStyled sx={{ pt: 4, }}>
          <List sx={{ mb: 3, }}>
            {[...Array(8).keys()].map(i => (
              <ListItem sx={{ p: 0, minHeight: 48, }} divider key={i}>
                <ListItemText sx={{ flex: 1, }}>
                  <Skeleton width={'40%'} height={28} sx={{ transform: 'translateY(0)', borderRadius: '4px', }} />
                </ListItemText>
                <ListItemText sx={{ flex: 2, pl: 2, flexWrap: 'wrap', }}>
                  <Skeleton width={'50%'} height={28} sx={{ transform: 'translateY(0)', borderRadius: '4px', }} />
                </ListItemText>
              </ListItem>
            ))}
            <Skeleton width={'30%'} height={32} sx={{ transform: 'translateY(0)', borderRadius: '4px', my: 4, }} />
          </List>


        </CreativeEditRowStyled>

      </CreativeDetailsStyled>

    </CreativeContainerStyled>
  )
}

export default CreativeDetailsSkeleton;
