import { Box, Grid, Skeleton } from '@mui/material';
import type { FC } from 'react';

const AssetDetailsSkeleton: FC = () => {
  return (
    <Grid
      container
      sx={(t) => ({
        position: 'relative',
        flexDirection: 'row',
        [t.breakpoints.down('md')]: { flexDirection: 'column' },
      })}
    >
      <Grid xs={12} md={6} item display="flex" justifyContent="center" alignItems="flex-start">
        <Skeleton sx={{ transform: 'translateY(0)', borderRadius: '4px' }} width={'100%'} height={'50vh'} animation="wave" />
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={6}
        sx={(t) => ({ [t.breakpoints.up('md')]: { pl: 3 }, mb: 7 })}
        direction="column"
      >
        <Skeleton width={'50%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 2, }} />
        <Skeleton width={'120px'} height={30} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 4, }} />
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, mb: 4, }}>
          <Skeleton width={'120px'} height={24} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 2, }} />
          <Skeleton width={'120px'} height={24} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 2, }} />
          <Skeleton width={'120px'} height={24} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 2, }} />
        </Box>
        <Skeleton width={'140px'} height={38} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 6, }} />
        <Skeleton width={'160px'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 2, }} />
        <Skeleton width={'80%'} height={20} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 0.5, }} />
        <Skeleton width={'30%'} height={20} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 4, }} />
        {[...Array(12).keys()].map(i => (
          <Box key={i} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 3, mb: 2, }}>
            <Skeleton width={'10%'} height={28} sx={{ transform: 'translateY(0)', borderRadius: '4px', }} />
            <Skeleton height={28} sx={{ flex: 1, transform: 'translateY(0)', borderRadius: '4px', }} />
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default AssetDetailsSkeleton;
