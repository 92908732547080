import { useLayoutEffect, useState } from "react";
import { getPersistedData, persistData } from "utils/persist";

const useHotjarConsent = () => {
  const [isShow, setIsShow] = useState(true);
  const [isHotjarConsent, setHotjarConsent] = useState(false);

  useLayoutEffect(() => {
    const persistedValue = !!getPersistedData("hotjar_consent");
    persistData("hotjar_consent", persistedValue);
    setIsShow(!persistedValue);
  }, []);

  const onToggleHotjarConsent = () => {
    setHotjarConsent(prev => {
      persistData("hotjar_consent", !prev);
      return !prev
    });
  };

  return { isShow, isHotjarConsent, onToggleHotjarConsent }
}

export default useHotjarConsent;
