import { Box, Typography } from "@mui/material";
import { AssignmentLateOutlined as AssignmentLateOutlinedIcon } from "@mui/icons-material";
import type { FC } from "react";

const UsersTableEmpty: FC = () => {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'center', justifyContent: 'center'}}>
      <AssignmentLateOutlinedIcon sx={ t=> ({color: t.palette.grey[600], fontSize: 28, marginBottom: 0.5 })} />
      <Typography sx={ t=> ({color: t.palette.grey[600] })}>No data received</Typography>
    </Box>
  )
};

export default UsersTableEmpty;
