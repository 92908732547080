/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* eslint-disable  @typescript-eslint/no-non-null-asserted-optional-chain */
import { Button, TableCell, TableRow } from '@mui/material';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { type FC } from 'react';
import { useCreateTag } from '../../api';
import { useModal } from 'hooks';
import TagNameField from './TagNameField';
import type { UseMutationResult } from '@tanstack/react-query';
import { useMyAccess } from 'features/users';

interface TagGroupDetailsField {
  groupId: number;
}

const TagAdd: FC<TagGroupDetailsField> = ({ groupId }) => {
  const access = useMyAccess();
  const { open, close, isOpen } = useModal(false);
  const createTag = useCreateTag(groupId);

  return access?.tags.includes('create') ? (
    <>
      <TableRow sx={{ '& td': { height: 32 } }}>
        <TableCell colSpan={3}>
          <Button
            disabled={isOpen}
            onClick={open}
            size="small"
            sx={{ textTransform: 'uppercase', px: 0 }}
            variant="text"
            endIcon={<AddIcon />}
          >
            Add tag
          </Button>
        </TableCell>
      </TableRow>
      {isOpen && (
        <TableRow>
          <TableCell>
            <TagNameField onMutate={createTag as UseMutationResult} onClose={close} />
          </TableCell>
          <TableCell>0</TableCell>
          <TableCell align="right">
            <Button
              size="small"
              variant="text"
              color="inherit"
              onClick={close}
              disabled={createTag.isPending}
              sx={{ minWidth: 34, visibility: 'visible' }}
            >
              <CloseIcon />
            </Button>
          </TableCell>
        </TableRow>
      )}
    </>
  ) : null;
};

export default TagAdd;
