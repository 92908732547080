import { Box, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { type FC } from 'react';
import SwitchVisibility from '../SwitchVisibility';
import { useTagGroupDetails } from '../../api';
import { isEmpty } from 'lodash-es';
import TagGroupName from './TagGroupName';
import TagGroupTags from './TagGroupTags';

interface TagGroupDetailsProps {
  tagGroupIdDetails: number;
  setTagGroupIdDetails: (id: number | null) => void;
}

const TagGroupDetails: FC<TagGroupDetailsProps> = ({ tagGroupIdDetails, setTagGroupIdDetails }) => {
  const { data: tagGroupDetails } = useTagGroupDetails({
    params: {
      group_id: tagGroupIdDetails,
    },
  });

  const onCloseTagGroupDetails = () => {
    setTagGroupIdDetails(null);
  };

  return (
    <>
      <Box
        sx={(t) => ({
          borderBottom: 1,
          borderColor: t.palette.grey[300],
          display: 'flex',
          minHeight: 56,
          maxHeight: 56,
          height: 56,
          px: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
        })}
      >
        <Typography fontWeight="500" variant="body2">
          Details
        </Typography>
        <IconButton onClick={onCloseTagGroupDetails}>
          <CloseIcon />
        </IconButton>
      </Box>
      {!isEmpty(tagGroupDetails) ? (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}>
          <TagGroupName
            groupId={tagGroupDetails.group_id}
            groupName={tagGroupDetails.group_name}
          />
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center',}}>
            <SwitchVisibility
              groupId={tagGroupDetails.group_id}
              checked={tagGroupDetails.is_visible}
              label={'Visible in the system'}
              sx={{ mb: 2, pl: 1, }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'start',
              position: 'relative',
              width: '100%',
              maxHeight: '100%',
              pl: 1,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                width: '100%',
                top: 0,
                left: 0,
                maxHeight: '100%',
              }}
            >
              <TagGroupTags groupId={tagGroupDetails.group_id} tags={tagGroupDetails.tags} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}>
          <Typography>No details found</Typography>
        </Box>
      )}
    </>
  );
};

export default TagGroupDetails;
