import * as yup from 'yup';

const creativesSettingsSchema = yup.object().shape({
  previewSize: yup.string().required('Preview size is required'),
  columns: yup.object().shape({
    file_Name: yup.bool(),
    id: yup.bool(),
    base_id: yup.bool(),
    creative_type: yup.bool(),
    idea_source: yup.bool(),
    idea_type: yup.bool(),
    social_network: yup.bool(),
    platform: yup.bool(),
    branch: yup.bool(),
    content_type: yup.bool(),
    technology: yup.bool(),
    resolution: yup.bool(),
    gender: yup.bool(),
    languages: yup.bool(),
    policy_check: yup.bool(),
    project_name: yup.bool(),
    customer_name: yup.bool(),
    performer: yup.bool(),
    model_name: yup.bool(),
    model_video_id: yup.bool(),
  }),
  columnsOrder: yup.array().of(yup.string()),
  creativesCount: yup.number(),
  searchTags: yup.object()
});

export default creativesSettingsSchema;
