import type { SelectChangeEvent } from "@mui/material";
import { Box, MenuItem, Select, styled } from "@mui/material";
import type { SortingOptionLabel } from "../api";
import useListParams from "hooks/useListParams";

const AssetsSortingContainer = styled(Box)(({ theme: t }) => `
  ${t.breakpoints.up('sm')} {
    padding-left: 12px;
  };

  ${t.breakpoints.down('sm')} {
    padding-left: 12px;
    width: 100%;
  };
`);
interface Option {
  value: boolean;
  label: SortingOptionLabel;
}

const SORTING_OPTIONS: Option[] = [
  { value: true, label: 'Newest' },
  { value: false, label: 'Oldest' },
];

const AssetsSorting = () => {
  const { desc, setDesc, setOrderBy } = useListParams();

  const handleSorting = (event: SelectChangeEvent) => {
    setDesc(event.target.value === 'true');
    setOrderBy('created_at');
  };

  return (
    <AssetsSortingContainer>
      <Select value={`${desc}`} onChange={handleSorting}>
        {SORTING_OPTIONS.map(option => (
          <MenuItem key={option.label} value={`${option.value}`}>{option.label}</MenuItem>
        ))}
      </Select>
    </AssetsSortingContainer>
  )
};

export default AssetsSorting;
