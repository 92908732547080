import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import TitlePageStyles from 'components/common/TitlePageStyles';
import { CreativesTopPanel, initialSettingsValue } from 'features/creatives';
import { creativesSettingsSchema } from 'features/creatives';
import { Suspense, type FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CreativeTable } from 'features/creatives';
import { CreativeTableSkeleton } from 'features/creatives';
import { Search, SearchContextProvider } from 'features/search';
import { Layout } from 'components/layout';
import useListParams from 'hooks/useListParams';
import type { CreativesSettingsState } from '../../features/creatives';

const CreativesPage: FC = () => {
  const { tags } = useListParams();

  const formMethods = useForm<CreativesSettingsState>({
    // @ts-expect-error type correction
    resolver: yupResolver(creativesSettingsSchema),
    defaultValues: {
      ...initialSettingsValue(),
      searchTags: tags ? tags.reduce((collection, tagId) => ({ ...collection, [`${tagId}`]: true }), {}) : {},
      creativesCount: 0,
    },
  });

  return (
    <FormProvider {...formMethods}>
      <SearchContextProvider searchType="creatives">
        <Layout childrenContainerClasses={t => ({
          [t.breakpoints.up('md')]: {
            paddingTop: '24px',
          },
          [t.breakpoints.down('md')]: {
            paddingTop: '0',
          },
          paddingBottom: '0',
        })}>
          <Box sx={t => ({
            position: 'relative',
            [t.breakpoints.up('md')]: {
              minHeight: 56,
            },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            pb: 1,
          })}>
            <TitlePageStyles>Creatives</TitlePageStyles>
            <Search />
          </Box>
          <Box sx={{ position: 'relative', display: 'flex', flex: 1, }}>
            <Box sx={{ position: 'absolute', width: '100%', height: '100%', }}>
              <CreativesTopPanel />
              <Suspense fallback={<CreativeTableSkeleton />}>
                <CreativeTable />
              </Suspense>
            </Box>
          </Box>
        </Layout>
      </SearchContextProvider>
    </FormProvider >
  );
};

export default CreativesPage;
