import type { Theme } from '@mui/material';
import { Box, Button, CircularProgress, IconButton, styled } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import type { FC } from 'react';
import { Link } from 'react-router-dom';

export const CreativeContainerStyled = styled(Box)(
  ({ theme: t }: { theme: Theme }) => `
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 24px;
  margin-bottom: 80px;

  ${t.breakpoints.up('md')} {
    flex-direction: row;
  };
  ${t.breakpoints.down('md')} {
    flex-direction: column;
  };
`
);

export const CreativeDetailsGalleryStyled = styled(Box)(
  ({ theme: t }: { theme: Theme }) => `
  display: flex;
  flex: 1;

  ${t.breakpoints.down('md')} {
    width: 100%;
  };
`
);

export const CreativeDetailsStyled = styled(Box)(
  ({ theme: t }: { theme: Theme }) => `
  display: flex;
  flex-direction: column;
  flex: 1;

  ${t.breakpoints.down('md')} {
    width: 100%;
  };
`
);

export const CreativeDetailsRowStyled = styled(Box)(
  () => `
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 32px;
`
);

export const CreativeEditRowStyled = styled(Box)(
  () => `
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
);

export const CreativeDetailsNavigation: FC<{ creativeId: number; visible?: boolean }> = ({
  creativeId,
  visible = true,
}) => {
  if (!visible) return null;
  return (
    <Box
      sx={{
        position: 'fixed',
        right: 20,
        bottom: 40,
        display: 'flex',
        p: 1,
        gap: 1,
        borderRadius: 20,
        backgroundColor: 'white',
        boxShadow:
          ' 0px 3px 5px -1px rgba(0, 0, 0, 0.10), 0px 6px 10px 0px rgba(0, 0, 0, 0.07), 0px 1px 18px 0px rgba(0, 0, 0, 0.06);',
      }}
    >
      <IconButton>
        <MoreVertIcon />
      </IconButton>
      <Button
        endIcon={<EditIcon sx={{ color: 'white' }} />}
        component={Link}
        to={`/creatives/${creativeId}/edit`}
        variant="contained"
        sx={{ borderRadius: 20, height: 40 }}
      >
        Edit
      </Button>
    </Box>
  );
};

export const CreativeEditNavigation: FC<{
  onSaveChanges: () => void;
  onDiscard: () => void;
  isPending: boolean;
}> = ({ onSaveChanges, onDiscard, isPending }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        right: 20,
        bottom: 40,
        display: 'flex',
        p: 1,
        gap: 1,
        borderRadius: 20,
        backgroundColor: 'white',
        boxShadow:
          ' 0px 3px 5px -1px rgba(0, 0, 0, 0.10), 0px 6px 10px 0px rgba(0, 0, 0, 0.07), 0px 1px 18px 0px rgba(0, 0, 0, 0.06);',
      }}
    >
      <Button
        disabled={isPending}
        onClick={onDiscard}
        variant="text"
        color="error"
        sx={{ borderRadius: 20, height: 40 }}
      >
        Discard
      </Button>

      <Button
        startIcon={isPending ? <CircularProgress size={16} /> : null}
        disabled={isPending}
        onClick={onSaveChanges}
        variant="contained"
        sx={{ borderRadius: 20, height: 40 }}
      >
        Save changes
      </Button>
    </Box>
  );
};
