import { DeleteOutline, BookmarkBorder, InfoOutlined } from "@mui/icons-material";
import { Box, Button, Chip, CircularProgress, Typography } from "@mui/material";
import type { FC } from "react";
import { useCallback, useMemo } from "react";
import type { SearchPreset } from "features/search";
import { usePresets, useDeletePreset } from "features/search";
import { useSearchContext } from "../context";

const PresetItem: FC<{ preset: SearchPreset; onClick: (preset: SearchPreset) => void; }> = ({ preset: { id, name, tags, q }, onClick }) => {
  const deletePreset = useDeletePreset();
  const { searchType, searchTextFieldRef } = useSearchContext();
  const handleRemovePreset = useCallback((id: number) => {
    deletePreset.mutate({ id, type: searchType });
  }, [searchType, deletePreset]);

  const handleChooseChip = useCallback(() => {
    onClick({ id, name, tags, q });
    searchTextFieldRef.current?.focus();
  }, [onClick, searchTextFieldRef]);

  return (
    <Chip
      key={`preset.${id}:${name}`}
      label={<Typography sx={{ fontSize: 13, textTransform: 'capitalize', }}>{name}</Typography>}
      icon={<BookmarkBorder sx={{ '&': { fontSize: 18, color: 'black', } }} />}
      deleteIcon={deletePreset.isPending ? <CircularProgress size={16} /> : <DeleteOutline />}
      onDelete={() => { handleRemovePreset(id); }}
      sx={t => ({
        '& .MuiChip-deleteIcon': { color: t.palette.grey[600], },
        '& .MuiChip-deleteIcon:hover': { color: t.palette.primary.main },
        '&': {
          height: '24px',
          mr: 1,
          borderRadius: '4px',
          '& .MuiChip-label': { pl: '12px', pr: '6px', py: '3px', color: 'black', }
        }
      })}
      onClick={handleChooseChip}
      component={Button} />
  )
}

const PresetsList: FC<{
  onSetPreset: (preset: SearchPreset) => void;
}> = ({ onSetPreset }) => {
  const { searchType } = useSearchContext();
  const { data } = usePresets(searchType);

  const handleSetPreset = useCallback((preset: SearchPreset) => {
    onSetPreset(preset);
  }, [onSetPreset]);

  const presetsList = useMemo(() => data.map(preset => (
    <PresetItem
      key={`preset:${preset.id}.${preset.name}`}
      preset={preset}
      onClick={handleSetPreset} />
  )), [handleSetPreset, data]);

  return (
    <Box sx={{ mr: 1, }}>
      {!data.length ? (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', pl: 1, mb: 1, }}>
          <InfoOutlined sx={t => ({ color: t.palette.grey[600], fontSize: 22, mr: 1, })} />
          <Typography sx={{ fontSize: 14, fontWeight: 400, }}>No data received</Typography>
        </Box>
      ) : <Box sx={{ display: 'flex', flexWrap: 'wrap', pb: 1, px: 0.5, }}>{presetsList}</Box>}

    </Box>
  )
};

export default PresetsList;
