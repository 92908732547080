import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import type { ListResponse } from 'lib/api';
import { apiClient } from 'lib/api';
import queryString from 'query-string';
import type { AssetEntry, AssetsListParams } from './types';
import queryKeys from './queryKeys';

export const getAssetsList = async (
  { params }: { params: AssetsListParams },
  signal?: AbortSignal
): Promise<ListResponse<AssetEntry>> => {
  const res = await apiClient.get<ListResponse<AssetEntry>>(`/assets`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

export const useAssetsList = ({ params }: { params: AssetsListParams }) => {
  return {
    offset: params.offset,
    ...useSuspenseInfiniteQuery({
      queryFn: ({ pageParam }) => getAssetsList({ params: { ...params, offset: pageParam, limit: 50 } }),
      queryKey: queryKeys.assetsList(params),

      select: (data) => ({
        count: data.pages[0].count,
        data: data.pages.flatMap((page: ListResponse<AssetEntry>) => page.data),
      }),

      getNextPageParam: (lastPage) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!lastPage) return null;
        return lastPage.data.length ? lastPage.offset + lastPage.limit : null;
      },
      initialPageParam: 0,
    }),
  };
};
