/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useState, useEffect, useRef } from 'react';

const useInView = (configs = {}) => {
  const [inView, setInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { ...configs }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, configs]);

  return { ref, inView };
};

export default useInView;
