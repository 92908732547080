import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import type { UseMutationResult } from '@tanstack/react-query';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { isAxiosError } from 'axios';
import type { FC } from 'react';
import { newTagNameSchema } from 'features/tags';
import { yupResolver } from '@hookform/resolvers/yup';

interface TagNameFieldProps {
  tagName?: string;
  onClose: () => void;
  onMutate: UseMutationResult;
}

interface FormValues {
  newTagName: string;
}

const TagNameField: FC<TagNameFieldProps> = ({ tagName = '', onMutate, onClose }) => {
  const formMethods = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(newTagNameSchema),
    defaultValues: {
      newTagName: tagName,
    },
  });

  const {
    control,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = formMethods;

  const newTagNameValue = useWatch({
    control,
    name: 'newTagName',
  }).trim();

  const onBlur = () => {
    if (errors.newTagName?.type === "required" || (tagName === newTagNameValue)) {
      handleClose();
      return;
    }

    setError('newTagName', {});
    onMutate.mutate(
      { tag_name: newTagNameValue },
      {
        onSuccess: () => {
          onClose();
          setValue('newTagName', newTagNameValue,);
        },
        onError: (e) => {
          if (isAxiosError(e) && e.response) {
            setError('newTagName', {
              type: 'manual',
              message: (e.response.data as { message: string }).message,
            });
          } else {
            handleClose();
          }
        },
      }
    );
  };

  const handleClose = () => {
    onClose();
    setValue('newTagName', '');
  };

  return (
    <Box sx={{ display: 'flex', }}>
      <Controller control={control} name="newTagName" render={({ field }) => (
        <TextField
          {...field}
          autoFocus
          variant="outlined"
          size="small"
          defaultValue={tagName}
          maxRows={1}
          onBlur={onBlur}
          inputProps={{
            style: {
              fontSize: 14,
              lineHeight: 1.3,
              letterSpacing: .2,
              padding: '4px 9px',
              minHeight: 32,
              boxSizing: 'border-box',
            }, maxLength: 64
          }}
          fullWidth
          error={Boolean(errors.newTagName?.type)}
          helperText={
            errors.newTagName?.type && <Typography fontSize={12}>{errors.newTagName.message}</Typography>
          }
          InputProps={{
            endAdornment: (
              <>
                {onMutate.isPending && <CircularProgress size={18} sx={{ mr: -1 }} color="success" />}
                {onMutate.isError && errors.newTagName && (
                  <ErrorOutlineIcon color="error" sx={{ width: 18, mr: -1 }} />
                )}
              </>
            ),
          }}
          disabled={isSubmitting}
        />
      )} />
    </Box>
  );
};

export default TagNameField;
