import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { Box, IconButton, styled } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import type { CreativeMediaTypeEntry, FileTypeValue } from 'types/shared';
import MediaContentFailed from './MediaContentFailed';
import EmptyAssetPreview from 'assets/empty-asset-preview.png';
import type { SearchTypesValue } from '../../features/search/types';
import { useAnalytics } from 'hooks';

type MediaContentTypeValue = FileTypeValue | CreativeMediaTypeEntry | null;
interface MediaContentViewProps {
  format?: string;
  media_type: MediaContentTypeValue;
  url: string;
  thumbnail: string;
  previewPopup?: boolean;
  emptyState?: boolean;
  searchType: SearchTypesValue;
}

interface VideoPlayerProps {
  format?: string;
  type: MediaContentTypeValue;
  url: string;
  isPreviewPopup?: boolean;
  searchType: SearchTypesValue;
}

const VideoPlayer: FC<VideoPlayerProps> = ({ isPreviewPopup, url, type , searchType}) => {
  const [isMediaError, setMediaError] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { sendContentPlayToAnalytics } = useAnalytics();

  const onPlay = useCallback(() => {
    setIsPlaying(true);
    sendContentPlayToAnalytics(searchType, isPreviewPopup);
  }, [setIsPlaying, searchType, isPreviewPopup, sendContentPlayToAnalytics]);

  const onError = useCallback(() => {
    setMediaError(true);
  }, [setMediaError]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('play', onPlay);
    }

    return () => {
      video?.removeEventListener('play', onPlay);
    };
  }, [onPlay, videoRef, isMediaError]);

  const handlePlay = async () => videoRef.current?.play();

  if (isMediaError)
    return (
      <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, }}>
        <MediaContentFailed type={type} url={url} isVideo showTitle />
      </Box>
    )

  return (
    <>
      {!isPlaying && (
        <IconButton
          onClick={handlePlay}
          sx={{
            '&': {
              position: 'absolute',
              minWidth: '100%',
              height: '100%',
              top: '0',
              left: '0',
              zIndex: 3,
              p: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: 50,
              borderRadius: 0,
            }
          }}
        >
          <PlayCircleOutlineIcon sx={{ fontSize: 100 }} />
        </IconButton>
      )}
      <Box
        component="video"
        id="video"
        ref={videoRef}
        width="100%"
        height="100%"
        controls={isPlaying}
        muted
        onError={onError}
        sx={{ position: 'relative', zIndex: 2, maxHeight: isPreviewPopup ? '100%' : '80vh', }}
      >
        <source src={url} type="video/mp4" />
      </Box>
    </>
  )
}

const AudioPlayer: FC<{ thumbnail?: string; url: string; onError: () => void; searchType: SearchTypesValue, isPreviewPopup?: boolean }> = ({ thumbnail, url, onError, searchType, isPreviewPopup}) => {
  const { sendContentPlayToAnalytics } = useAnalytics();

  const onPlay = useCallback(() => {
    sendContentPlayToAnalytics(searchType, isPreviewPopup);
  }, [searchType, isPreviewPopup, sendContentPlayToAnalytics]);

  return (
    <Box sx={{ position: 'relative', display: 'flex', flex: 1, justifyContent: 'center', boxSizing: 'border-box', }}>
      <Box component="img" src={thumbnail} sx={{ maxHeight: '50%', }} />
      <Box component="audio" controls onError={onError} onPlay={onPlay} sx={{ width: '100%', position: 'absolute', bottom: 0, }}>
        <source type="audio/wav" src={url} />
      </Box>
    </Box>
  )
}

const MediaContentViewContainer = styled(Box)<{ preview?: boolean }>(({ preview: isPreviewPopup }) => `
  position: relative;
  display: flex;
  height: ${isPreviewPopup ? '100%' : 'auto'};
  max-height: ${isPreviewPopup ? 'inherit' : '100%'};
  justify-content: center;
`);

const MediaContentView: FC<MediaContentViewProps> = ({ media_type, url, thumbnail, emptyState, previewPopup , searchType}) => {
  const isVideoPlayer = media_type === 'video' || media_type === 'animation' || media_type === 'json';
  const isAudioPlayer = media_type === 'audio' || media_type === 'track';
  const isImage = media_type === 'static' || media_type === 'image' || media_type === 'fonts';

  const [isMediaError, setMediaError] = useState(false);
  const onError = useCallback(() => {
    setMediaError(true);
  }, [setMediaError]);

  useEffect(() => {
    setMediaError(isEmpty(url));
  }, [url, setMediaError]);

  if (emptyState && isEmpty(media_type) && isEmpty(url))
    return (
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          mb: 2,
        }}
      >
        <Box component="img" sx={{ objectFit: 'contain', maxWidth: '100%' }} src={EmptyAssetPreview} />
      </Box>
    );

  return (
    isMediaError ? (
      <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, }}>
        <MediaContentFailed type={media_type} showTitle />
      </Box>
    ) : isVideoPlayer ? (
      <MediaContentViewContainer preview={previewPopup} key={`${media_type}.${url}`}>
        <VideoPlayer url={url} isPreviewPopup={previewPopup} type={media_type} searchType={searchType} />
      </MediaContentViewContainer>
    ) : isImage ? (
      <MediaContentViewContainer preview={previewPopup} key={`${media_type}.${url}`}>
        <Box component="img" src={url} onError={onError} sx={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' }} />
      </MediaContentViewContainer>
    ) : isAudioPlayer ? (
      <MediaContentViewContainer preview={previewPopup} key={`${media_type}.${url}`}>
        <AudioPlayer url={url} isPreviewPopup={previewPopup} thumbnail={thumbnail} onError={onError} searchType={searchType} />
      </MediaContentViewContainer>
    ) : null
  );
};

export default MediaContentView;
