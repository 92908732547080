import type { FC } from 'react';
import { Box, ListItemText, MenuItem } from '@mui/material';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash-es';
import FileIcon from 'components/common/FileIcon';
import type { File } from 'types/shared';

const FilesList: FC<{ files: File[] }> = ({ files }) => {
  const handleItemMenu = (e: React.MouseEvent<HTMLElement>, downloadUrl: string, fileName: string) => {
    e.stopPropagation();
    saveAs(downloadUrl, `adam_${fileName}`);
  };

  if (isEmpty(files))
    return (
      <MenuItem
        disabled
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
        }}
      >
        <ListItemText sx={(t) => ({ p: 0, fontSize: t.typography.body2.fontSize })}>No data received</ListItemText>
      </MenuItem>
    );

  return files.map(({ name, type, format, size, download_url }) => (
    <MenuItem
      key={`${name}.${size}`}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        handleItemMenu(e, download_url, name);
      }}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
    >
      <Box sx={(t) => ({ mr: 1, color: t.palette.primary.main, width: 24, height: 24 })}>
        <FileIcon type={type} format={format} />
      </Box>
      <ListItemText
        sx={(t) => ({
          p: 0,
          fontSize: t.typography.body2.fontSize,
          color: t.palette.primary.main,
          textTransform: 'uppercase',
          '& .MuiTypography-root': {
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-all',
          },
        })}
      >
        {format} ({(size / 1024 ** 2).toFixed(2)} Mb)
      </ListItemText>
    </MenuItem>
  ));
};

export default FilesList;
