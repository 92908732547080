import type { Theme} from '@mui/material';
import { Box, Drawer, styled } from '@mui/material';
import { Suspense, type FC, useCallback } from 'react';
import TagGroupsList from './TagGroupsList';
import TagGroupsListSkeleton from './TagGroupsListSkeleton';
import TagGroupDetails from './tag-group-details/TagGroupDetails';
import TagGroupDetailsSkeleton from './tag-group-details/TagGroupDetailsSkeleton';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { useBreakpoints } from 'hooks';

const TagGroupsStyles = styled(Box)(({ theme }: { theme: Theme }) => {
  return `
    border-left: 1px solid ${theme.palette.grey[300]};
    display: flex;
    flex-direction: column;
    align-content: stretch;
    height: 100%;
    width: 100%;
    ${theme.breakpoints.down('sm')} {

    }`
});

const TagGroupsContainer: FC<{ children: React.ReactElement }> = ({ children }) => {
  const { isBreakpoint } = useBreakpoints('sm');
  const [tagGroupIdDetails, setTagGroupIdDetails] = useQueryParam('group_id', withDefault(NumberParam, null));
  const handleTagGroupIdDetails = useCallback(() => {
    setTagGroupIdDetails(null);
  }, [setTagGroupIdDetails]);

  return isBreakpoint ? (
    <Drawer
      anchor={'right'}
      open={!!tagGroupIdDetails}
      onClose={handleTagGroupIdDetails}
      PaperProps={{
        sx: {
          width: '85%',
        }
      }}
    >
      {children}
    </Drawer>
  ) : children
}

const TagGroups: FC = () => {
  const [tagGroupIdDetails, setTagGroupIdDetails] = useQueryParam('group_id', withDefault(NumberParam, null));
  const handleTagGroupIdDetails = useCallback((value: number | null) => {
    setTagGroupIdDetails(value)
  }, [setTagGroupIdDetails]);

  return (
    <>
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Box sx={{ position: 'relative', display: 'flex', flexGrow: 1, width: '100%', flexBasis: '50%', }}>
          <Suspense fallback={<TagGroupsListSkeleton tagGroupIdDetails={tagGroupIdDetails}  />}>
            <TagGroupsList tagGroupIdDetails={tagGroupIdDetails} setTagGroupIdDetails={handleTagGroupIdDetails} />
          </Suspense>
        </Box>
        {tagGroupIdDetails &&
          <TagGroupsContainer>
            <TagGroupsStyles>
              <Suspense fallback={<TagGroupDetailsSkeleton />}>
                <TagGroupDetails tagGroupIdDetails={tagGroupIdDetails} setTagGroupIdDetails={handleTagGroupIdDetails} />
              </Suspense>
            </TagGroupsStyles>
          </TagGroupsContainer>
        }
      </Box>
    </>
  )
};

export default TagGroups;
