import type { QueryKey } from '@tanstack/react-query';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import queryString from 'query-string';
import type { QueryConfig, SynchronousQueryConfig } from 'lib/react-query';
import type { AssetFile, AssetFilesResponse } from './types';

interface AssetFilesPayload {
  id: number;
}

export const getAssetFiles = async (
  { params: { id } }: { params: AssetFilesPayload },
  signal?: AbortSignal
): Promise<AssetFile[]> => {
  const res = await apiClient.get<AssetFilesResponse>(`/assets/${id}/files`, {
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data.data;
};

interface UseAssetFiles<TData> {
  config?: QueryConfig<typeof getAssetFiles, TData>;
  params: AssetFilesPayload;
}

interface UseSyncAssetFiles<TData> {
  config?: SynchronousQueryConfig<typeof getAssetFiles, TData>;
  params: AssetFilesPayload;
}

export const useAssetFiles = <TData = AssetFile[]>({ params, config }: UseAssetFiles<TData>) => {
  const { id } = params;
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getAssetFiles({ params }, signal),
    queryKey: ['assets', id, 'files'] as QueryKey,
  });
};

export const useAssetFilesSync = <TData = AssetFile[]>({ params, config }: UseSyncAssetFiles<TData>) => {
  const { id } = params;
  return useQuery({
    staleTime: 60 * 30 * 1000,
    retry: false,
    throwOnError: false,
    ...config,
    queryFn: ({ signal }) => getAssetFiles({ params }, signal),
    queryKey: ['assets', id, 'files'] as QueryKey,
  });
};
