import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import type { CreativeDetailsModel, CreativeGroupId, CreativeGroupTagsResponse, CreativeTagEntry } from './types';
import queryKeys from './queryKeys';

export const getCreativeGroupTags = async (groupId: CreativeGroupId, signal?: AbortSignal): Promise<CreativeTagEntry[]> => {
  const res = await apiClient.get<CreativeGroupTagsResponse>(`/creatives/groups/${groupId}`, {
    signal,
  });
  return res.data.data;
};

interface UseCreativeGroupTagsOptions<TData> {
  config?: QueryConfig<typeof getCreativeGroupTags, TData>;
  groupId: CreativeGroupId;
}

export const useCreativeGroupTags = <TData = CreativeDetailsModel>({ groupId, config }: UseCreativeGroupTagsOptions<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getCreativeGroupTags(groupId, signal),
    queryKey: queryKeys.creativeDetails(groupId),
  });
};
