import { Box, Link, Typography, styled } from '@mui/material';
import { useAssets } from 'features/assets';
import type { FC } from 'react';
import type { CreativeMediaTypeEntry, FileTypeValue } from 'types/shared';
import FileIcon from './FileIcon';
import type { SystemStyleObject, Theme } from '@mui/system';
import { isEmpty, isString } from 'lodash-es';

export const MediaContentBoxStyled = styled(Box)(`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
`);

export const MediaContentFailed: FC<{
  url?: string;
  isVideo?: boolean;
  type: FileTypeValue | CreativeMediaTypeEntry | null;
  showTitle?: string | boolean;
}> = ({ type, isVideo, showTitle, url }) => {
  const { isGrid } = useAssets();
  const sizeIcon = isGrid ? 42 : 32;

  return (
    <MediaContentBoxStyled>
      <FileIcon
        type={type}
        format={'default'}
        sx={(t: Theme): SystemStyleObject<Theme> => ({
          fontSize: sizeIcon,
          color: (t.palette.grey as Record<number, string>)[500],
          mb: 2,
        })}
      />

      {showTitle && (
        <Typography sx={(t) => ({ textAlign: 'center', fontSize: '0.875rem', fontWeight: 500, '&, & a': { color: t.palette.grey[600] } })}>
          {(isVideo && !isEmpty(url)) ? (
            <>
              {`Video can't be played, file format is not supported by the browser.`}<br />
              {`Try to `}<Link target="_blank" href={url}>download it</Link>
            </>
          ) : `Failed to load ${isString(showTitle) ? showTitle : ''}`}
        </Typography>
      )}
    </MediaContentBoxStyled>
  );
};

export default MediaContentFailed;
