import { Button, ListItemIcon, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
  Settings as SettingsIcon,
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  SellOutlined as SellOutlinedIcon,
} from '@mui/icons-material';
import { useAnchorMenu } from 'hooks';

const SettingsNavItem = ({
  to,
  title,
  icon,
  onClose,
}: {
  to: string;
  title: string;
  icon: ReactNode;
  onClose?: () => void;
}) => {
  const t = useTheme();
  return (
    <MenuItem onClick={onClose} sx={{ p: 0, display: 'flex', alignItems: 'stretch' }}>
      <Link
        to={to}
        style={{
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          color: t.palette.primary.main,
          textDecoration: 'none',
          width: '100%',
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <Typography sx={{ fontSize: '0.875rem' }}>{title}</Typography>
      </Link>
    </MenuItem>
  );
};

const SettingsNav = () => {
  const { isOpen, anchor, handleOpenMenu, handleCloseMenu } = useAnchorMenu();

  return (
    <>
      <Button
        sx={(t) => ({ color: t.palette.text.secondary })}
        id="settings-item-button"
        aria-controls={isOpen ? 'settings-item-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        variant="text"
        disableElevation
        disableRipple
        disableTouchRipple
        onClick={handleOpenMenu}
      >
        <SettingsIcon sx={{ fontSize: 24 }} />
      </Button>
      <Menu
        id="settings-item-menu"
        slotProps={{
          paper: {
            sx: {
              width: 200,
            },
          },
        }}
        elevation={8}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchor}
        open={isOpen}
        onClose={handleCloseMenu}
      >
        <SettingsNavItem
          onClose={handleCloseMenu}
          to={'/tags'}
          title="Tag groups"
          icon={<SellOutlinedIcon fontSize="small" color="primary" sx={{ mr: 1 }} />}
        />
        <SettingsNavItem
          onClose={handleCloseMenu}
          to={'/users'}
          title="Users"
          icon={<AccountCircleOutlinedIcon fontSize="small" color="primary" sx={{ mr: 1 }} />}
        />
      </Menu>
    </>
  );
};

export default SettingsNav;
