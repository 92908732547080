import { useCallback } from 'react';
import type { FC } from 'react';
import { InsertLink as InsertLinkIcon } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import { AssetUtilButton } from './AssetsListComponents';
import { useAssets } from '../context';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';

interface AssetCopyLinkButtonProps {
  isPreview?: boolean;
  assetId: number;
}

const AssetCopyLinkButton: FC<AssetCopyLinkButtonProps> = ({ assetId, isPreview }) => {
  const { onShowAlert } = useEnqueueSnackbar();
  const { isList, isGrid } = useAssets();

  const handleCopyAssetLink = useCallback(() => {
    copy(`${window.location.host}/assets/${assetId}`);
    onShowAlert('Page link successfully copied');
  }, [assetId, copy, onShowAlert]);

  return (
    <>
      <Tooltip title={(isPreview ?? isGrid) ? 'Copy page link' : null} placement={'left'} arrow={isList}>
        <AssetUtilButton
          preview={isPreview}
          onClick={handleCopyAssetLink}
          variant={(isPreview ?? isGrid) ? 'contained' : 'text'}
          disableElevation
        >
          <InsertLinkIcon
            sx={{ fontSize: 22, color: (isPreview ?? isGrid) ? 'white' : 'black', mr: !isPreview && isList ? 1 : 0 }}
          />
          {!isPreview && isList && (
            <Typography
              sx={(t) => ({
                fontSize: t.typography.body2.fontSize,
                fontWeight: 500,
                color: t.palette.primary.main,
                whiteSpace: 'nowrap',
              })}
            >
              Copy Media URL
            </Typography>
          )}
        </AssetUtilButton>
      </Tooltip>
    </>
  );
};

export default AssetCopyLinkButton;
