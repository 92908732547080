import { type FC, type ReactNode, useMemo, useState, useCallback, useRef } from 'react';
import type { CallbackFunctionParam} from './SearchContext';
import { SearchContext } from './SearchContext';
import useListParams from 'hooks/useListParams';
import type { SearchTypesValue } from '../types';

interface AuthContextProviderProps {
  children: ReactNode;
  searchType: SearchTypesValue;
};

const SearchContextProvider: FC<AuthContextProviderProps> = ({ children, searchType }: AuthContextProviderProps) => {
  const searchTextFieldRef = useRef<HTMLInputElement>(null);
  const { tags, setTags, q, setQ } = useListParams();
  const [isShowPresets, setShowPresets] = useState(false);
  const [isShowAllTags, setShowAllTags] = useState(false);
  const [isShowResultTags, setShowResultTags] = useState(false);
  const [queryString, setQueryString] = useState(() => q?.length ? q : '');
  const [queryFieldValue, setQueryFieldValue] = useState(() => {
    const defaultValue = q?.length ? q : '';
    setShowResultTags(() => defaultValue.length >= 2);
    return defaultValue;
  });

  const handleResetAll = useCallback(() => {
    setQueryString('');
    setQueryFieldValue('');
    setShowAllTags(false);
    setShowResultTags(false);
    setTags(undefined);
    setQ(undefined);
  }, [
    setQueryString,
    setShowAllTags,
    setShowResultTags,
    setQueryFieldValue,
    setTags,
    setQ
  ]);

  const handleResetValues = useCallback((args?: CallbackFunctionParam) => {
    switch (args?.param) {
      case undefined:
      default:
        handleResetAll();
        break;
      case 'q':
        setQ(undefined);
        setQueryFieldValue('');
        break;
      case 'tags':
        setTags(undefined);
        break;
    }
  }, [setQ, setQueryFieldValue, setTags, handleResetAll])

  const contextValue = useMemo(
    () => ({
      searchType,
      searchTextFieldRef,

      onResetValues: handleResetValues,

      isShowPresets,
      setShowPresets,

      isShowAllTags,
      setShowAllTags,

      isShowResultTags,
      setShowResultTags,

      queryString,
      setQueryString,

      queryFieldValue,
      setQueryFieldValue,

      tags,
      setTags,

      q,
      setQ
    }),
    [
      searchType,
      searchTextFieldRef,
      isShowPresets, setShowPresets,
      isShowAllTags, setShowAllTags,
      isShowResultTags, setShowResultTags,
      queryString, setQueryString,
      queryFieldValue, setQueryFieldValue,
      tags, setTags,
      q, setQ,
      handleResetValues,
    ]
  );

  return <SearchContext.Provider
    value={contextValue}>
    {children}
  </SearchContext.Provider>;
};

export default SearchContextProvider;
