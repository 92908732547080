import { Box, Skeleton } from "@mui/material";
import { type FC } from "react";
import { CreativeContainerStyled, CreativeDetailsGalleryStyled, CreativeEditRowStyled, CreativeDetailsStyled } from "./CreativeDetailsComponents";

const CreativeEditSkeleton: FC = () => {
  return (
    <CreativeContainerStyled>
      <CreativeDetailsGalleryStyled>
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'start', justifyContent: 'center', flexGrow: 1, height: 420, }}>
          <Skeleton sx={{ transform: 'translateY(0)', borderRadius: '4px' }} width={'100%'} height={'50vh'} animation="wave" />
        </Box>
      </CreativeDetailsGalleryStyled>

      <CreativeDetailsStyled>
        <Box sx={{ position: 'relative', display: 'flex', flexGrow: 1, height: 40, mb: 3, }}>
          <Skeleton width={'30vw'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px' }} />
        </Box>

        <CreativeEditRowStyled sx={{ position: 'relative', mb: 3, }}>
          <Skeleton width={120} height={30} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
          <Skeleton width={'100%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
        </CreativeEditRowStyled>

        <CreativeEditRowStyled sx={{ position: 'relative', mb: 3, }}>
          <Skeleton width={120} height={30} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
          <Skeleton width={'100%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
        </CreativeEditRowStyled>

        <CreativeEditRowStyled sx={{ position: 'relative', mb: 3, }}>
          <Skeleton width={120} height={30} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
          <Skeleton width={'100%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
        </CreativeEditRowStyled>

        <CreativeEditRowStyled sx={{ position: 'relative', mb: 3, }}>
          <Skeleton width={120} height={30} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
          <Skeleton width={'100%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
        </CreativeEditRowStyled>

        <CreativeEditRowStyled sx={{ position: 'relative', mb: 3, flexDirection: 'row', gap: 2, }}>
          <Skeleton width={120} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
          <Skeleton width={'100%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
        </CreativeEditRowStyled>

        <CreativeEditRowStyled sx={{ position: 'relative', mb: 3, flexDirection: 'row', gap: 2, }}>
          <Skeleton width={120} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
          <Skeleton width={'100%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
        </CreativeEditRowStyled>

        <CreativeEditRowStyled sx={{ position: 'relative', mb: 3, flexDirection: 'row', gap: 2, }}>
          <Skeleton width={120} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
          <Skeleton width={'100%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
        </CreativeEditRowStyled>

        <CreativeEditRowStyled sx={{ position: 'relative', mb: 3, flexDirection: 'row', gap: 2, }}>
          <Skeleton width={120} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
          <Skeleton width={'100%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
        </CreativeEditRowStyled>

        <CreativeEditRowStyled sx={{ position: 'relative', mb: 3, flexDirection: 'row', gap: 2, }}>
          <Skeleton width={120} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
          <Skeleton width={'100%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
        </CreativeEditRowStyled>
        <CreativeEditRowStyled sx={{ position: 'relative', mb: 3, justifyContent: 'end', flexDirection: 'row', gap: 2, }}>
          <Skeleton width={120} height={40} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
        </CreativeEditRowStyled>

      </CreativeDetailsStyled>

    </CreativeContainerStyled>
  )
}

export default CreativeEditSkeleton;
