import { Forbidden } from 'features/users';
import { Titled } from 'react-titled';

const ForbiddenPage = () => {
  return (
    <>
      <Titled title={(title) => `Forbidden | ${title}`} />
      <Forbidden />
    </>
  );
};

export default ForbiddenPage;
