import { Box, Typography } from "@mui/material";
import BreadcrumbLink from 'components/layout/BreadcrumbLink';
import { TagGroups, AddTagGroup } from 'features/tags';
import { type FC } from "react";
import { Titled } from "react-titled";

const TagsPage: FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, }}>
      <Titled title={(title) => `ADAM | ${title}`} />
      <Box
        sx={(t) => ({
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          [t.breakpoints.down('sm')]: {
            pt: 6,
          },
          [t.breakpoints.up('sm')]: {
            pt: 1,
          },
          mb: 1,
          height: 48,
        })}
      >
        <BreadcrumbLink to="/assets" title="Assets" />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, pt: 2.5, }}>
        <Typography variant="h1" sx={{ lineHeight: 1, }}>Tag groups</Typography>
        <AddTagGroup />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'stretch', height: '100%', }}>
        <TagGroups />
      </Box>
    </Box>
  );
};

export default TagsPage;
