import { type FC } from 'react';
import {
  Box,
  LinearProgress,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import getSkeletonArray from 'utils/getSkeletonArray';
import { useFormContext } from 'react-hook-form';
import type { CreativesSettingsState } from '../types';

const CreativeTableRowSkeleton: FC<{ previewSize: number }> = ({ previewSize }) => (
  <TableRow
    sx={(t) => ({
      td: {
        border: '1px solid',
        borderColor: t.palette.grey[200],
        p: 1.5,
        minWidth: 108,
        maxWidth: 108,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    })}
  >
    <TableCell
      sx={{ p: '1px !important', minWidth: `${previewSize}px !important`, height: `${previewSize}px !important` }}
    >
      <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" />
    </TableCell>
    <TableCell sx={{ borderLeft: 'none !important' }}>
      <Skeleton animation="wave" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" width="100%" />
    </TableCell>
    <TableCell>
      <Skeleton animation="wave" width="100%" />
    </TableCell>
    <TableCell sx={{ minWidth: '56px !important' }}>
      <Skeleton animation="wave" width="100%" />
    </TableCell>
  </TableRow>
);

const CreativeTableSkeleton: FC = () => {
  const { watch } = useFormContext<CreativesSettingsState>();
  const previewSize = watch('previewSize') === 'large' ? 96 : 64;

  return (
    <Box
      sx={(t) => ({
        [t.breakpoints.down('sm')]: {
          width: 'calc(100vw - 40px)',
        },
        width: 'calc(100vw - 140px)',
        height: '95vh',
        position: 'relative',
      })}
    >
      <LinearProgress sx={{ height: 4, mb: 2, zIndex: 3, width: '100%' }} />
      <TableContainer
        sx={(t) => ({
          overflow: 'scroll',
          width: '100%',
          height: '100%',
          border: '1px solid',
          borderTop: 'none',
          borderColor: t.palette.grey[200],
        })}
      >
        <Table
          sx={{
            'tr th:first-of-type, td:first-of-type': {
              position: 'sticky',
              width: 72,
              left: 0,
              zIndex: 3,
              background: 'white',
              borderLeft: 'none',
            },
            'tr th:last-of-type, td:last-of-type': {
              position: 'sticky',
              width: 56,
              right: 0,
              zIndex: 3,
              background: 'white',
              borderRight: 'none',
            },
          }}
          stickyHeader
          size="small"
        >
          <TableHead sx={{ position: 'relative', zIndex: 3 }}>
            <TableRow
              sx={(t) => ({
                zIndex: 11,
                th: {
                  border: '1px solid',
                  borderColor: t.palette.grey[200],
                  height: 40,
                  p: 1,
                  borderRight: 'none',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  maxWidth: 108,
                  minWidth: 108,
                },
                'th:first-of-type, th:last-of-type': {
                  borderRight: '1px solid',
                  borderColor: t.palette.grey[200],
                },
              })}
            >
              <TableCell sx={{ minWidth: `${previewSize}px !important`, height: 72 }}>
                <Skeleton animation="wave" width="100%" />
              </TableCell>
              <TableCell sx={{ borderLeft: 'none !important' }}>
                <Skeleton animation="wave" width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" width="100%" />
              </TableCell>
              <TableCell sx={{ minWidth: '200px !important' }}>
                <Skeleton animation="wave" width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" width="100%" />
              </TableCell>
              <TableCell sx={{ minWidth: '200px !important' }}>
                <Skeleton animation="wave" width="100%" />
              </TableCell>
              <TableCell sx={{ minWidth: '200px !important' }}>
                <Skeleton animation="wave" width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" width="100%" />
              </TableCell>
              <TableCell sx={{ minWidth: '56px !important' }}>
                <Skeleton animation="wave" width="100%" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getSkeletonArray(25).map((r) => (
              <CreativeTableRowSkeleton previewSize={previewSize} key={r.id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CreativeTableSkeleton;
