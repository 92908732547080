import { Button, useTheme } from '@mui/material';
import { type FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import type { RequireAtLeastOne } from 'types/utils';

interface LocationState {
  search?: string;
}


interface BreadcrumbLinkProps {
  onClick: () => void;
  to: string;
  title: string
}

type HandlerOrLinkProps = RequireAtLeastOne<BreadcrumbLinkProps, 'onClick' | 'to'>

const BreadcrumbLink: FC<HandlerOrLinkProps> = ({ onClick, to, title }) => {
  const t = useTheme();
  const location = useLocation();
  const searchState = location.state as LocationState | null;

  return to ? (
    <Link
      to={{
        pathname: to,
        search: searchState?.search ?? '',
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: t.palette.text.primary,
        fontSize: t.typography.body1.fontSize,
        lineHeight: '2rem',
      }}
    >
      <KeyboardArrowLeftIcon sx={{ mr: 1, ml: -0.5 }} /> {title}
    </Link>
  ) : (
    <Button
      variant="text"
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: t.palette.text.primary,
        fontSize: t.typography.body1.fontSize,
        lineHeight: '2rem',
        fontWeight: 400,
        padding: 0,
        textTransform: 'capitalize',
      }}
      sx={{ '&:hover': { backgroundColor: 'transparent', } }}
      disableTouchRipple
      disableElevation
      disableRipple
    >
      <KeyboardArrowLeftIcon sx={{ mr: 1, ml: -0.5 }} /> {title}
    </Button>
  );
};

export default BreadcrumbLink;
