import { Button, TableCell, TableRow, Typography } from '@mui/material';
import { EditOutlined as EditIcon } from '@mui/icons-material';
import { type FC } from 'react';
import { type UserEntry } from '../api';
import { Box } from '@mui/system';
import UsersDelete from './UsersDelete';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import useMyAccess, { PROJECT_DEPENDANT_ROLES } from '../hooks/useMyAccess';

const UserProjects: FC<{ user: UserEntry }> = ({ user }) => {
  const { access_level: accessLevel, projects } = user;
  if (accessLevel && !PROJECT_DEPENDANT_ROLES.some((r) => r === accessLevel))
    return <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>All projects</Typography>;

  return projects?.length ? (
    <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>{projects.map(({ name }) => name).join(' ,')}</Typography>
  ) : null;
};

const UsersTableRowItem: FC<{ user: UserEntry }> = ({ user }) => {
  const [, setUserId] = useQueryParam('userId', withDefault(NumberParam, null));
  const access = useMyAccess();

  return (
    <TableRow
      hover
      sx={{
        '&:hover td:last-child div': { visibility: access && access.users.includes('delete') ? 'visible' : 'hidden' },
      }}
    >
      <TableCell style={{ paddingLeft: '4px' }}>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {user.email}
        </Typography>
      </TableCell>
      <TableCell>
        {user.access_level ? (
          <Typography variant="body2">{user.access_level}</Typography>
        ) : (
          <Typography variant="body2" sx={(t) => ({ color: t.palette.grey[500] })}>
            No access
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          <UserProjects user={user} />
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {user.customer_tag}
        </Typography>
      </TableCell>
      <TableCell>
        <Box sx={{ visibility: 'hidden' }}>
          <Button
            onClick={() => {
              setUserId(user.id);
            }}
            variant="text"
            color="primary"
            startIcon={<EditIcon color="primary" sx={{ fontSize: 20 }} />}
            sx={{ mr: 1 }}
          >
            Edit user
          </Button>
          <UsersDelete user={user} />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default UsersTableRowItem;
