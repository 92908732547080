import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { UpdateAssetPayload, UpdateAssetResponse } from './types';

export const updateAsset = async ({ id, ...body }: UpdateAssetPayload): Promise<UpdateAssetResponse> => {
  const res = await apiClient.patch<UpdateAssetResponse>(`/assets/${id}`, body);
  return res.data;
};

interface UseUpdateAssetOptions {
  config?: MutationConfig<typeof updateAsset>;
}

export const useUpdateAsset = ({ config }: UseUpdateAssetOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({
        queryKey: ['assets'],
      });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: updateAsset,
  });
};
