import type { FC } from 'react';
import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion, Divider, Box } from '@mui/material';
import { useModal } from '../../hooks';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
interface TagAccordionProps {
  details: React.ReactNode;
  summary: React.ReactNode;
}
const TagAccordion: FC<TagAccordionProps> = ({ details, summary }) => {
  const { toggle, isOpen } = useModal();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <MuiAccordion onChange={toggle} expanded={isOpen} disableGutters sx={{ boxShadow: 'none', }}>
        <AccordionSummary sx={t => ({ minHeight: '32px', fontSize: t.typography.body2.fontSize, fontWeight: 500, px: 1, })} expandIcon={isOpen ? <RemoveIcon /> : <AddIcon />}>{summary}</AccordionSummary>
        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', pb: 2, }}>{details}</AccordionDetails>
      </MuiAccordion>
      <Divider />
    </Box>
  );
};

export default TagAccordion;
