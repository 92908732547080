/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  QueryClientConfig,
  UseMutationOptions,
  UseQueryOptions,
  UseSuspenseQueryOptions,
} from '@tanstack/react-query';
import { QueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      // refetchOnWindowFocus: import.meta.env.PROD,

      /**
       * We have a very long timing of receiving assets,
       * and there is a line loader from the designer that animate for a long time,
       * so in order not to annoy the user with the loader when focusing on the page,
       * let's disable it =)
       */
      refetchOnWindowFocus: false,
    },
  },
};

export const queryClient = new QueryClient(queryClientConfig);

type Fn = (...args: any[]) => any;

export type ExtractFnReturnType<FnType extends Fn> = Awaited<ReturnType<FnType>>;

export type SynchronousQueryConfig<QueryFnType extends Fn, TData = ExtractFnReturnType<QueryFnType>> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>, unknown, TData>,
  'queryKey' | 'queryFn'
>;

export type QueryConfig<QueryFnType extends Fn, TData = ExtractFnReturnType<QueryFnType>> = Omit<
  UseSuspenseQueryOptions<ExtractFnReturnType<QueryFnType>, unknown, TData>,
  'queryKey' | 'queryFn'
>;

type GetTVariables<T> = T extends (...args: infer R) => any ? (R extends [infer TVar, ...any] ? TVar : never) : never;

export type MutationConfig<MutationFnType extends Fn> = UseMutationOptions<
  ExtractFnReturnType<Awaited<ReturnType<MutationFnType>>>,
  AxiosError,
  GetTVariables<MutationFnType>
>;
