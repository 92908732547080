import type { UserEntry, UserError } from './types';
import { apiClient } from '../../../lib/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { CreateUserDTO } from '../types';
import type { MutationConfig } from 'lib/react-query';
import axios from 'axios';
import { UserErrors } from './errors';

export const createUser = async (body: CreateUserDTO): Promise<UserEntry> => {
  const res = await apiClient.post<UserEntry>(`/users`, body);

  if (axios.isAxiosError(res)) {
    const error = res.response?.data as UserError;
    throw new Error(UserErrors[error.detail.column]);
  }

  return res.data;
};

interface UseCreateUserOptions {
  config?: MutationConfig<typeof createUser>;
}

export const useCreateUser = ({ config }: UseCreateUserOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['users'],
      });

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    mutationFn: createUser,
  });
};
