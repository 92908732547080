/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { InputProps, SelectProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { type FC } from 'react';
import { useParams } from 'react-router';
import MediaContentView from 'components/common/MediaContentView';
import {
  CreativeContainerStyled,
  CreativeDetailsGalleryStyled,
  CreativeDetailsStyled,
  CreativeEditNavigation,
  CreativeEditRowStyled,
} from './CreativeDetailsComponents';
import { useCreative } from '../api/getCreative';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import creativeEditSchema from '../validation/creativeEdit.schema';
import Input from 'components/common/Input';
import type { CreativeDetailsModelKeys, CreativeGroupId } from '../api/types';
import Select from 'components/common/Select';
import { useCreativeGroupTags } from '../api/getCreativeGroupTags';
import { ACCESS_OPTIONS } from '../constants';
import { getDetailIdValue } from '../utils';
import RadioGroup from 'components/common/RadioGroup';
import { useUpdateCreative } from '../api/updateCreative';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { isNumber } from 'lodash-es';
import type { OptionValue } from '../../../types/shared';

interface CreativeEditInputProps extends Omit<InputProps, 'name' | 'label' | 'variant'> {
  direction?: 'row' | 'column';
  name: CreativeDetailsModelKeys;
  label: string;
  required?: boolean;
}

interface CreativeSelectInputProps
  extends Omit<SelectProps<OptionValue | OptionValue[]>, 'name' | 'label' | 'variant'> {
  name: CreativeDetailsModelKeys;
  label: string;
  required?: boolean;
}

interface FormData {
  access: boolean;

  model_name: string;
  comment: string;
  thumbnail: string;
  download_files_link: string;
  model_video_id: string;
  original_text: string;
  original_creative_asset: string;
  base_id: string;
  file_name: string;
  media_link: string;
  customer: string;
  performer: string;
  base_customer: string;

  platform?: number;
  branch?: number;
  gender?: number;
  creative_type: number;
  idea_type: number;
  idea_source?: number;
  policy_check?: number;

  technology: number[];
  social_network: number[];
  projects: number[];
  resolution: number[];
  languages: number[];
}

const CreativeEditInput: FC<CreativeEditInputProps> = ({
  direction = 'column',
  name,
  label,
  multiline,
  required,
  onBlur,
  sx,
}) => (
  <CreativeEditRowStyled sx={{ mb: 1, ...sx }}>
    <Input
      name={name}
      label={label}
      required={required}
      multiline={multiline}
      onBlur={onBlur}
      titleSx={{ fontWeight: 400, fontSize: 16, flex: 1, whiteSpace: 'nowrap', maxWidth: 120 }}
      inputStyle={direction === 'row' ? { flex: 2, height: 24, padding: '8px 12px' } : {}}
      containerSx={
        direction === 'row' ? { mb: 1, gap: 3, display: 'flex', flexDirection: 'row', alignItems: 'center' } : { mb: 1 }
      }
    />
  </CreativeEditRowStyled>
);

const CreativeEditSelect: FC<CreativeSelectInputProps> = ({
  name,
  label,
  multiple,
  required,
  onBlur,
  sx,
  ...restProps
}) => {
  const { data: creativeGroupTags } = useCreativeGroupTags({
    groupId: name.replace('_', '-') as CreativeGroupId,
    config: {
      select: (data) => data.map((item) => ({ value: item.id, label: item.name })),
    },
  });

  return (
    <CreativeEditRowStyled sx={{ mb: 1, ...sx }}>
      <Select
        name={name}
        label={label}
        required={required}
        multiple={multiple}
        options={creativeGroupTags}
        onBlur={onBlur}
        titleSx={{ fontWeight: 400, fontSize: 16, flex: 1, whiteSpace: 'nowrap', maxWidth: 120 }}
        containerSx={{ mb: 1, gap: 3, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        {...restProps}
      />
    </CreativeEditRowStyled>
  );
};

const CreativeDetails: FC = () => {
  const { creativeId } = useParams();
  const { data: creativeDetails } = useCreative({ id: `${creativeId}` });
  const {
    file_name,
    thumbnail,
    media_link,
    download_files_link,
    comment,
    base_id,
    model_name,
    creative_type,
    model_video_id,
    customer,
    base_customer,
    performer,
    original_text,
    original_creative_asset,
    social_network,
    gender,
    branch,
    idea_source,
    idea_type,
    projects,
    access,
    technology,
    resolution,
    languages,
    policy_check,
  } = creativeDetails;

  const formMethods = useForm<FormData>({
    // @ts-expect-error incorrect schema types
    resolver: yupResolver(creativeEditSchema),
    mode: 'onChange',
    defaultValues: {
      creative_type: getDetailIdValue(creative_type),
      gender: getDetailIdValue(gender),
      branch: getDetailIdValue(branch),
      idea_source: getDetailIdValue(idea_source),
      policy_check: getDetailIdValue(policy_check),
      idea_type: getDetailIdValue(idea_type),
      social_network: social_network.map(getDetailIdValue),
      projects: projects.map(getDetailIdValue),
      technology: technology.map(getDetailIdValue),
      resolution: resolution.map(getDetailIdValue),
      languages: languages.map(getDetailIdValue),
      access,
      file_name,
      thumbnail,
      media_link,
      download_files_link,
      comment,
      base_id,
      model_name,
      model_video_id,
      customer,
      base_customer,
      performer,
      original_text,
      original_creative_asset,
    },
  });
  const { handleSubmit, reset, watch } = formMethods;
  const { onShowAlert } = useEnqueueSnackbar();

  const mediaLinkPath = watch('media_link');
  const thumbnailPath = watch('thumbnail');

  const updateCreative = useUpdateCreative({
    config: {
      onSuccess: () => {
        onShowAlert('Changes saved');
      },
      onError: () => {
        onShowAlert('Error');
      },
    },
  });

  const onSubmit = ({
    access,
    creative_type,
    platform,
    gender,
    branch,
    idea_source,
    policy_check,
    idea_type,
    social_network,
    technology,
    resolution,
    languages,
    ...restParams
  }: FormData) => {
    updateCreative.mutate({
      id: creativeId!,
      tags: [
        creative_type,
        platform,
        branch,
        idea_source,
        idea_type,
        gender,
        policy_check,
        ...social_network,
        ...technology,
        ...resolution,
        ...languages,
      ].filter((i) => isNumber(i)) as number[],
      access: typeof access === 'string' ? access === 'true' : access,
      ...restParams,
    });
  };

  return (
    <CreativeContainerStyled>
      <CreativeDetailsGalleryStyled>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
            height: 420,
          }}
        >
          <MediaContentView
            media_type={creativeDetails.media_type}
            url={mediaLinkPath}
            thumbnail={thumbnailPath}
            searchType="creatives"
          />
        </Box>
      </CreativeDetailsGalleryStyled>

      <CreativeDetailsStyled>
        <Box sx={{ position: 'relative', display: 'flex', flexGrow: 1, height: 30, mb: 3 }}>
          <Typography
            variant="h1"
            sx={(t) => ({
              '&': {
                position: 'absolute',
                display: 'block',
                width: '100%',
                fontSize: t.typography.h3,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            })}
          >
            Edit creative
          </Typography>
        </Box>

        <FormProvider {...formMethods}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 4 }}>
            <CreativeEditInput name="file_name" disabled label={'File name'} required />
            <CreativeEditInput name="thumbnail" label={'Thumbnail'} required />
            <CreativeEditInput name="media_link" label={'Media Link'} required />
            <CreativeEditInput name="download_files_link" label={'Download files link'} required />
            <CreativeEditInput name="comment" label={'Comment'} multiline />
          </Box>
          <CreativeEditInput name="base_id" disabled label={'Base ID'} direction="row" required />
          <CreativeEditSelect name="creative_type" disabled label={'Creative type'} required />
          <CreativeEditSelect name="social_network" label={'Social network'} multiple />
          <CreativeEditSelect name="gender" disabled label={'Gender'} />
          <CreativeEditSelect name="branch" disabled label={'Branch'} />
          <CreativeEditInput name="model_name" label={'Model name'} direction="row" />
          <CreativeEditInput name="model_video_id" label={'Model video id'} direction="row" />
          <CreativeEditSelect name="projects" disabled label={'Project name'} required multiple />
          <CreativeEditSelect name="idea_source" label={'Idea source'} />
          <CreativeEditSelect name="idea_type" disabled label={'Idea type'} required />
          <CreativeEditSelect name="technology" label={'Technology'} multiple />
          <CreativeEditSelect name="resolution" disabled label={'Resolution'} multiple required />
          <CreativeEditSelect name="languages" disabled label={'Languages'} multiple required />
          <CreativeEditSelect name="policy_check" label={'Policy check'} />
          <CreativeEditInput name="customer" disabled label={'Customer'} direction="row" />
          <CreativeEditInput name="base_customer" label={'Base customer'} direction="row" />
          <CreativeEditInput name="performer" label={'Performer'} direction="row" />
          <CreativeEditInput name="original_text" label={'Original text'} direction="row" />
          <CreativeEditInput name="original_creative_asset" label={'Original Creative'} direction="row" />
          <Box>
            <RadioGroup
              name="access"
              label="Access*"
              options={ACCESS_OPTIONS}
              labelProps={{
                sx: (t) => ({
                  fontSize: t.typography.body1.fontSize,
                  fontWeight: 400,
                  color: t.palette.primary.main,
                  flex: 1,
                  whiteSpace: 'nowrap',
                  maxWidth: 120,
                }),
              }}
              controlProps={{
                sx: {
                  display: 'flex',
                  flex: 2,
                  gap: 3,
                  flexDirection: 'row',
                  justifyContent: 'start',
                  alignItems: 'center',
                },
              }}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                mb: 1,
                '& .MuiFormControlLabel-root:last-child': { mr: 0 },
              }}
            />
          </Box>
        </FormProvider>
      </CreativeDetailsStyled>

      <CreativeEditNavigation
        isPending={updateCreative.isPending}
        onSaveChanges={handleSubmit(onSubmit)}
        onDiscard={reset}
      />
    </CreativeContainerStyled>
  );
};

export default CreativeDetails;
