import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import type { TagGroupEntry, TagGroupResponse } from '../types';
import queryKeys from './queryKeys';

interface GetTagGroupDetailsParams {
  group_id: number;
}

export interface GetTagGroupDetailsOptions {
  params: GetTagGroupDetailsParams;
}

export const getTagGroupDetails = async (
  { params: { group_id } }: GetTagGroupDetailsOptions,
  signal?: AbortSignal
): Promise<TagGroupEntry> => {
  const res = await apiClient.get<TagGroupResponse<TagGroupEntry>>(`/tag_groups/${group_id}`, { signal });
  return res.data.data;
};

interface UseTagGroupDetailsOptions<TData> {
  config?: QueryConfig<typeof getTagGroupDetails, TData>;
  params: GetTagGroupDetailsOptions['params'];
}

export const useTagGroupDetails = <TData = TagGroupEntry>({ config, params }: UseTagGroupDetailsOptions<TData>) => {
  const { group_id } = params;

  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getTagGroupDetails({ params }, signal),
    queryKey: queryKeys().tagGroupDetails(group_id),
  });
};
