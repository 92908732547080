import { useProjects } from '../api';
import type { ControlledSelectProp } from 'components/common/Select';
import Select from 'components/common/Select';
import type { FC } from 'react';
import type { Option } from 'types/shared';

type ProjectSelectProps = Omit<ControlledSelectProp, 'options'>;

const ProjectSelect: FC<ProjectSelectProps> = (props) => {
  const { data: projects } = useProjects<Option[]>({
    config: {
      select: (data) => data.map(({ name, id }) => ({ value: id, label: name })),
    },
  });
  return <Select {...props} options={projects} />;
};

export default ProjectSelect;
