import { Box } from "@mui/material";
import type { FC } from "react";
import CreativesCounter from "./CreativesCounter";
import CreativesSearchValues from "./CreativesSearchValues";

const CreativesTopPanel: FC = () => {
  return (
    <Box sx={t => ({
      pr: 1,
      zIndex: 4,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'white',
      [t.breakpoints.up('md')]: {
        minHeight: 60,
      },
    })}>
      <CreativesSearchValues />
      <CreativesCounter />
    </Box >
  )
}

export default CreativesTopPanel;
