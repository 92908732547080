import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Popover } from '@mui/material';
import type { FC, MouseEvent, ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import useEnqueueSnackbar from '../../../hooks/useEnqueueSnackbar';
import { useNavigate } from 'react-router';
import { useMyAccess } from '../../users';
interface PopupListItemProps {
  icon: ReactNode;
  text: string;
  disabled?: boolean;
  action: (() => void) | (() => Promise<void>);
}

interface CreativesTableRowActionsProps {
  creativeId: number;
  name: string;
}

const PopupListItem: FC<PopupListItemProps> = ({ icon, text, disabled, action }) => (
  <ListItem disablePadding>
    <ListItemButton onClick={action} disabled={disabled} sx={{ p: 1 }}>
      <ListItemIcon sx={{ mr: 1 }}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  </ListItem>
);

const CreativesTableRowActions: FC<CreativesTableRowActionsProps> = ({ creativeId, name }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const access = useMyAccess();

  const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  }, []);
  const { onShowAlert } = useEnqueueSnackbar();

  const handleCopyValue = useCallback(
    async (value: string) => {
      try {
        await navigator.clipboard.writeText(value);
        onShowAlert('Copied');
        setAnchorEl(null);
      } catch (err) {
        onShowAlert('Error occurs');
      }
    },
    [onShowAlert]
  );

  const listData: PopupListItemProps[] = useMemo(() => {
    const baseActions = [
      {
        icon: <LinkIcon />,
        text: 'Copy link',
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        action: () => handleCopyValue(`${location.origin}/creatives/${creativeId}`),
      },
      {
        icon: <ContentCopyIcon />,
        text: 'Copy name',
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        action: () => handleCopyValue(name),
      },
    ];

    const editAction = {
      icon: <EditIcon />,
      text: 'Edit',
      action: () => {
        navigate(`/creatives/edit/${creativeId}`);
      },
    };

    if (access?.creatives.includes('update')) {
      return [...baseActions, editAction];
    }

    return baseActions;
  }, [access?.creatives, creativeId, handleCopyValue, name, navigate]);

  const open = Boolean(anchorEl);
  const id = open ? 'actions-dropdown' : undefined;

  return (
    <>
      <IconButton sx={{ width: 36, height: 36 }} onClick={handleOpen} aria-describedby={id}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        onClick={handleClose}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        role={undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        hideBackdrop
      >
        <Paper sx={{ width: 220, py: 1 }}>
          <List sx={{ p: 0 }}>
            {listData.map(({ action, text, disabled, icon }) => (
              <PopupListItem key={text} action={action} disabled={disabled} icon={icon} text={text} />
            ))}
          </List>
        </Paper>
      </Popover>
    </>
  );
};

export default CreativesTableRowActions;
