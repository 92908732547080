import { getPersistedData } from 'utils/persist';
import { INITIAL_COLUMN_ORDER } from '../constants';
import type { CreativesSettingsState } from '../types';

const initialSettingsValue = (): CreativesSettingsState => {
  const persistedValues = getPersistedData<CreativesSettingsState>('creativesSettingsState');
  if (persistedValues) {
    return persistedValues;
  }

  return {
    previewSize: 'medium',
    columns: INITIAL_COLUMN_ORDER.reduce<Record<string, boolean>>(
      (collection, { name }) => ({
        ...collection,
        [name.split('.')[1]]: true,
      }),
      {}
    ),
    columnsOrder: INITIAL_COLUMN_ORDER,
    searchTags: {},
    creativesCount: 0,
  };
};

export default initialSettingsValue;
