import { useSuspenseQuery } from '@tanstack/react-query';
import type { ListResponse, PaginationParams } from 'lib/api';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { TagGroupEntry } from '../types';
import queryKeys from './queryKeys';

export interface GetTagGroupsOptions {
  params?: PaginationParams;
}

export const getTagGroups = async (
  { params }: GetTagGroupsOptions,
  signal?: AbortSignal
): Promise<ListResponse<TagGroupEntry>> => {
  const res = await apiClient.get<ListResponse<TagGroupEntry>>(`/tag_groups`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  return {
    count: res.data.count,
    limit: res.data.limit,
    offset: res.data.offset,
    data: res.data.data,
  };
};

interface UseTagGroupsOptions<TData> {
  config?: QueryConfig<typeof getTagGroups, TData>;
  params?: GetTagGroupsOptions['params'];
}

export const useTagGroups = <TData = ListResponse<TagGroupEntry>>({ config, params }: UseTagGroupsOptions<TData>) => {
  const { offset, limit } = { ...params };

  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getTagGroups({ params }, signal),
    queryKey: queryKeys().tagGroups(offset, limit),
  });
};
