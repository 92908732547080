import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import type { ReactNode } from 'react';
import ProgressSkeleton from 'components/layout/ProgressSkeleton';
import { useMe } from 'features/users';
import { trackUser } from '../analytics/analyticsInitial';

interface ErrorAuth {
  cause?: {
    status?: number;
  };
}

const AuthRequired = ({ children, checkAccess = true }: { children: ReactNode; checkAccess?: boolean }) => {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);

  const { data: me, isFetched, isFetching, isError, error } = useMe({ config: { enabled: checkAccess } });

  if (route === 'idle' || isFetching) {
    return <ProgressSkeleton />;
  }
  if (isError && (error as ErrorAuth).cause?.status === 503) {
    return location.pathname === '/maintenance' ? children : <Navigate to="/maintenance" />;
  }

  if (checkAccess && isFetched && !me?.access_level) {
    return <Navigate to="/forbidden" />;
  }

  if (route !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (me) {
    trackUser(me);
  }

  return children;
};
export default AuthRequired;
