import { Box, Button } from "@mui/material"
import type { FC } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import LogoAdam from "assets/logo-adam-grey.svg?react";

const TopBar: FC<{onToggleMenu: () => void}> = ({onToggleMenu}) => {
  return <Box sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: 56,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    zIndex: 5,
    paddingX: 3
  }}>
    <Button onClick={onToggleMenu}
      sx={t => ({
        borderRadius: 40,
        width: 40,
        minWidth: 40,
        height: 40,
        border: `1px solid ${t.palette.grey[300]}`,
        mr: 2,
      })}><MenuIcon sx={{fontSize: 24}} color={"action"} /></Button>
      <LogoAdam width={56} height={30} />
  </Box>
}

export default TopBar;
