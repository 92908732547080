import {
  Download as DownloadIcon,
  DownloadOutlined as DownloadOutlinedIcon,
  ExpandMoreOutlined as ExpandMoreOutlinedIcon,
} from '@mui/icons-material';
import { Button, Menu } from '@mui/material';
import { useAnchorMenu } from 'hooks';
import { Suspense, useCallback } from 'react';
import type { FC } from 'react';
import FilesListSkeleton from 'components/common/FilesListSkeleton';
import CreativeFilesList from './CreativeFilesList';

interface AssetGetFileButtonProps {
  parentRef?: React.MutableRefObject<HTMLDivElement | null>;
  creativeId: number;
  variant?: "text" | "contained" | "outlined";
  title?: string;
}

const CreativesFilesButton: FC<AssetGetFileButtonProps> = ({ parentRef, creativeId, variant, title }) => {
  const { isOpen, anchor, handleOpenMenu, handleCloseMenu } = useAnchorMenu();

  const onOpenMenu = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      parentRef?.current?.classList.add('action-hover');
      handleOpenMenu(e);
    },
    [parentRef, handleOpenMenu]
  );

  const onCloseMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    parentRef?.current?.classList.remove('action-hover');
    handleCloseMenu();
  }, [parentRef, handleCloseMenu]);

  return (
    <>
      <Button
        aria-controls={isOpen ? `creative-${creativeId}-context-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={onOpenMenu}
        variant={variant ?? "contained"}
        color="info"
        startIcon={
          title ? <DownloadIcon sx={{ color: 'black', }}
          /> : (
            <DownloadOutlinedIcon
              sx={t => ({
                '&:nth-of-type(1)': { fontSize: 24 },
                color: t.palette.grey[700],
              })}
            />)
        }
        endIcon={
          title ? (
            <ExpandMoreOutlinedIcon
              sx={{
                fontSize: 14,
                color: 'black',
                transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
                transition: 'transform .1s',
              }} />
          ) : null
        }
        sx={(t) => ({
          flexGrow: 1,
          mr: '4px',
          borderRadius: t.borderRadius.sm,
          fontSize: t.typography.body2.fontSize,
          lineHeight: t.typography.body2.lineHeight,
          opacity: 0.9,
          minHeight: 36,
          minWidth: 36,

          px: title ? 1.75 : 1,
          py: title ? 1 : 1,
          '& .MuiButton-startIcon': {
            mr: title ? 1 : 0,
            ml: title ? -0.5 : 0,
          }
        })}>{title ? title : ""}</Button>

      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={`creative-${creativeId}-context-menu`}
        anchorEl={anchor}
        open={isOpen}
        onClose={onCloseMenu}
        slotProps={{
          paper: {
            sx: {
              minWidth: 180,
              maxWidth: 280,
              maxHeight: 280,
            }
          },
        }}
      >
        {isOpen && (
          <Suspense fallback={<FilesListSkeleton />}>
            <CreativeFilesList id={creativeId} />
          </Suspense>
        )}
      </Menu>
    </>
  );
};

export default CreativesFilesButton;
