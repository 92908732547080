import { Login } from "features/login";
import type { FC } from "react";
import { Titled } from "react-titled";

const LoginPage: FC = () => {
  return (
    <>
      <Titled title={(title) => `ADAM | ${title}`} />
      <Login />
    </>
  );
};

export default LoginPage;
