import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import type { CheckboxProps } from '@mui/material';
import type { FC } from 'react';

interface CheckboxComponentProps extends Omit<CheckboxProps, 'name'> {
  name: string;
  label?: string;
}
const Checkbox: FC<CheckboxComponentProps> = ({ name, label, ...restProps }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <FormControlLabel
            control={
              <MuiCheckbox
                checked={!!field.value}
                onChange={field.onChange}
                inputProps={{ 'aria-label': label }}
                {...restProps}
              />
            }
            label={label}
          />
        );
      }}
    />
  );
};

export default Checkbox;
