import type { QueryKey } from '@tanstack/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import type { AccessLevelsResponse, UserAccessLevel } from './types';

export const getAccessLevels = async (signal?: AbortSignal): Promise<UserAccessLevel[]> => {
  const res = await apiClient.get<AccessLevelsResponse>(`/users/access-levels`, {
    signal,
  });
  return res.data.data;
};

interface UseUsersListOptions<TData> {
  config?: QueryConfig<typeof getAccessLevels, TData>;
}

export const useAccessLevels = <TData = UserAccessLevel[]>({ config }: UseUsersListOptions<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getAccessLevels(signal),
    queryKey: ['users/access-levels'] as QueryKey,
  });
};
