import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';

interface UpdateCreativePayload {
  id: string;
  tags: number[];
  projects: number[];
  access: boolean;
  file_name: string;
  thumbnail: string;
  media_link: string;
  download_files_link: string;
  comment: string;
  base_id: string;
  model_name?: string;
  model_video_id: string;
  customer: string;
  base_customer: string;
  performer: string;
  original_text: string;
  original_creative_asset: string;
}
interface UpdateCreativeResponse {
  data: unknown
}

export const updateCreative = async ({ id, ...body }: UpdateCreativePayload): Promise<UpdateCreativeResponse> => {
  const res = await apiClient.patch<{ data: UpdateCreativeResponse }>(`/creatives/${id}`, body);
  return res.data.data;
};

interface UseUpdateAssetOptions {
  config?: MutationConfig<typeof updateCreative>;
}

export const useUpdateCreative = ({ config }: UseUpdateAssetOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({
        queryKey: ['creatives'],
      });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: updateCreative,
  });
};
