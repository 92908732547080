import { createContext, useContext } from 'react';

export type ListType = 'row' | 'column';

export interface AssetsStateValue {
  isVisibleFilter: boolean;
  handleVisibleFilter: () => void;

  listType: ListType;
  handleListType: (value: ListType) => void;

  isGrid: boolean;
  isList: boolean;

  countAssets: number | null;
  handleSetCountAssets: (value: number) => void;
}

export type AuthContextType = AssetsStateValue;

export const AssetsContext = createContext<AuthContextType>({} as AuthContextType);

export function useAssets() {
  return useContext(AssetsContext);
}
