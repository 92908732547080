import type { QueryKey } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { SynchronousQueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { UserEntry } from './types';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

export const getMe = async (signal?: AbortSignal): Promise<UserEntry> => {
  const res = await apiClient.get<{ data: UserEntry }>(`/users/me`, {
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  if (isAxiosError(res) && res.name === 'AxiosError') {
    const errRes = new Error('User error');
    errRes.cause = { status: res.response?.status };
    return Promise.reject(errRes);
  }

  return res.data.data;
};

interface UseUsersListOptions {
  config?: SynchronousQueryConfig<typeof getMe>;
}

export const useMe = ({ config }: UseUsersListOptions = {}) => {
  return useQuery({
    staleTime: 60 * 180 * 1000,
    ...config,
    queryFn: ({ signal }) => getMe(signal),
    queryKey: ['me'] as QueryKey,
    retry: 0,
  });
};
