/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useCallback, type FC } from 'react';
import TagAdd from './TagAdd';
import TagSorting from './TagSorting';
import type { TagEntry } from '../../types';
import { useTagsSort } from 'features/tags';
import TagNameField from './TagNameField';
import { useUpdateTag } from 'features/tags';
import type { UseMutationResult } from '@tanstack/react-query';
import { useModal } from 'hooks';
import { useMyAccess } from 'features/users';

interface TagGroupTagsProps {
  groupId: number;
  tags: TagEntry[];
}

interface TagNameProps {
  tagName: string;
  tagId: number;
  groupId: number;
}

const TagName: FC<TagNameProps> = ({ tagName, tagId, groupId }) => {
  const access = useMyAccess();
  const { open, close, isOpen } = useModal(false);
  const changeTag = useUpdateTag({
    params: {
      group_id: groupId,
      tag_id: tagId,
    },
  });

  const handleEditTagName = useCallback(() => {
    open();
  }, [open]);

  return access?.tags.includes('update') ? (
    <>
      {!isOpen ? (
        <Button
          variant="text"
          onClick={handleEditTagName}
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            textAlign: 'left',
            textTransform: 'inherit',
            fontSize: 14,
            lineHeight: 1.3,
            letterSpacing: 0.2,
            fontWeight: 'normal',
            wordBreak: 'break-all',
            color: 'black',
            ml: -1,
            border: '1px solid transparent',
            '&:hover': {
              border: '1px solid #bbe1ff',
            },
          }}
        >
          {tagName}
        </Button>
      ) : (
        <Box sx={{ display: 'flex', flexGrow: 1, ml: -1 }}>
          <TagNameField tagName={tagName} onMutate={changeTag as UseMutationResult} onClose={close} />
        </Box>
      )}
    </>
  ) : (
    <Typography
      sx={{
        fontSize: 14,
        lineHeight: 1.3,
        letterSpacing: 0.2,
        fontWeight: 'normal',
        wordBreak: 'break-all',
        color: 'black',
        ml: -1,
        padding: '6px 8px',
      }}
    >
      {tagName}
    </Typography>
  );
};

const TagGroupTags: FC<TagGroupTagsProps> = ({ groupId, tags }) => {
  const { currentSorting, sortedTags, setSortOrder } = useTagsSort(tags, { order: 'asc', type: 'alphabet' });

  return (
    <TableContainer component={Box}>
      <Table
        size="small"
        stickyHeader
        sx={{
          '& thead th': { backgroundColor: 'white' },
          '& tbody tr:last-child td': { border: 'none' },
        }}
      >
        <TableHead
          sx={(t) => ({ th: { height: 36, color: t.palette.grey[600], fontSize: t.typography.body2.fontSize } })}
        >
          <TableRow>
            <TableCell sx={{ width: '40%', maxWidth: '40%' }}>Tag name</TableCell>
            <TableCell sx={{ lineHeight: 1.25, maxWidth: 200 }}>Content count</TableCell>
            <TableCell align="right" sx={{ px: 0 }}>
              <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'flex-end' }}>
                Sorting <TagSorting setSortOrder={setSortOrder} currentSorting={currentSorting} />
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TagAdd groupId={groupId} />
          {sortedTags.map((tag) => (
            <TableRow
              sx={{
                '& td': { height: 32 },
                '& td:last-child button': { visibility: 'hidden' },
                '&:hover td:last-child button': { visibility: 'visible' },
                '& .tagEditName': { visibility: 'hidden' },
                '&:hover .tagEditName': { visibility: 'visible' },
              }}
              key={tag.tag_id}
            >
              <TableCell>
                <TagName tagName={tag.tag_name} tagId={tag.tag_id} groupId={groupId} />
              </TableCell>
              <TableCell>{tag.creatives_count}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TagGroupTags;
